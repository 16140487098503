import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ClassEnrollment } from './ClassEnrollment';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import { useDispatch } from 'react-redux';
import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

import {    
  Box,
  Hidden,
  Paper,
  Modal,
} from '@material-ui/core';

import LaunchIcon from '@material-ui/icons/Launch';

import { filterImportReport } from '../../actions/reportActions';
import SearchBar from '../Common/SearchBar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    width: '100%',
    minHeight: '30vh',
    // minWidth: '650',
  },
  modalWindow: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    border: 0,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },  
  spinner: {
    minHeight: '35vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },

  clickable: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  iconSmall: {
    color: theme.palette.secondary.main,
    fontSize: '16px'    
  },
}));


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 10 + rand();
  // const left = 50 + rand();

  return {
    outline: 0,
    top: `${top}%`,
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}


const ImportReport = (  ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const report = useSelector((state) => state.importReport, shallowEqual);


  const [activeClass, updateActiveClass] = useState({classId: null, schoolId: null, teacherEmail: null, className: null, startDate: null, endDate: null});
  const [modalStyle] = useState(getModalStyle);
    
  const [modalOpen, updateModalOpen] = useState(false);
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    dispatch(filterImportReport(event.target.value));
  };

  let csvLink;
  const handleDownload = () => {
    csvLink.link.click();    
  }    


  const closeModal = () => {
    updateModalOpen(false);
  }
  
  const fetchClassRoster = async (item) => {
    const { classId, schoolId, teacherEmail, className, startDate, endDate, termId, termName } = item;
    updateActiveClass({ classId, schoolId, teacherEmail, className, startDate, endDate, termId, termName });
    updateModalOpen(true);
    
    
  };
  useEffect(() => {
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.isLoading, shallowEqual]);
    
  

  return (
    <>
    {modalOpen && activeClass && activeClass.classId && (
      
            <Modal
                open={modalOpen}
                className={classes.modal}
                aria-labelledby="classroom report"
                aria-describedby="List of users returned by SIS"
            >
            <div style={modalStyle} className={classes.modalWindow}>
              <ClassEnrollment item={activeClass} handleClick={closeModal} />
            </div>                            
            </Modal>
      )}
          


      {report && report.data && report.results && !report.isLoading && report.data.jobType === 'import' && (
        <>
          <CSVLink
            data={report._results}
            filename="staged_results.csv"
            style={{ display: 'none'}}
            ref={(r) => csvLink = r}                   
            target="_blank"/>

            <Box component="div" m={1} pb={3}>
              <SearchBar handleFilter={handleFilter} handleDownload={handleDownload}/>
            </Box>

          <TableContainer component={Paper} className={classes.table}>
            <TablePagination
                      component="div"
                      rowsPerPageOptions={[5,10,25, 100, 500]}
                      count={report.results.length || 0}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />       
              <Table size="small" aria-label="staged licenses table">
                <TableHead>
                  <TableRow>
                    <TableCell>School</TableCell>
                    <Hidden lgDown>
                      <TableCell>Course</TableCell>                        
                    </Hidden>              
                    <Hidden mdDown>
                      <TableCell>Class</TableCell>
                    </Hidden>
                    <TableCell>ClassId</TableCell>
                    <TableCell>Teacher Email</TableCell>
                    <TableCell>eBook</TableCell>

                    <TableCell align="right">Students</TableCell>
                    <TableCell align="right">Teachers</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={report.isLoading ? 'fadeOut' : 'fadeIn'}>
                  {report.results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={`class-${index}-key`}>
                      <TableCell component="th" scope="row">
                        {row.schoolName}
                      </TableCell>
                      <Hidden lgDown>
                        <TableCell>{row.courseName}</TableCell>
                      </Hidden>
                      <Hidden mdDown>
                        <TableCell>{row.className}</TableCell>
                      </Hidden>
                      <TableCell className={classes.clickable} onClick={()=>fetchClassRoster(row)}>{row.classId} <LaunchIcon className={classes.iconSmall} /></TableCell>
                      <TableCell>{row.teacherEmail}</TableCell>
                      <TableCell>{row.eBook}</TableCell>
                      <TableCell align="right">{row.studentLicenses || 0}</TableCell>              
                      <TableCell align="right">{row.teacherLicenses || 0}</TableCell>              
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </>
  )} 

    </>
  );
};
export {
  ImportReport
};

