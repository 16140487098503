import produce from 'immer';

import {
  FETCH_PUSHREPORT_REQUEST,
  FETCH_PUSHREPORT_SUCCESS,
  FETCH_PUSHREPORT_FAILURE
} from '../actions/actionTypes';

const totalsDefault = {teacherOverage: 0, studentOverage: 0, studentCount: 0, teacherCount: 0, studentUsed: 0, teacherUsed: 0 };
const initialState = { 
  data: {
    date: '',
    jobType: '',
    pool: '',
    runTime: 0,
    success: null,
    daysSinceJob: '',    
    eBooks: {},
    statistics: {},
    warning: []
  },
  totals: totalsDefault,
  isLoading: false,
  error: ''
};

const pushReportReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_PUSHREPORT_FAILURE:
      return { ...initialState, error: action.payload, isLoading: false };
    case FETCH_PUSHREPORT_REQUEST:
      draft.isLoading = true;
      return draft; 
    case FETCH_PUSHREPORT_SUCCESS:
      let totals = totalsDefault;
      if (action.payload.hasOwnProperty('eBooks')) {
        totals = Object.keys(action.payload.eBooks).reduce((memo, index) => {
          // Not all license Pools have teacher license and student licenses so need to seed data to prevent error
          const basedata = action.payload.eBooks[index];
          const databackstop = { teacher: { overages: 0, count: 0, used: 0 }, student: { overages: 0, count: 0, used: 0 } };
          const data = {...databackstop, ...basedata};
          return {
            teacherOverage: memo.teacherOverage += data.teacher?.overages || 0,
            studentOverage: memo.studentOverage += data.student?.overages || 0,
            teacherCount: memo.teacherCount += data.teacher.count || 0,
            studentCount: memo.studentCount += data.student.count || 0,
      
            studentUsed: memo.studentUsed += data.student.used || 0,
            teacherUsed: memo.teacherUsed += data.teacher.used || 0,
          }
        }, {teacherOverage: 0, studentOverage: 0, studentCount: 0, teacherCount: 0, studentUsed: 0, teacherUsed: 0 });
      }
      return {...draft, isLoading: false, data: action.payload, totals };
    default:
      return draft;
  }
}, initialState);

export default pushReportReducer;
