import React from 'react';
import {
    TextField,
} from '@material-ui/core';
import { NumberFormatCustom } from '../NumberFormatCustom';

const EditCurrencyField = ({fieldDisplay, fieldName, fieldValue, required=true, handleChange}) => {
    return (      
            <TextField            
                fullWidth       
                InputLabelProps={{ shrink: true }}
                required={required}
                label={fieldDisplay}
                margin="normal"
                name={fieldName}
                onChange={(e) => handleChange({ [e.target.name]: e.target.value })}
                value={fieldValue || ''}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}                
                />
    )
}

export { EditCurrencyField };