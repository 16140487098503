import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { updateTermOverride } from 'actions';
import { InfoBox } from 'components/Common/InfoBox';

const useStyles = makeStyles((theme) => ({
  boxContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  boxContentNumber: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },

}));

const ModeOverrides = () => {
  
  const overrideTermDate = useSelector((state) => state.district.overrideTermDate);
  const [currentOverrideTermDate, setCurrentOverrideTermDate] = useState(overrideTermDate || null);
  


  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = (date) => {
    setCurrentOverrideTermDate(date);
  };

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(updateTermOverride(currentOverrideTermDate));
  };

  return (
<InfoBox title='Override Term Start Date' tooltip='Use the date override to roster classes that have a start date in the future.' >



  <FormControl component="fieldset">
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
          margin="normal"
          id="overrideTermDate"
          label="Override Start Date"
          format="MMM DD, YYYY"
          value={currentOverrideTermDate}
          onChange={handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </ MuiPickersUtilsProvider>          


    </FormControl>

  <Button
      aria-label="Save Mode Settings"
      className={classes.button}
      color="primary"
      disabled={overrideTermDate === currentOverrideTermDate}
      onClick={handleClick}
      variant="contained"
      fullWidth={true}
      >
      Save
    </Button>          

      

</InfoBox>

  );
};

export default ModeOverrides;
