export const cleanUrlSlashes = (baseUrl='') => {
    if(baseUrl.startsWith('http://')){
        baseUrl = 'https://' + baseUrl.slice(7);
    } else if (!baseUrl.startsWith('https://')) {
        baseUrl = 'https://' + baseUrl;
    }

    if(baseUrl.endsWith('/')){
        baseUrl = baseUrl.slice(0, baseUrl.length - 1);
    }

    return baseUrl;
};
