import produce from 'immer';

import {
  FETCH_DISTRICTPROFILE_REQUEST,
  FETCH_DISTRICTPROFILE_SUCCESS,

  ASSIGN_LICENSE,
  COMPLETE_ONBOARDING,
  SET_DISTRICT_ID,
  TOGGLE_ROSTER_COURSE,
  TOGGLE_ROSTER_SCHOOL,

  UPDATE_DISTRICT_PROFILE_REQUEST,
  UPDATE_DISTRICT_PROFILE_SUCCESS,
  UPDATE_DISTRICT_PROFILE_FAILURE,


  UPDATE_PROFILE_MODE,
  UPDATE_TERM_OVERRIDE,
  UPDATE_PROFILE_ROSTER_TEACHERS,


  VERIFY_ROSTER_CREDENTIALS_SUCCESS,
  VERIFY_ROSTER_CREDENTIALS_REQUEST,
  VERIFY_ROSTER_CREDENTIALS_FAILURE,


  ADD_EXCLUDED_SUCCESS,
  DELETE_EXCLUDED_SUCCESS,
  RESET_SCHEDULE_SUCCESS,
  RESET_SCHEDULE_FAILURE,

  TOGGLE_CERTIFICATION_SUCCESS,
  TOGGLE_CERTIFICATION_FAILURE,

} from '../actions/actionTypes';

const initialState = {
  courses: [],
  isLoading: false,
  error: undefined,
  checkingCredentials: false,
  licensePoolId: undefined,
  districtId: '',
  schools: [],
  licenses: [],
  exclusions: [],
  validProfileCredentials: false,
  validRosterCredentials: false
};

const districtReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_DISTRICT_ID:
      if (draft.districtId === action.payload) {        
          return draft;
      } else {
        return {...initialState, districtId: action.payload, isLoading: false} 
      }
    case FETCH_DISTRICTPROFILE_REQUEST:
      return {...draft, isLoading: true}    
    case FETCH_DISTRICTPROFILE_SUCCESS:
      return {...draft, ...action.payload, validProfileCredentials: draft.validProfileCredentials, checkingCredentials: draft.checkingCredentials, isLoading: false };
    

    // CREDENTIALS FOR ROSTER SERVER
    case VERIFY_ROSTER_CREDENTIALS_REQUEST:
      return { ...draft, checkingCredentials: true, validRosterCredentials: false };
    case VERIFY_ROSTER_CREDENTIALS_SUCCESS:
      return { ...draft, checkingCredentials: false, validRosterCredentials: true };
    case VERIFY_ROSTER_CREDENTIALS_FAILURE:
      return { ...draft, checkingCredentials: false, validRosterCredentials: false };            



    case RESET_SCHEDULE_SUCCESS:
        return { ...draft, ...action.payload };
    case RESET_SCHEDULE_FAILURE:
      return { ...draft, error: action.payload };                    
    case ASSIGN_LICENSE: {
      const { courseId, eBookId } = action.payload;
      const licenseIndex = draft.licenses.findIndex(
        (l) => l.courseId === courseId
      );
      if (licenseIndex !== -1) {
        draft.licenses[licenseIndex].courseId = courseId;
        draft.licenses[licenseIndex].eBookId = eBookId;
      }
      if (licenseIndex === -1) {
        draft.licenses.push({ courseId, eBookId });
      }
      return draft;
    }    
    case COMPLETE_ONBOARDING:
      draft.onboardingComplete = true;
      return draft;
    case TOGGLE_CERTIFICATION_SUCCESS:
      draft.isCertified = action.payload;
      return draft;     
    case TOGGLE_CERTIFICATION_FAILURE:
      return draft;          
    case TOGGLE_ROSTER_COURSE: {
      const { courseId, courseName } = action.payload;
      const courseIndex = draft.courses.findIndex((c) => c.courseId === courseId);
      const licenseIndex = draft.licenses.findIndex(
        (l) => l.courseId === courseId
      );
      if (action.method === 'post') {
        if (courseIndex === -1) {
          draft.courses.push({ courseId, courseName });
        }
        if (licenseIndex === -1) {
          draft.licenses.push({ courseId, eBookId: null });
        }
      }
      if (action.method === 'delete') {
        if (courseIndex !== -1) {
          draft.courses.splice(courseIndex, 1);
        }
        if (licenseIndex !== -1) {
          draft.licenses.splice(licenseIndex, 1);
        }
      }
      return draft;
    }
    case TOGGLE_ROSTER_SCHOOL: {
      const { schoolId, schoolName } = action.payload;
      const schoolIndex = draft.schools.findIndex((s) => s.schoolId === schoolId);
      if (action.method === 'post' && schoolIndex === -1) {
        draft.schools.push({schoolId, schoolName});
      }
      if (action.method === 'delete' && schoolIndex !== -1) {
        draft.schools.splice(schoolIndex, 1);
      }
      return draft;
    }
    case UPDATE_DISTRICT_PROFILE_REQUEST:
      return {
        ...draft,
        isLoading: true,
        error: false
      };
    case UPDATE_DISTRICT_PROFILE_SUCCESS:
      return {
        ...draft,
        isLoading: false,
        ...action.payload
      };
    case UPDATE_DISTRICT_PROFILE_FAILURE:
      return {
        ...draft,
        error: action.payload
      };              
    case UPDATE_PROFILE_MODE:
      draft.mode = action.payload;
      return draft;
    case UPDATE_TERM_OVERRIDE:
      draft.overrideTermDate = action.payload;
      return draft;      
    case UPDATE_PROFILE_ROSTER_TEACHERS:
      draft.rosterTeachers = action.payload;
      return draft;
    case ADD_EXCLUDED_SUCCESS:
      draft.isLoading = false;
      draft.exclusions.push(action.payload)      
      return draft;      
    case DELETE_EXCLUDED_SUCCESS:
      draft.isLoading = false;
      draft.exclusions = draft.exclusions.filter(item => item._id !== action.payload) ;
      return draft;
    default:      
      return draft;
  }
}, initialState);

export default districtReducer;
