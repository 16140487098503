import { SSO_REDIRECT } from 'env';
import React, { useEffect } from 'react';
import { useHistory  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwt from 'jsonwebtoken';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Container, Paper, Typography } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%' // Fix IE 11 issue.
  },
  logo: {
    width: '150px'
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  title: {
    color: 'rgb(14, 108, 67)',
    fontWeight: 600
  }
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  
  const { isAdmin, isAuthenticated } = useSelector((state) => state.auth);


  const handleSubmit = (e) => {
    e.preventDefault();
    window.location = SSO_REDIRECT;
  };

  useEffect(() => {
    const token = jwt.decode(
      document.cookie.replace(
        /(?:(?:^|.*;\s*)cpm-sso-token\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
      )
    );
    if (token) {
      // dispatch(validateUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);
  

  useEffect(() => {
    if (isAuthenticated) {
       if (isAdmin) {
         const impersonateId = sessionStorage.getItem('X-Impersonate');
         if (impersonateId) {
          history.replace('/dashboard');
         } else {
          history.replace('/admin');
         }
        } else {
          history.replace('/dashboard');
        }            
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])    



  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper} elevation={2} square>
        <Box alignItems="center" display="flex" flexDirection="column">
          <img alt="CPM Logo" className={classes.logo} src="/CPM.svg" />
          <Typography className={classes.title} variant="body2">
            Integration Services
          </Typography>
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Button
            className={classes.submit}
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
          >
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export {
  Login
};
