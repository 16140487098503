import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  FormControlLabel,
  Typography,
  Divider,
  Switch,
} from '@material-ui/core';


import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { toggleCertification } from 'actions';
import { InfoBox } from 'components/Common/InfoBox';
const useStyles = makeStyles((theme) => ({
  mailTo: {    
    color: theme.palette.secondary.dark,
    textDecoration: 'none'
  }            
}));


const CPMCertify = () => {
  const isCertified = useSelector((state) => state.district.isCertified);
  const { isAdmin } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = () => {
    console.log('hello', !isCertified)
    dispatch(toggleCertification({isCertified: !isCertified}));
  };

  const openSchedule = () => {
    window.open("https://calendar.google.com/calendar/u/0/selfsched?sstoken=UUY3WktydUQ5TzUxfGRlZmF1bHR8ZDMyMDk1OWQ0Y2Y1MTg4ZTY1ZTU4ODU1MjRhZjMwZGU");
  }

  const MyTooltip = () => {
    return (
      <>
      Certification:
      <Divider /> A CPM Integration Specialist will certify settings to ensure a smooth transition to automated rostered
      </>
    )
  }

  return (
    <>
    <InfoBox title='Certification' tooltip={MyTooltip()} >
    <Box grow={1} />
    <Typography
            component="h1"
            variant="body1"
          >
            A CPM Integration Specialist will meet with you to certify your integration settings before going live.
          </Typography> 
          <Box grow={1} />    
          <span onClick={openSchedule} className='clickable'>
          <InsertInvitationIcon fontSize="large"/>
          </span>
          <span onClick={openSchedule} className={`${classes.mailTo} clickable`}>
          <Typography variant="subtitle1">
          Click to schedule
          </Typography>
          </span>
          <Box grow={1} />
          {isAdmin && (
          <FormControl component="fieldset">

        <FormControlLabel
            control={
              <Switch
                checked={isCertified || false}
                onChange={handleChange}
                name="rosterTeachers"
                color="primary"
              />
            }
            label={isCertified ? 'Certified' : 'Pending Certification'} 
            />
          </FormControl>
          )}

          <Box grow={1} />
    </InfoBox>
    </>
  );
};

export {
  CPMCertify
};
