import React, { useEffect, useState, useReducer } from 'react';
import { API_URL } from 'env';
import { axiosWithAuth } from 'utils/axiosWithAuth';
import { CSVLink } from "react-csv";
import Alert from '@material-ui/lab/Alert';
import { DynamicFormat } from '../Common/DynamicFormat';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Hidden,
    Typography
  } from '@material-ui/core';

  import {
    Box,
    makeStyles, 
    Paper,
    Avatar,
    Card,
    CardHeader,
    CardContent,
    IconButton,   
  } from '@material-ui/core';  

// ICONS
import { CircularProgress } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ClassIcon from '@material-ui/icons/Class';
import SearchBar from '../Common/SearchBar';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    spinner: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(6)
      },
      searchBottom: {
        clear: 'both',
        marginBottom: '3em',
      },
      searchWrapper: {
        clear: 'both',
        marginBottom: '3em',        
      },
      
}));


const classInit = {isLoading: false, hasError: false, error: null, _roster: [], roster:[], searchFilter: ''};
const classReducer = (state, action) => {
    switch(action.type) {
        case 'REQUEST':
            return {...classInit, isLoading: true };
        case 'REQUEST_SUCCESS':
            return { ...classInit, _roster: action.payload, roster: action.payload };
        case 'REQUEST_FAILED':          
            return { isLoading: false, hasError: true, error: action.payload };
        case 'SEARCH':
          const searchTerm = action.payload;
          return {...state, roster: 
            state._roster.reduce((memo, item) => {
              if (item.email.includes(searchTerm) || item.firstName.includes(searchTerm) || item.lastName.includes(searchTerm)) {
                return [...memo, item];
              } else {
                return memo;
              }
            }, [])
          };
        default:
            return state;
    }
}



const ClassEnrollment = ( { item: { classId, schoolId, className, teacherEmail, startDate, endDate, termId, termName }, handleClick }) => {  
    const classes = useStyles();
    const [classRoster, updateClassRoster] = useReducer(classReducer, classInit);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    const handleFilter = (event) => {
      updateClassRoster({ type: 'SEARCH', payload: event.target.value });    
    };

    let csvLink;
    const handleDownload = () => {
      csvLink.link.click();      
    }    

    const fetchClassRoster = async ( ) => {
        try {  
          updateClassRoster({type: 'REQUEST'});
          const response = await axiosWithAuth().post(`${API_URL}/api/v1/roster/class/enrollment`, { classId, schoolId });
          updateClassRoster({type: 'REQUEST_SUCCESS', payload: response.data});
          
        } catch (e) {
          updateClassRoster({type: 'REQUEST_FAILED', payload: e.response.data});
        }
      };
      useEffect(() => {
        setPage(0);
        fetchClassRoster();

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [classId]);
      

  
    return (

        <Card>      
          <CardHeader
              avatar={
                <Avatar aria-label="class" className={classes.avatar}>
                 <ClassIcon />
                </Avatar>
              }
              action={
                <IconButton 
                onClick={handleClick}
                aria-label="close"
                >
                  <CancelIcon />
                </IconButton>
              }
              title={
                <Typography variant="button" gutterBottom>
                  <strong>{className || ''}</strong>
                </Typography>                
              }
              subheader={
              <>
                <strong>class id:</strong> {classId}<br />
                <strong>termId:</strong> {termId} ({termName}) <br />
                <strong>dates:</strong> <DynamicFormat fieldValue={startDate} fieldType='utc-date' /> - <DynamicFormat fieldValue={endDate} fieldType='utc-date' />
                </>

              }
              />


        <CardContent>
         
          {classRoster && classRoster.hasError ? (
            <Alert severity="error">Error retrieving data from roster server - {classRoster.error}</Alert>
          )
          : (            
          <>
            {classRoster && classRoster.isLoading && !classRoster.hasError ? (
              <div className={classes.spinner}>
                <CircularProgress color="secondary" size={100} />
              </div>
            ) : (
            <>
            <CSVLink
              data={classRoster._roster}
              filename="class_enrollment.csv"
              style={{ display: 'none'}}
              ref={(r) => csvLink = r}                   
              target="_blank"/>


              <Box component="div" m={1} pb={3}>
                <SearchBar handleFilter={handleFilter} handleDownload={handleDownload}/>
              </Box>

            <TableContainer component={Paper}>     
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10]}
                  count={classRoster.roster.length || 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />                       
                <Table className={classes.table} size="small" aria-label="Current class enrollment">
                  <TableHead>
                    <TableRow>
                      <TableCell>Role</TableCell>
                      <Hidden xsDown>
                        <Hidden mdDown>
                          <TableCell>User Id</TableCell>
                          <TableCell>Roster Id</TableCell>
                          <TableCell>Login</TableCell>
                        </Hidden>

                        <TableCell>First</TableCell>
                        <TableCell>Last</TableCell>

                      </Hidden>
                      <TableCell>Email</TableCell>                        
                    </TableRow>
                  </TableHead>
                  <TableBody>           

                  {classRoster && 
                  classRoster.roster.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map( (row, index) => (
                    <TableRow key={`enrollment-${index}-key`}>
                      <TableCell>{row.schoolRole}</TableCell>
                      <Hidden xsDown>
                      <Hidden mdDown>
                        <TableCell>{row.userId}</TableCell>
                        <TableCell>{row.rosterSourceId}</TableCell>
                        <TableCell>{row.login}</TableCell>
                      </Hidden>
                        <TableCell>{row.firstName}</TableCell>
                        <TableCell>{row.lastName}</TableCell>                          
                      </Hidden>
                      <TableCell>{row.email}</TableCell>                  
                    </TableRow>              
                  ))}

                  </TableBody>
                </Table>
              
              </TableContainer>
            </>
          )}
          </>
          )}

        </CardContent>
      </Card>
    );  
}

export {
    ClassEnrollment
};