import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {  fetchLicensePools } from 'actions';
import { makeStyles } from '@material-ui/core/styles';
import {   
  colors,
  Icon
} from '@material-ui/core';

import DnsSharpIcon from '@material-ui/icons/DnsSharp';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { DashboardInfoBox } from 'components/DashboardInfoBox';

import HttpsIcon from '@material-ui/icons/Https';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';

const useStyles = makeStyles((theme) => ({
    errorMessage: {
      alignItems: 'center',
      borderRadius: 0,
      display: 'flex',
      minHeight: '64px'
    },
    gridContainer: {
      // maxWidth: '1040px',
      minHeight: '64px'
    },
    link: {
      textDecoration: 'none'
    },
    spinner: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    marginLeft: {
      marginLeft: theme.spacing(2)
    },
    successIcon: {
      color: theme.palette.secondary.light,
      fontSize: '56px',
    },
    successIconSmall: {
        color: theme.palette.success.main,
        fontSize: '24px'    
    },  
    infoIcon: {
      color: theme.palette.info.main,
      fontSize: '56px',
      '& small': {
        fontSize: '24px',
      }    
    },  
    warningIcon: {
      color: colors.orange,
      fontSize: '56px',    
    },
    warningIconSmall: {
      color: colors.orange[600],
      fontSize: '24px'    
    },
    uncheckedIconSmall: {
      color: colors.blueGrey[600],
      fontSize: '24px'    
    },  
    errorIconSmall: {
      color: colors.red[600],
      fontSize: '24px'    
    },
  }));



const IntegrationStatus = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const district = useSelector((state) => state.district, shallowEqual);
    const { isCertified, licensePoolId } = useSelector((state) => state.district, shallowEqual);
    const licensePools = useSelector((state) => state.licensePools, shallowEqual);
    const rosteredSchools = useSelector((state) => state.district.schools.length);
    const rosteredCourses = useSelector((state) => state.district.courses.length);
    
    const assignedLicenses = useSelector(
      (state) => state.district.licenses.filter((l) => l.eBookId).length
    );


    const MyCheckBoxIcon = (criteria) => {
      return (
        <Icon className={`${criteria ? classes.successIconSmall : classes.uncheckedIconSmall}`}>{criteria ? 'check_box' : 'check_box_outline_blank'}</Icon>
      )
    }

    const [dashboardData, updateDashboardData] = useState([]);

      useEffect(() => {
        if (district._id) {            
            resetDashboardData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [district, district.checkingCredentials]);
      
      useEffect(() => {
            dispatch(fetchLicensePools());
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      
      const getLicensePoolStatus = () => {
        if (licensePoolId && licensePools && licensePools.length) {
          const myLicensePool = licensePools.find((x => x.id === licensePoolId));
          if (myLicensePool && myLicensePool.isRostered) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      const getCredentialsStatus = () => {
        if (district.checkingCredentials) {
            return {
              display: 'Checking Credentials',
              icon: <LockOpenIcon className={`${classes.infoIcon.small} icon-pulse`} />
            }    
          }
          if (district.validRosterCredentials) {
            return {
              display: 'Credentials are valid',
              icon: <HttpsIcon className={`${classes.successIconSmall}`} />
            }    
          }
          if (!district.validRosterCredentials) {
            return {
              display: 'Credentials are invalid',
              icon: <NoEncryptionIcon className={`${classes.errorIconSmall}`} />
            }    
          }           

      }

      const resetDashboardData = () => {      
        const scheduleIcon = (district.mode === 'production') ? <SyncAltOutlinedIcon className={classes.successIconSmall} /> : <PauseCircleFilledRoundedIcon className={classes.warningIconSmall} />;              
        const credentialsStatus = getCredentialsStatus();
        const licensePoolStatus = getLicensePoolStatus();
        const myContent = [
          {
            key: 'credentials',
            ...credentialsStatus,
            link: '/profile'
          },                        
           
          // {
          //   key: 'dates',
          //   display: 'Override Dates',
          //   icon: <DateRangeIcon className={`${classes.successIconSmall}`} />,
          //   link: '/settings'
          // },             
          {
            key: 'schools',
            display: rosteredSchools === 1 ? `${rosteredSchools} School Rostered` : `${rosteredSchools} Schools Rostered`,
            icon: MyCheckBoxIcon(rosteredSchools > 0),
            // icon: <SchoolSharpIcon className={`${classes.successIconSmall}`} />,
            link: '/schools'
          },
          {
            key: 'courses',
            display: rosteredCourses === 1 ? `${rosteredCourses} Course Rostered` : `${rosteredCourses} Courses Rostered`,
            icon: MyCheckBoxIcon(rosteredCourses > 0),
            // icon: <ClassSharpIcon className={`${classes.successIconSmall}`} />,
            link: '/courses'
          },
          {
            key: 'licenses',
            display: assignedLicenses === 1 ? `${assignedLicenses} eBooks Mapped` : `${assignedLicenses} eBooks Mapped`,
            icon: MyCheckBoxIcon(assignedLicenses > 0),            
            link: '/licenses'
          },
            
          {
            key: 'licensePool',
            display: licensePoolStatus ? `License Pool Assigned` : licensePoolId ? `Invalid License Pool Assigned` : `License Pool Not Assigned`,
            icon: MyCheckBoxIcon(licensePoolStatus),
            link: '/licensepool'
          },          
          {
            key: 'certify',
            display: 'CPM Certification',
            icon: MyCheckBoxIcon(isCertified),
            link: '/certify'
          },       
          {
            key: 'schedule',
            display: district.mode === 'production' ? 'Schedule is Live' : 'Schedule is Paused',
            icon: scheduleIcon,
            link: '/schedule'
          },    
                           
        ];        
        updateDashboardData(myContent);
      }
      
      return (

          <DashboardInfoBox
          content={dashboardData}
          headingIcon={<DnsSharpIcon />}
          headingTitle="Integration Settings Checklist"
          />
        );
  


}

export { IntegrationStatus };