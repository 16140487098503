import { createSlice } from '@reduxjs/toolkit'
import {
    fetchStickyLicenses,
    fetchStickyLicenseCounts,
    addStickyLicenseProfile,
    editStickyLicenseProfile,
    deleteStickyLicenseProfile,
    appendStickyLicense,
    deleteStickyLicense
  } from 'actions';


const initialState = {
    data: [],
    counts: {
        total: 0,
        licenses: {}
    },
    filter: '',
    isLoading: false,
    error: null
  };



// https://stackoverflow.com/questions/62977443/how-to-reuse-the-reducer-logic-in-redux-toolkit-createslice-function  

  export const stickyLicensesSlice = createSlice({
    name: 'stickylicenses',
    initialState,
    reducers: {
        setStickyLicensesFilter: (state, action) => {
            state.filter = action.payload;
        },
        resetStickyLicensesFilter: (state) => {
            state.filter = '';
        }

    },
    extraReducers: (builder) => {
        builder.addCase(fetchStickyLicenses.pending, (state) => {
            state.loading = true;
            state.data = [];
            state.error = null;
        })
        .addCase(fetchStickyLicenses.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload;
        })
        .addCase(fetchStickyLicenses.rejected, (state, { error }) => {
            state.loading = false
            state.error = error.message;
        })

        // Get counts
        .addCase(fetchStickyLicenseCounts.pending, (state) => {
            state.loading = true
            state.error = null;
        })
        .addCase(fetchStickyLicenseCounts.fulfilled, (state, { payload }) => {
            state.loading = false
            state.counts = payload;
        })
        .addCase(fetchStickyLicenseCounts.rejected, (state, { error }) => {
            state.loading = false
            state.error = error.message;
        })

        // Create new user sticky license profile
        .addCase(addStickyLicenseProfile.pending, (state) => {
            state.loading = true
            state.error = null;
        })
        .addCase(addStickyLicenseProfile.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data.push(payload);
        })
        .addCase(addStickyLicenseProfile.rejected, (state, { error }) => {
            state.loading = false
            state.error = error.message;
        })

        // Update existing user sticky license profile
        .addCase(editStickyLicenseProfile.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(editStickyLicenseProfile.fulfilled, (state, { payload }) => {
            state.loading = false;
            const myIndex = state.data.findIndex(element => element.email === payload.email);
            state.data[myIndex] = payload;
        })
        .addCase(editStickyLicenseProfile.rejected, (state, { error }) => {
            state.loading = false
            state.error = error.message;
        })        

        // Delete existing user sticky license profile
        .addCase(deleteStickyLicenseProfile.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(deleteStickyLicenseProfile.fulfilled, (state, { payload }) => {
            state.loading = false;
            const myIndex = state.data.findIndex(element => element._id === payload);
            state.data.splice(myIndex, 1);
        })
        .addCase(deleteStickyLicenseProfile.rejected, (state, { error }) => {
            state.loading = false
            state.error = error.message;
        }) 

        // Add license to existing user
        .addCase(appendStickyLicense.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(appendStickyLicense.fulfilled, (state, { payload }) => {
            state.loading = false;
            const myIndex = state.data.findIndex(element => element.email === payload.email);
            state.data[myIndex] = payload;
        })
        .addCase(appendStickyLicense.rejected, (state, { error }) => {
            state.loading = false
            state.error = error.message;
        })
        // Remove license to existing user
        .addCase(deleteStickyLicense.pending, (state) => {
            state.loading = true
            state.error = null;
        })
        .addCase(deleteStickyLicense.fulfilled, (state, { payload }) => {
            state.loading = false;
            const myIndex = state.data.findIndex(element => element.email === payload.email);
            state.data[myIndex] = payload;
            // state.data = state.data.reduce((memo, item) => {       
            //     return payload._id === item._id ? [...memo, item] : memo;
            // }, []);
        })
        .addCase(deleteStickyLicense.rejected, (state, { error }) => {
            state.loading = false
            state.error = error.message;
        })                                
    },
})    

export const { setStickyLicensesFilter, resetStickyLicensesFilter } =  stickyLicensesSlice.actions;
export const stickyLicensesReducer = stickyLicensesSlice.reducer;