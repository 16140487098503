import React, { useEffect, useState, useReducer, createRef } from 'react';
import ReactDOM from 'react-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { differenceBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

import { ToggleText, StickyBooksList } from './components';

import {
  appendStickyLicense,
  deleteStickyLicense,
  editStickyLicenseProfile,
  deleteStickyLicenseProfile
} from 'actions';
import cleanObject from "../../utils/cleanObject";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    width: '100%'
  },
  flexContainer: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    display: 'flex'
  },
  arrow: {
    color: theme.palette.secondary.main
  },
  button: {
    width: '100%'
  },
  close: {
    cursor: 'pointer'
  },
  columnHeader: {
    fontWeight: 600
  },
  deleteButton: {
    backgroundColor: theme.palette.danger.light,
    color: 'white',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    }
  },
  activeLink: {    
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    textDecoration: 'underline'
  },
  inactiveLink: {    
    cursor: 'pointer',
    textDecoration: 'none'
  },  
  divider: {
    margin: theme.spacing(2, 0)
  },
  eBooks: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginBottom: '8px'
  },

  eBooksHeader: {
    margin: '8px 0',
    fontWeight: 600
  },
  editButton: {
    marginTop: theme.spacing(2)
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  halfWidth: {
    width: '49%'
  },
  list: {
    backgroundColor: 'white',
    flex: '1',
    height: '100vh'
  },
  listsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  modalContainer: {
    position: 'fixed',
    zIndex: 9999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,.33)'
  },
  modalContent: {
    margin: '0 auto',
    padding: theme.spacing(3),
    maxWidth: '800px'
  },
  paper: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingLeft: {
    display: 'flex'
  },
  paperHeadingTitle: {
    marginRight: theme.spacing(0.5)
  },
  paperInput: {
    padding: theme.spacing(3)
  },
  paperInputField: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  paperList: {
    flex: '1',
    height: '100vh',
    overflow: 'hidden',
    '&:first-child': {
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
        marginRight: 0
      }
    }
  },
  paperOtherContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  row: {
    padding: theme.spacing(0, 3)
  },
  selectStyle: {
    marginBottom: theme.spacing(1),
    // marginTop: theme.spacing(2),
    width: '100%'
  },
  spacer: {
    margin: '0px 12px',
    width: '1px'
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },
  teacherProfile: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%'
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    justifyContent: 'center'
  },
  warningIcon: {
    color: '#f1c40f',
    fontSize: '80px'
  }
}));
const toDisplayString = (item) => {
  return item.replace(/([A-Z])/g, ' $1')
  .replace(/^./, function(str){ return str.toUpperCase(); })
}

const profileAllowed = ['_id', 'email', 'login', 'schoolId', 'schoolName', 'firstName', 'lastName'];
const localStickyLicenseProfileReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      const cleanData = cleanObject(action.payload, profileAllowed);
      return {...state, ...cleanData};    
    default: 
      console.log("Unsupported action for sticky license profile");
    }
}

const EditStickyLicenseModal = ({
  currentStickyLicense,
  setCurrentStickyLicense,
  toggleModal
}) => {
  const form = createRef();
  const { isAdmin } = useSelector((state) => state.auth);
  const [localStickyProfile, updateLocalStickyProfile] = useReducer(localStickyLicenseProfileReducer, cleanObject(currentStickyLicense, profileAllowed));
  const [isTeacher, updateIsTeacher] = useState((currentStickyLicense.schoolRole === 'Teacher'));
  const licenses = useSelector((state) => state.licenses);
  const { schools } = useSelector((state) => state.schools);
  const license = useSelector(
    (state) => state.stickyLicenses.data.find(x => x.email === currentStickyLicense.email),
    shallowEqual
  );
  const [availableLicenses, updateAvailableLicenses] = useState([]);
  const [edit, setEdit] = useState(false);
  
  const [lastAddedEbookId, setLastAddedEbookId] = useState();

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
      if(form.current.reportValidity()) {        
        dispatch(editStickyLicenseProfile(localStickyProfile));
        setEdit(false)
      } 
  };
  const handleAddEbook = (e) => {
    const eBook = e.target.value;
    const { eBookId, eBookName } = eBook;
    
    setLastAddedEbookId(eBookId);
    const { _id: stickyLicenseId } = license;
    dispatch(
      appendStickyLicense({ stickyLicenseId, eBookId, eBookName })
    );
  };

  const handleRemoveEbook = (eBookLicense) => {
    const { _id: stickyLicenseId } = license;
    const { eBookId } = eBookLicense;
    dispatch(deleteStickyLicense({ stickyLicenseId, eBookId } ));    
  };


  const handleDeleteProfile = () => {
    dispatch(deleteStickyLicenseProfile(localStickyProfile));
    toggleModal();    
  };

  const handleChange = (e) => {
    const value = e.target.value.trim();
    const payload = { [e.target.name]: value };    
    updateLocalStickyProfile({ type: 'update', payload })
  };

  const updateSchool = (newSchool) => {
    updateLocalStickyProfile({ type: 'update', payload: newSchool })
  }

  const filter = createFilterOptions();

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        toggleModal(null);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
      setCurrentStickyLicense(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(licenses && Array.isArray(licenses.licenses)) {
      const myAvailableLicenses = differenceBy( licenses.licenses, license.classes, 'eBookId')
        .filter(x => x.isTeacherEdition === isTeacher && x.licenses > 0);
      updateAvailableLicenses(myAvailableLicenses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license, isTeacher]);



  return ReactDOM.createPortal(
    <div className={classes.modalContainer}>
      <div className={classes.modalContent}>
        <Paper className={classes.paper} elevation={2} square>
          <div className={classes.paperHeading}>
            <Typography
              className={classes.paperHeadingTitle}
              component="h1"
              variant="body1"
            >
              Edit Sticky License
            </Typography>
            <CloseSharpIcon
              className={classes.close}
              onClick={() => toggleModal()}
            />
          </div>
          <div className={classes.paperContent}>
            <div className={classes.flexColumn}>
              <form ref={form} onSubmit={e => e.preventDefault()} noValidate>
              <div className={classes.teacherProfile}>

                <div className={classes.fullWidth}>
                  <Typography style={{ fontWeight: '600' }} variant="body1">
                    Teacher Profile
                  </Typography>
                </div>

              {['firstName', 'lastName', 'email', 'login'].map(item => (
                  <div className={classes.halfWidth} key={item}>
                  <TextField
                    autoComplete="text"
                    autoFocus
                    disabled={!edit}
                    fullWidth
                    id={item}
                    InputLabelProps={{ shrink: true }}
                    label={toDisplayString(item)}
                    margin="normal"
                    name={item}
                    onChange={handleChange}
                    required
                    value={(localStickyProfile && localStickyProfile[item]) || ''}
                    variant="outlined"
                  />
                </div>
              ))}
                <Autocomplete
                  disabled={!edit}
                  fullWidth
                  value={(localStickyProfile && localStickyProfile.schoolName) || ''}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      const { schoolId, schoolName } = newValue;
                      updateSchool({ schoolId, schoolName });
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  selectOnFocus
                  handleHomeEndKeys
                  id="schools-autocomplete"
                  options={schools}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return option.schoolName;
                  }}
                  renderOption={(option) => option.schoolName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      label="Primary School"
                      variant="outlined"
                    />
                  )}
                />              
                <div className={classes.actions}>
                  {!edit && (
                    <>
                        <Button
                          aria-label="Edit Teacher Profile"
                          className={classes.button}
                          color="primary"
                          onClick={() => setEdit(true)}
                          variant="contained"
                        >
                          Edit Profile
                        </Button>

                        <div className={classes.spacer} />
                      <Button
                        aria-label="Delete Teacher Profile"
                        className={classes.deleteButton}
                        onClick={handleDeleteProfile}
                        variant="contained"
                        disabled={!license || !license.classes || license.classes.length > 0}
                      >
                        Delete Profile
                      </Button>                        
                    </>
                  )}
                  {edit && (
                    <>
                      <Button
                        aria-label="Cancel Edit Teacher Profile"
                        className={classes.button}
                        onClick={() => setEdit(false)}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                      <div className={classes.spacer} />
                      <Button
                        aria-label="Save Teacher Profile"
                        className={classes.button}
                        color="primary"
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        Save
                      </Button>
                    </>
                  )}
                  
                </div>
              </div>
                </form>
              <Divider className={classes.divider} />
              <div className={classes.fullWidth}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography style={{ fontWeight: '600' }} variant="body1">
                  Licenses
                </Typography>
              </Grid>
           
              </div>
              {isAdmin && (
              <div className={classes.fullWidth}>
                <ToggleText items={["Teacher", "Student"]} activeIndex={isTeacher ? 0 : 1} handleClick={(myIndex) => updateIsTeacher(myIndex === 0 ? true : false)}/>
              </div>
              )}

              <div className={classes.fullWidth}>
                <FormControl className={classes.selectStyle}>
                  <TextField
                    label="Add license"
                    name="eBooks"
                    onChange={handleAddEbook}
                    select
                    value=""
                    variant="outlined"
                  >
                    {availableLicenses.map((l) => (
                      <MenuItem key={`new-${l.eBookId}-eBook`} value={l}>
                        {l.eBookName}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
                {license && license.classes && license.classes.length > 0 &&
                <div>
                  <div className={classes.eBooksHeader}>Assigned Licenses:</div>
                  <StickyBooksList licenses={license.classes} lastAddedEbookId={lastAddedEbookId} handleClick={handleRemoveEbook} />
                </div>
                 }
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>,
    document.querySelector('#modal')
  );
};

export { EditStickyLicenseModal };
