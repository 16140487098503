import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector, batch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Container,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import HelpIconSharp from '@material-ui/icons/HelpSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';

import { AddStickyLicenseModal, EditStickyLicenseModal } from './index';

 
import {
  fetchSchools,
  fetchLicenses,
  fetchStickyLicenses,
} from 'actions';

import {
  resetStickyLicensesFilter,
  setStickyLicensesFilter
} from '../../reducers/stickyLicensesReducer';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main
  },
  columnHeader: {
    fontWeight: 600
  },
  divider: {
    margin: theme.spacing(0.5, 0)
  },
  list: {
    backgroundColor: 'white',
    flex: '1',
    height: '100vh'
  },
  listsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  paper: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.info.light,    
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingLeft: {
    display: 'flex'
  },
  paperHeadingTitle: {
    marginRight: theme.spacing(0.5)
  },
  paperInput: {
    padding: theme.spacing(3)
  },
  paperInputField: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  paperList: {
    flex: '1',
    height: '100vh',
    overflow: 'hidden',
    '&:first-child': {
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
        marginRight: 0
      }
    }
  },
  paperOtherContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  row: {
    padding: theme.spacing(0, 3)
  },
  spinner: {
    minHeight: '50vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },
  tooltip: {
    backgroundColor: theme.palette.success.main,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    justifyContent: 'center'
  },
  warningIcon: {
    color: '#f1c40f',
    fontSize: '80px'
  }
}));

const StickyLicenses = () => {
  const stickyLicenses = useSelector((state) => state.stickyLicenses.data, shallowEqual);
  const stickyLicensesFilter = useSelector((state) => state.stickyLicenses.filter);
  const isLoading = useSelector((state) => state.stickyLicenses.isLoading);
  const [currentStickyLicense, setCurrentStickyLicense] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  const match = new RegExp(stickyLicensesFilter, 'i');

  const sortLicenses = (a, b) => {
    const nameA = a.lastName.toUpperCase();
    const nameB = b.lastName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const filteredStickyLicenses = stickyLicenses
    .filter(
      (l) =>
        match.test(l.lastName) ||
        match.test(l.firstName) ||
        match.test(l.login) ||
        match.test(l.email)
    )
    .sort((a, b) => sortLicenses(a, b));

  const handleFilter = (searchString) => {
    dispatch(setStickyLicensesFilter(searchString));
  };

  const toggleAddModal = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const toggleEditModal = (license) => {
    if (license === undefined || license === null) {
      setCurrentStickyLicense(null);
    } else {
      setCurrentStickyLicense(license);
    }

    setShowEditModal((prevState) => !prevState);
  };

  useEffect(() => {
      batch(() => {
        dispatch(fetchSchools());
        dispatch(fetchLicenses());
        dispatch(fetchStickyLicenses());
      });    
    
    return () => {
      dispatch(resetStickyLicensesFilter());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // useEffect(() => {
  //   console.log('render!');
  //   return () => console.log('unmounting...');
  // }, []);
  

  return (
    <>
      {isLoading ? (
        <div className={classes.spinner}>
          <CircularProgress color="secondary" size={100} />
        </div>
      ) : (
        <>
          <Paper className={classes.paper} elevation={2} square>
            <div className={classes.paperHeading}>
              <div className={classes.paperHeadingLeft}>
                <Typography
                  className={classes.paperHeadingTitle}
                  component="h1"
                  variant="body1"
                >
                  Teacher Sticky Licenses
                </Typography>
                <Tooltip
                  arrow
                  classes={{
                    arrow: classes.arrow,
                    tooltip: classes.tooltip
                  }}
                  placement="right"
                  title={
                    <div className={classes.tooltip}>
                      <Typography variant="subtitle2">Login information must match exactly for SSO to work</Typography>
                    </div>
                  }
                >
                  <HelpIconSharp aria-label="Help Text" fontSize="small" />
                </Tooltip>
              </div>

              
              <Button
                aria-label="Add Sticky License"
                color='secondary'
                onClick={toggleAddModal}
                variant="outlined"
              >
                ADD STICKY LICENSE
              </Button>
            </div>
            <div className={classes.paperInput}>
                  <TextField
                    className={classes.paperInputField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchSharpIcon />
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => handleFilter(e.target.value)}
                    placeholder="Search sticky licenses"
                    value={stickyLicensesFilter}
                  />
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-label="Sticky Licenses Table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.columnHeader}>
                            Role
                          </TableCell>
                          <TableCell className={classes.columnHeader}>
                            Primary School
                          </TableCell>                          
                          <TableCell className={classes.columnHeader}>
                            Last Name
                          </TableCell>                          
                          <TableCell className={classes.columnHeader}>
                            First Name
                          </TableCell>
                          <TableCell className={classes.columnHeader}>
                            Login
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.columnHeader}
                          >
                            Licenses
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody className='fadeIn'>                      
                        {!isLoading && Array.isArray(stickyLicenses) && stickyLicenses.length > 0 && filteredStickyLicenses.map((license) => (
                          <TableRow key={license.login}>
                            <TableCell>{license.schoolRole}</TableCell>
                            <TableCell>{license.schoolName}</TableCell>
                            <TableCell component="th" scope="row">
                              {license.lastName}
                            </TableCell>
                            <TableCell>{license.firstName}</TableCell>
                            <TableCell>{license.login}</TableCell>
                            <TableCell align="center">
                              {license.classes && license.classes.length}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                aria-label="Edit Sticky License"
                                color="primary"
                                onClick={() => toggleEditModal(license)}
                                variant="contained"
                              >
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                  {!isLoading && stickyLicenses.length < 1 &&  (
                <>
                  <Container className={classes.paperOtherContent}>
                    <Typography variant="h3">
                       0
                    </Typography>
                    <Typography variant="body2">Sticky Licenses</Typography>
                  </Container>
                </>
                )}

            </div>
          </Paper>
        </>
      )}
      {showAddModal && <AddStickyLicenseModal toggleModal={toggleAddModal} />}
      {showEditModal && (
        <EditStickyLicenseModal
          currentStickyLicense={currentStickyLicense}
          toggleModal={toggleEditModal}
          setCurrentStickyLicense={setCurrentStickyLicense}
        />
      )}
    </>
  );
};

export { StickyLicenses };
