import React from 'react';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Pluralize from 'react-pluralize'
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Typography
  } from '@material-ui/core';
  import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

  const useStyles = makeStyles((theme) => ({
    boxContent: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        padding: 0
      },
      boxContentNumber: {
        display: 'flex',
        justifyContent: 'center'
      },
      button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      },
      paperContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        padding: theme.spacing(3),        
      },
      paperHeading: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: "white",
        display: 'flex',
        margin: 0,
        padding: theme.spacing(2)
      },
      paperHeadingTitle: {
        marginRight: theme.spacing(0.5)
      },
      warningIcon: {
        color: '#ff9800',
        fontSize: '56px'
      }
  }));


const ImportDashboard = ( ) => {
    const classes = useStyles();       
    const { data: importReport, totals } = useSelector((state) => state.importReport, shallowEqual);
    const syncNumber = (importReport && importReport.hoursSinceJob < 24) ? importReport.hoursSinceJob : importReport.daysSinceJob;
    // const syncNumber = 1;
    const syncText = (importReport && importReport.hoursSinceJob < 24) ? `hour` : `day`;
    
      return (    
    <Paper className={classes.paper} elevation={4} square>
      <div className={classes.paperHeading}>
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >        
          <Typography
            className={classes.paperHeadingTitle}
            component="h3"
            variant="body1"
          >
            Last Roster Import
          </Typography>
          <CloudDownloadOutlinedIcon />
        </Grid>
      </div>
      <div className={classes.paperContent}>

      <Container className={classes.boxContent}>
            <Typography className={classes.boxContentNumber} variant="caption" color="primary">
                Last completed
            </Typography>          
            {importReport && importReport._id ? (
                  <>            
              <Typography className={classes.boxContentNumber} variant="h4" color="primary">                
                  <Pluralize singular={syncText} count={syncNumber} /> <Box pl={1}> ago </Box>
              </Typography>
              <Typography className={classes.boxContentNumber} variant="caption">{moment(importReport.date).local().format('MMM DD, YYYY LT')}</Typography>  
              </>
                ) : (
                  <Typography className={classes.boxContentNumber} variant="h4" color="primary">
                    No Record
                  </Typography>
                )}
                
            </Container>
            <Button
              className={classes.button}
              aria-label="View Report"
              color="primary"
              variant="outlined"
              component={RouterLink}
              to={importReport._id ? `/import/${importReport._id}` : '/import'}
            >
              View
            </Button>


          <Container className={classes.boxContent}>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"

            >  
                <Grid item>
                    <Typography className={classes.boxContentNumber} variant="h5">
                    <NumberFormat                            
                        displayType={'text'}
                        thousandSeparator
                        value={totals.studentCount}
                        />                           
                    
                    </Typography>
                    <Typography className={classes.boxContentNumber} variant="body2">
                        Students
                    </Typography>      
                </Grid>
                <Grid item>
                    <Typography className={classes.boxContentNumber} variant="h5">
                        {totals.teacherCount}
                    </Typography>
                    <Typography className={classes.boxContentNumber} variant="body2">
                        Teachers
                    </Typography>            
                </Grid>
                <Grid item>
                    <Typography className={classes.boxContentNumber} variant="h5">
                        {importReport.errorMessages && importReport.errorMessages.length ? (
                          importReport.errorMessages.length
                        ) : 0}
                    </Typography>
                    <Typography className={classes.boxContentNumber} variant="body2">
                        Errors
                    </Typography>            
                </Grid>                
 

            </Grid>
              


          </Container>



      </div>
    </Paper>    
      );
    };
    export {
        ImportDashboard
    };