import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosWithAuth } from 'utils/axiosWithAuth';
import { API_URL } from 'env';


const initialState = { 
  totalCount: 0, 
  districts: [],
  _districts: [],
  isLoading: false,
  error: ''
};

export const fetchDistrictList = async() => {   
  try {
    const url = `${API_URL}/api/admin/districts`
    const response = await axiosWithAuth().get(url);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const fetchDistrictListPaginated = createAsyncThunk(
  'districts/fetchDistrictListPaginated',
  async (providedParams, thunkAPI) => {
      const defaults = { page: 0, limit: 50 };
      const params = {...defaults, ...providedParams };
        const url = `${API_URL}/api/admin/districts/${params.page}`
        const response = await axiosWithAuth().post(url, params);        
        return response.data;
});

export const adminDistrictsSlice = createSlice({
  name: 'districts',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDistrictListPaginated.pending]: (state) => {
      state.loading = true;
      state.districts = [];
      state._districts = [];
    },
    [fetchDistrictListPaginated.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.totalCount = payload.totalCount;
      state.districts = payload.districts;
      state._districts = payload.districts;
    },
    [fetchDistrictListPaginated.rejected]: (state, { payload }) => {
    
      state.loading = false;
      state.error = payload;
    }, 
  },
})

export const adminDistrictsReducer = adminDistrictsSlice.reducer;


