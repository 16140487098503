import React, { createRef, useState, useEffect } from 'react';
import { CustomSnackbar } from '../../../components/Common/CustomSnackbar';
import { DynamicField,  } from 'components/Common/DynamicField';
import { InfoText,  } from 'components/Common/InfoText';
import { 
    Box,
    Button,
    Divider,
    Grid,
    Icon,
    Typography
  } from '@material-ui/core';

  import {    
    Card,
    CardHeader,
    CardContent,
    Avatar,
  } from '@material-ui/core';

  import { cleanUrlSlashes } from "../../../utils/cleanUrlSlashes";
  import { dynamicRegistrationFields, formFields, ltiProvider } from './ltiFormFields';
  import { LTIAAS_API_URL } from '../../../env';

const defaultFormValues = { ltiProvider: '' };
  const DYNREG_PROVIDERS = ['moodle', 'brightspace'];

  const LTIAvantagePlatform = ({ platform, handleSave, handleLink, handleCancel, handleDelete, isDynamicRegistration }) => {
    const form = createRef();

    const [registrationLink, updateRegistrationLink] = useState('https://');

    const [editMode, updateEditMode] = useState(false);
    const [freeze, updateFreeze] = useState(false);
    const [formValues, updateFormValues] = useState(defaultFormValues);
    const [dynamicFormFields, updateDynamicFormFields] = useState([]);
    

    const [snackbarState, updateSnackbarState] = useState({
        open: false,
        severity: 'error',
        message: ""
      });    


      useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [registrationLink]);


      const handleChange = (value) => {
        updateFormValues({...formValues, ...value});
        
        if(isDynamicRegistration && value.hasOwnProperty('ltiProvider')) {
            /** Dynamic registration behavior differs.   Moodle creates a platform record in ltiaas so we just need to link.
              Canvas doesn't auto register in ltiaas, so it is actually a save.   Since it has predictable attributes we can pre populate
            **/
            
              // we need to know more information for some providers, such as Canvas, so dynamically change the fields we are capturing
            updateDynamicFormFields(dynamicRegistrationFields[value['ltiProvider']]);


            const baseRegistrationLink  = `${LTIAAS_API_URL}/register`
            

            
            if (value['ltiProvider'] === 'canvas') {
                updateRegistrationLink(`${baseRegistrationLink}/canvas`);
            } else if (value['ltiProvider'] === 'moodle') {
                updateRegistrationLink(baseRegistrationLink);
            } else if (value['ltiProvider'] === 'schoology') {
                updateRegistrationLink('N/A - Schoology requires manually configuration');
            } else if (value['ltiProvider'] === 'brightspace') {
                updateRegistrationLink(baseRegistrationLink);
            }
        }

        if(isDynamicRegistration && formValues['ltiProvider'] === 'canvas' && value.hasOwnProperty('baseUrl')) {
            const baseUrl = value['baseUrl'];
            updateFormValues({
                ...formValues,
                ...value, 
                url: `https://canvas.instructure.com`,
                authenticationEndpoint: `${cleanUrlSlashes(baseUrl)}/api/lti/authorize_redirect`,
                accesstokenEndpoint: `${cleanUrlSlashes(baseUrl)}/api/lti/authorize_redirect`,
                method: 'JWK_SET',
                key: `${cleanUrlSlashes(baseUrl)}/api/lti/security/jwks`
            });            
        }

          if(isDynamicRegistration && formValues['ltiProvider'] === 'brightspace' && value.hasOwnProperty('baseUrl')) {
              const baseUrl = value['baseUrl'];
              updateFormValues({
                  ...formValues,
                  ...value,
                  url: baseUrl,
                  authenticationEndpoint: `${cleanUrlSlashes(baseUrl)}/d2l/lti/authenticate`,
                  accesstokenEndpoint: `https://api.brightspace.com/auth/token`,
                  method: 'JWK_SET',
                  key: `${cleanUrlSlashes(baseUrl)}/d2l/.well-known/jwks`
              });
          }


        if(isDynamicRegistration && formValues['ltiProvider'] === 'schoology') {
            const baseUrl = 'https://lti-service.svc.schoology.com/lti-service';
            updateFormValues({
                ...formValues,
                ...value, 
                url: `https://schoology.schoology.com`,
                authenticationEndpoint: `${baseUrl}/authorize-redirect`,
                accesstokenEndpoint: `${baseUrl}/access-token`,
                method: 'JWK_SET',
                key: `${baseUrl}/.well-known/jwks`
            });            
        }        
    }


    const handleClose = () => {
        updateSnackbarState({ ...snackbarState, open: false });
    };
    const resetFormValues = () => {
        const { authConfig, ...rest } = platform;
        const flattenedPlatform = {...rest, ...authConfig};
        const updatedValues = formFields.reduce((memo, item) => {
            return {...memo, [item.fieldName]: flattenedPlatform.hasOwnProperty(item.fieldName) ? flattenedPlatform[item.fieldName] : item.fieldValue};
        }, {});
        updateFormValues(updatedValues);
    }

    useEffect(() => {

        updateFreeze(false);
        if (platform && platform.error) { // don't reset values if a server error occurs            
            updateSnackbarState({...snackbarState, open: true, message: platform.error});     
        } else if (platform && !platform.id ) { // put into edit mode if this is a new record that doesn't exist in database
            updateEditMode(true);
            resetFormValues();
        } else if (!platform.error || !editMode || platform.id) { // set read only 
            resetFormValues();         
            updateEditMode(false)
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [platform]);

    
      const handleSubmit = () => {
        if(form.current.reportValidity()) {
            updateFreeze(true);
            const { key, method, ...rest } = formValues;
            const unflattenedPlatform = {...rest, authConfig: { key, method }};

            // if dynamic and moodle then link, otherwise save
            if (isDynamicRegistration && DYNREG_PROVIDERS.includes(formValues['ltiProvider'])) {
                const clientId = formValues['clientId'];
                handleLink(clientId);
            } else {
                handleSave(unflattenedPlatform);
            }
        }
      }


      const handleInternalCancel = () => {
        resetFormValues();
        updateEditMode(false);
      }      

    
    return (
        <Card>
            <CustomSnackbar handleClose={handleClose} snackbarState={snackbarState}/>
        <CardHeader               
            avatar={
              <Avatar aria-label="LTI Platform">
               <Icon>https</Icon>
              </Avatar>
            }
            title={formValues.platformName || 'LTI 1.3 Platform'}
            />
        <Divider />
      <CardContent>
        <form ref={form} onSubmit={e => e.preventDefault()}>
      <Grid container spacing={3}>
        {isDynamicRegistration ? (
            <>
            <Grid item xs={12} sm={12} md={6}>
                <Typography
                    color="textSecondary"
                    variant="subtitle2"
                >                
                Step 1:
            </Typography>
                Select your LMS provider to get the appropriate dynamic registration link, if applicable.
                <Box p={1} />

                <DynamicField             
                        editMode={true}
                        variant="outlined"
                        handleChange={handleChange}
                        {...ltiProvider}
                        fieldValue={formValues['ltiProvider'] || ''}
            />

            <Grid item>
                <InfoText 
                    fieldDisplay='Dynamic Registration Link' 
                    value={registrationLink || ''}
                ></InfoText>                
            </Grid>   

        </Grid>

        <Grid item xs={12} sm={12} md={6}>
        <Typography
                    color="textSecondary"
                    variant="subtitle2"
                >                
                Step 2:
            </Typography>

            Enter the data provided by the LMS to complete the set up process.
            <Box p={1} />
            {dynamicFormFields.map((myField, myIndex) => (
                <DynamicField
                    key={myIndex}
                    editMode={editMode}
                    disabled={freeze}
                    variant="outlined"
                    handleChange={handleChange}
                    {...myField}
                    fieldValue = {formValues[myField.fieldName]}
                />
                                    
            ))}
        </Grid>
                </>
        ): (
            <>
            {formFields.map((myField, myIndex) => (
                <Grid key={myIndex} item xs={12} sm={12} md={6}>
                                <DynamicField
                                  key={myIndex}
                                  editMode={editMode}
                                  disabled={freeze}
                                  variant="outlined"
                                  handleChange={handleChange}
                                  {...myField}
                                  fieldValue = {formValues[myField.fieldName]}
                                />
                                </Grid>
    
                ))}
                </>
        )}
        
            </Grid>
            </form>
        </CardContent>

        <CardContent> 
                        <Box display="flex" flexDirection="row" alignItems="space-between">
                            <Box flexGrow={1}>
                            {editMode && platform.id && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    disabled={freeze}                                    
                                    onClick={handleDelete}
                                >Delete</Button>
                            )}
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="button-list"   
                                disabled={freeze}            
                                onClick={() => {editMode ?  handleSubmit() : updateEditMode(true)}}
                            >{editMode ?  'Save' : 'Edit' }</Button>
                            <>
                            {editMode && (
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={platform.id ? handleInternalCancel : handleCancel}
                                disabled={freeze}
                                className="button-list"
                            >Cancel</Button>
                            )}     
                            </>
                        </Box>
                    </CardContent>
        </Card>        
    )   
}

export {
    LTIAvantagePlatform
}



