import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Button
 } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    textAlign: 'center'
  }
}));

const NoReport = ({handleClick}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        title="Found 0 Import Logs"
      />
      <CardContent>
        <Typography variant="h5" color="textSecondary" component="p" gutterBottom>
          No imported roster data
        </Typography>
      </CardContent>
      <CardContent>
        <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        size="large"
        className={classes.button}
        startIcon={<GetAppIcon />}
      >
        Import
      </Button>
      </CardContent>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Running a roster import only stages roster data.  You may run a test import in order to review results without impacting your CPM licenses.
        </Typography>  
      </CardContent>
    </Card>
  );
}

export {
    NoReport
};