import React from 'react';
import NumberFormat from 'react-number-format';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
  } from '@material-ui/core';

  const useStyles = makeStyles((theme) => ({
      boxContentNumber: {
        display: 'flex',
        justifyContent: 'center',
        
      }
  }));
  const SyncNumbers = ( ) => {
    const classes = useStyles();       
    const {  totals} = useSelector((state) => state.pushReport, shallowEqual);
    return (
        <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        
        >
            <Grid item>
                <Typography className={classes.boxContentNumber} variant="h5">
                    <NumberFormat                            
                        displayType={'text'}
                        thousandSeparator
                        value={totals.studentUsed}
                        />                        
                    
                </Typography>
                <Typography className={classes.boxContentNumber} variant="body2">
                    Students
                </Typography>      
            </Grid>

            <Grid item>
                <Typography className={classes.boxContentNumber} variant="h5">
                    <NumberFormat                            
                        displayType={'text'}
                        thousandSeparator
                        value={totals.teacherUsed}
                        />                
                </Typography>
                
                <Typography className={classes.boxContentNumber} variant="body2">
                    Teachers
                </Typography>            
            </Grid>


            <Grid item>
                <Typography className={classes.boxContentNumber} variant="h5">
                    <NumberFormat                            
                        displayType={'text'}
                        thousandSeparator
                        value={totals.teacherOverage + totals.studentOverage}
                        />                                     
                </Typography>
                <Typography className={classes.boxContentNumber} variant="body2">
                    Overages
                </Typography>            
            </Grid>

        </Grid>
    );
  };

  export { SyncNumbers };