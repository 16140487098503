import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
  Box
} from '@material-ui/core';

import { InfoBox } from 'components/Common/InfoBox';

import { updateProfileMode } from 'actions';


const Mode = () => {
  const { mode, isCertified } = useSelector((state) => state.district);
  
  const [currentMode, setCurrentMode] = useState('setup');

  useEffect(() => {
    setCurrentMode(mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const dispatch = useDispatch();
  

  const handleChange = (e) => {
    if(!isCertified && e.target.value === 'production') {
      toast.warning('Integration profile must be certified by CPM prior to setting to Live');
    } else {

      
      dispatch(updateProfileMode(e.target.value));
      setCurrentMode(e.target.value);
    }
  };


  const MyTooltip = () => {
    return (
      <div>              
              
              <Typography variant="subtitle2">
                &quot;Paused&quot; mode: Integration service will not run on automated basis.  Use this mode to configure 
                settings and manually run imports.
              </Typography>
              <Divider /> 
              <Typography variant="subtitle2">
                &quot;Live&quot;: Integration services will automatically run every 24 hours.  This includes a daily import from the roster server, and nightly syncronization of CPM accounts and eBook licenses.
              </Typography>
            </div>
    )
  }

  return (
   <InfoBox title='Integration Schedule' tooltip={MyTooltip()} >
        <Box grow={1} />
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="mode"
            name="mode"
            onChange={handleChange}
            value={currentMode || null}
          >
            <FormControlLabel 
              control={<Radio />}
              label="Paused"
              value="setup"
            />
            
            <FormControlLabel
              control={<Radio />}
              label="Live"
              value="production"
            />
          </RadioGroup>
        </FormControl>
        <Box grow={1} />

 
    </InfoBox>
  );
};

export default Mode;