import produce from 'immer';

import {
    FETCH_EXCLUDED_SUCCESS,
    FETCH_EXCLUDED_REQUEST,
    FETCH_EXCLUDED_FAILURE
} from '../actions/actionTypes';

const initialState = { excluded: [], isLoading: false};

const excludedReducer = produce((draft, action) => {
  switch (action.type) {   
    case FETCH_EXCLUDED_REQUEST:
      draft.isLoading = true;
      return draft;        
    case FETCH_EXCLUDED_FAILURE:
      draft.isLoading = false;
      return draft;  
    case FETCH_EXCLUDED_SUCCESS:
      draft.excluded = action.payload;      
      draft.isLoading = false;
      return draft;
    default:
      return draft;
  }
}, initialState);

export default excludedReducer;
