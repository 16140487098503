import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    makeStyles,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    Typography,
    Button,
    Grid
  } from '@material-ui/core';

  import {    
    Card,
    CardHeader,
    CardContent,    
  } from '@material-ui/core';


import DoneIcon from '@material-ui/icons/Done';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
      display: 'block'
    },
    navigationButton: {
      marginBottom: theme.spacing(3)
    },       
    paper: {    
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      },
      paperContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
      },
      paperHeading: {
        alignItems: 'center',
        backgroundColor: theme.palette.info.light,
        display: 'flex',
        margin: 0,
        padding: theme.spacing(2)
      },
      paperHeadingTitle: {
        marginRight: theme.spacing(0.5)
      },
      avatar: {
        //   color: theme.palette.secondary.light,
         backgroundColor: theme.palette.secondary.main
      }

  }));

  const integrationInit = [
    {
      title: "API Client",
      detail:  "API credentials have been provisioned and have been validated as correct in the CPM Integration platform.  Required data points are shared (organizations, teachers, students, applicable courses, schedules/classes)",
      done: false
    },
    {
      title: "Integration Mapping",
      detail:  "Schools, Courses and Course mappings have been completed.",
      done: false
    },
    {
      title: "CPM License Mappings",
      detail:  "CPM eBook licenses are mapped to corresponding courses",
      done: false
    },
    {
      title: "Test Import",
      detail:  "Run a test import to verify that data is coming in as anticipated.",
      done: false
    },
    {
      title: "CPM Data Readiness: Teacher data preparation",
      detail:  "Verify that teacher accounts are up to date and follow convention of using the official school email address for the username. Submit a request to support@cpm.org if usernames need to be updated.",
      done: false
    },
    {
      title: "CPM Data Readiness: Student data preparation",
      detail:  "Verify that student accounts are up to date and follow convention of using the official school email address for the username. Often the easies approach is to delete existing records.  Submit a request to support@cpm.org to have records bulk deleted.",
      done: false
    },
    {
      title: "Authorize rostering process to use License Pool",
      detail:  "The License Pool tracks the distribution of CPM teacher and student licenses and must be switched from manually administered mode to automated mode.",
      done: false
    },
    {
      title: "Enable Rostering",
      detail:  'Set Integration Schedule to "Live" ',
      done: false
    },         
  ];

const RosterCheckItem = ({item}) => {
  return (
  <ListItem>
  <ListItemAvatar onClick={()=> {item.done = !item.done}}>
      {item.done ? (
        <DoneIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />        
      )}
  </ListItemAvatar>
  <ListItemText primary={item.title} secondary={item.detail} />
</ListItem>
  )  
}

const RosterChecklist = ({hideBackButton}) => {
    const classes = useStyles();
    const [integration]  = useState(integrationInit);
    const history = useHistory();
    const handleGoToDashboard = () => {
      history.push('/dashboard');
    };    
    return (
      <>      
      <div className={classes.root}>
      {!hideBackButton && (


      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Button
        aria-label="Back"
        className={classes.navigationButton}
        color="default"
        onClick={handleGoToDashboard}
        startIcon={<ArrowBackIosIcon />}
        variant="contained"
      >
        Back to Dashboard
      </Button>          
        </Grid>      
      </Grid>
      
      )}    
      <Typography variant="h5">
        Checklist
      </Typography>
      <Card>
          <CardHeader 
                className={classes.paperHeading}
              avatar={
                <Avatar aria-label="Integration Settings" className={classes.avatar}>
                 <SyncAltOutlinedIcon />
                </Avatar>
              }
              title='Integration Settings'
              subheader='Configure Roster API Provider to share required data and define how CPM should process.'
              />

        <CardContent>
          <List className={classes.root} dense={true}>
            {integrationInit.map((item, index) =>(
              <React.Fragment key={`int-${index}-key`}>
                <RosterCheckItem item={item} />
                {(index +1) < integration.length && (
                    <Divider />
                 )}
              </React.Fragment>
            ))}        
          </List>
        </CardContent>
    </Card>



    </div>
    </>
    )
}

export {
    RosterChecklist
}