import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  colors,
  Paper,
  Typography,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';


const useStyles = makeStyles((theme) => ({
  root: {
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  },
  paper: {    
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.info.light,
    padding: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.info.main,
    color: 'white',
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingTitle: {
    marginRight: theme.spacing(2)
  },
  spinner: {
    minHeight: '50vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },  
  viewButton: {    
    color:theme.palette.primary.dark
  },
  link: {
    textDecoration: 'none',
    color: '#fff'
  },  
}));


export const formatNumber = inputNumber => {
  if(!inputNumber) {
    return 0;
  }
  let formattedNumber=(Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  let splitArray=formattedNumber.split('.');
  if(splitArray.length>1){
    formattedNumber=splitArray[0];
  }
  return(formattedNumber);
};


const LicenseSyncReport = ( {showLink} ) => {
  const classes = useStyles();   
  const { data: pushReport, isLoading: pushReportLoading} = useSelector((state) => state.pushReport, shallowEqual);
  
  
  const district = useSelector((state) => state.district, shallowEqual);


  return (
    <>
    <Paper className={classes.paper} elevation={5} square>
      <div className={classes.paperHeading}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >        
          <Typography
            className={classes.paperHeadingTitle}
            component="h3"
            variant="body1"
          >
            Roster Status - {district && district.scheduleStatus}
          </Typography>
          {showLink && (
            <Link className={classes.link} to={`/sync/${pushReport.data._id}`}>
              <LaunchIcon />            
            </Link>
          )}
        </Grid>
      </div>
      <div className={classes.paperContent}>


      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        style={{ minHeight: '20vh'}}
      >  

            
            {pushReport && !pushReportLoading && !pushReport.success && pushReport._id && (
              <>
              <div>
                <h3>Last import failed</h3>
                <Typography variant="caption">{moment(pushReport.date).local().format('MMM DD, YYYY HH:mm')}</Typography>
              </div>
              </>
            )}          
            {pushReport && !pushReportLoading && !pushReport._id && (
              <h1>No Data</h1>
            )}

    

            </Grid>
    </div>
    </Paper>

      
    </>  
  );
};
export {
  LicenseSyncReport
};

