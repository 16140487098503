import { batch } from "react-redux";
import {
    UNSET_ADMIN_MODE,
    LOGOUT_USER,
    SET_DISTRICT_ID,
    CLEAR_IMPORTREPORT,
    AUTHENTICATE_NAV_ADMIN,
    AUTHENTICATE_USER_SUCCESS
} from "./actionTypes";
import { SSO_REDIRECT } from '../env';
import jwt from 'jsonwebtoken';

export const getAuthPayload = (realmAccess) => {
  const isAdmin = realmAccess.roles.includes('CPM_ADMIN') || false;
  const district = realmAccess.districts.find(x => x.role === 'CUSTOMER_ADMINISTRATOR');
  const isDistrictAdmin = (district && Boolean(district.id)) || false;
  const unauthorizedUser = isAdmin || isDistrictAdmin ? false : true;
  return { isDistrictAdmin, unauthorizedUser, isAdmin };
}

export const makeStateFromToken = (aToken) => {
  console.log('In makeStateFromToken', {aToken});
  const tokenData = jwt.decode(aToken);
  const calculatedFields = getAuthPayload(tokenData.realm_access);
  return {...tokenData, ...calculatedFields, isAuthenticated: true};
};

export const unsetAdminMode = () => (dispatch) => {
    sessionStorage.removeItem('X-Impersonate');
    dispatch({ type: UNSET_ADMIN_MODE });
  };

  export const setImpersonate = (impersonateId) => (dispatch) => {
    sessionStorage.setItem('X-Impersonate', impersonateId);
    batch(() => {
      dispatch({ type: SET_DISTRICT_ID, payload: impersonateId });
      dispatch({ type: CLEAR_IMPORTREPORT });
    })
  };


  export const logoutUser = () => (dispatch) => {    
    dispatch({ type: LOGOUT_USER });
    const newLocation = SSO_REDIRECT && SSO_REDIRECT.includes('test') ? 'https://my-test.cpm.org' : 'https://my.cpm.org'
    window.location = newLocation;
    // document.cookie ='cpm-sso-token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=cpm.org; pathsa=/;';
  };

  export const validateUser = () => async (dispatch, getState) => {      
    const { isAdmin, isDistrictAdmin, realm_access } = getState().auth;
    if (isAdmin) {
      const impersonateId = sessionStorage.getItem('X-Impersonate');      
      if (typeof impersonateId !== 'string') {
        dispatch({ type: AUTHENTICATE_NAV_ADMIN, payload: true });
      } else {
        dispatch({ type: SET_DISTRICT_ID, payload: sessionStorage.getItem('X-Impersonate') });
      }    
    } else if(isDistrictAdmin) {
      const district = realm_access.districts.find(x => x.role === 'CUSTOMER_ADMINISTRATOR');
      dispatch({ type: SET_DISTRICT_ID, payload: district.id });
    }



  };

  export const setUser = (token) => (dispatch) => {
    const tokenState = makeStateFromToken(token);
    dispatch({ type: AUTHENTICATE_USER_SUCCESS, payload: tokenState });
    dispatch(validateUser());
  };
  
  