import React from 'react';
import { 
  Grid
 } from '@material-ui/core';

import Mode from 'components/Mode';
import ModeOverrides from 'components/ModeOverrides';
import { RosterTeachers } from '../../components/RosterTeachers';

import { FreezeRoster } from 'components/FreezeRoster';
const ScheduleState = () => {
  return (
      <Grid container spacing={3}>      
          <Grid item md={6} sm={12} xs={12}>
            <Mode />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <RosterTeachers />
          </Grid>

          <Grid item sm={6} xs={12}>
            <ModeOverrides />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FreezeRoster />
          </Grid>      

      </Grid>
  );
};

export {
  ScheduleState
};
