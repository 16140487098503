import { toast } from 'react-toastify';

import{ axiosWithAuth } from 'utils/axiosWithAuth';

import { API_URL } from 'env';

import {
  ASSIGN_LICENSE,
  UNASSIGN_LICENSE,
  UPDATE_LICENSE,

  TOGGLE_CERTIFICATION_REQUEST,
  TOGGLE_CERTIFICATION_SUCCESS,
  TOGGLE_CERTIFICATION_FAILURE,

  AUTHENTICATE_USER_SUCCESS,
  COMPLETE_ONBOARDING,
  
  FETCH_DISTRICTPROFILE_REQUEST,
  FETCH_DISTRICTPROFILE_SUCCESS,
  FETCH_DISTRICTPROFILE_FAILURE,

  SET_DISTRICT_ID,

  FETCH_LICENSEPOOLS_FAILURE,
  FETCH_LICENSEPOOLS_REQUEST,
  FETCH_LICENSEPOOLS_SUCCESS,

  RESET_SCHEDULE_REQUEST,
  RESET_SCHEDULE_SUCCESS,
  RESET_SCHEDULE_FAILURE,

  FETCH_COURSES_REQUEST,
  FETCH_COURSES_FAILURE,
  FETCH_COURSES_SUCCESS,
  REMOVE_INVALID_COURSE,
  FETCH_LICENSES_FAILURE,
  FETCH_LICENSES_REQUEST,
  FETCH_LICENSES_SUCCESS,
  FETCH_SCHOOLS_FAILURE,
  FETCH_SCHOOLS_REQUEST,
  FETCH_SCHOOLS_SUCCESS,
  REMOVE_INVALID_SCHOOL,
  
  FETCH_TEACHERS_FAILURE,
  FETCH_TEACHERS_REQUEST,
  FETCH_TEACHERS_SUCCESS,
  FETCH_TEACHERS_RESET,

  RESET_COURSES_FILTER,
  RESET_SCHOOLS_FILTER,

  
  REVIEW_CHANGE_GROUPING,
  REVIEW_ONLOAD,
  SET_COURSES_FILTER,
  SET_SCHOOLS_FILTER,

  TOGGLE_ROSTER_COURSE,
  TOGGLE_ROSTER_SCHOOL,
  
  UPDATE_DISTRICT_PROFILE_REQUEST,
  UPDATE_DISTRICT_PROFILE_SUCCESS,
  UPDATE_DISTRICT_PROFILE_FAILURE,

  UPDATE_PROFILE_MODE,
  UPDATE_TERM_OVERRIDE,
  UPDATE_PROFILE_ROSTER_TEACHERS,


  VERIFY_ROSTER_CREDENTIALS_SUCCESS,
  VERIFY_ROSTER_CREDENTIALS_REQUEST,
  VERIFY_ROSTER_CREDENTIALS_FAILURE,

  INIT_ADMIN,
  FETCH_ELSPROFILE_REQUEST,
  FETCH_ELSPROFILE_SUCCESS, 
  FETCH_ELSPROFILE_FAILURE
} from './actionTypes';

// Profile

export const fetchElsProfile = () => async (dispatch) => {
  dispatch({ type: FETCH_ELSPROFILE_REQUEST });
try {  
    const response = await axiosWithAuth().get(`${API_URL}/api/v1/district/profile/els`);
    dispatch({ type: FETCH_ELSPROFILE_SUCCESS, payload: response.data });
  } catch (e) {
    dispatch({ type: FETCH_ELSPROFILE_FAILURE, payload: e.message });
  }
};




export const saveRosterCredentials = (profile) => async (dispatch) => {
  dispatch({
    type: UPDATE_DISTRICT_PROFILE_REQUEST
  });  
  try {
    // First check out if credentials are correct
    // 403 will be returned if not    
    try {
      const response = await axiosWithAuth().post(
        `${API_URL}/api/v1/district/credentials`,
        profile
      );

      dispatch({
        type: UPDATE_DISTRICT_PROFILE_SUCCESS,
        payload: response.data
      });
      dispatch(testRosterCredentials(response.data));
    } catch (err) {
      throw err;
    }
  } catch (err) {

    dispatch({
      type: UPDATE_DISTRICT_PROFILE_FAILURE,
      payload: 'Error updating Profile'
    });
  }
};
// Use for testing without saving
export const testRosterCredentials = (profile = {}) => async (dispatch) => {
    // First check out if credentials are correct
    // 403 will be returned if not
    
    dispatch({
      type: VERIFY_ROSTER_CREDENTIALS_REQUEST
    });
    try {
      await axiosWithAuth().post(
        `${API_URL}/api/v1/district/credentials/test`,
        profile
      );
      dispatch({
        type: VERIFY_ROSTER_CREDENTIALS_SUCCESS
      });      
  } catch (err) {
    
    // toast.warning(`${err.response.data}`);
    dispatch({
      type: VERIFY_ROSTER_CREDENTIALS_FAILURE,
      payload: { error: err.message, profile }
    });
  }

};

export const toggleCertification = ({isCertified}) => async (dispatch) => {
  
    dispatch({
      type: TOGGLE_CERTIFICATION_REQUEST,
    });
    try {
      const response = await axiosWithAuth().patch(
        `${API_URL}/api/v1/district/certify`,
        { isCertified }
      );
        console.log(response.data)
      dispatch({
        type: TOGGLE_CERTIFICATION_SUCCESS,
        payload: isCertified
      });  
  } catch (err) {
    const message = err && err.response && err.response.data ? err.response.data : err.message;
    dispatch({
      type: TOGGLE_CERTIFICATION_FAILURE,
      payload: message
    });
  }
};


export const completeOnboarding = () => async (dispatch, getState) => {
  try {
    await axiosWithAuth().put(
      `${API_URL}/api/v1/district/profile`,
      {
        onboardingComplete: true
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const completeOnboardingLocal = () => (dispatch) => {
  dispatch({ type: COMPLETE_ONBOARDING });
};

export const resetSchedule = () => async (dispatch) => {
  dispatch({ type: RESET_SCHEDULE_REQUEST });
try {  
    const response = await axiosWithAuth().patch(`${API_URL}/api/v1/district/roster/schedule/reset`);
    dispatch({ type: RESET_SCHEDULE_SUCCESS, payload: response.data });
  } catch (e) {
    toast.error(`Unable to reset schedule.  Please report the following: ${e.message}`);
    dispatch({ type: RESET_SCHEDULE_FAILURE, payload: e.message });
  }
};

// Schools
export const fetchSchools = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SCHOOLS_REQUEST });
    const response = await axiosWithAuth().get(
      `${API_URL}/api/v1/district/schools`
    );
    dispatch({ type: FETCH_SCHOOLS_SUCCESS, payload: response.data });
  } catch (e) {
    dispatch({ type: FETCH_SCHOOLS_FAILURE });
  }
};

export const toggleRosterSchool = (school) => async (dispatch) => {
  try {
    const method = school.rostered ? 'delete' : 'post';
    const urlPath = school.rostered ? `/${school.schoolId}` : '';
    const url = `${API_URL}/api/v1/district/school${urlPath}`;
    await axiosWithAuth().request({ method, url, data: school });
    dispatch({ type: TOGGLE_ROSTER_SCHOOL, method, payload: school });
  } catch (e) {
    console.log(e);
  }
};

export const removeInvalidSchool = (school) => async (dispatch) => {
  try {
    const url = `${API_URL}/api/v1/district/school/${school.schoolId}`;
    await axiosWithAuth().delete( url );
    dispatch({ type: REMOVE_INVALID_SCHOOL, payload: school });
  } catch (e) {
    console.log(e);
  }
};

export const setSchoolsFilter = (filter) => (dispatch) => {
  dispatch({ type: SET_SCHOOLS_FILTER, payload: filter });
};

export const resetSchoolsFilter = () => (dispatch) => {
  dispatch({ type: RESET_SCHOOLS_FILTER });
};

// Courses
export const fetchCourses = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_COURSES_REQUEST });
    const response = await axiosWithAuth().get(
      `${API_URL}/api/v1/district/courses`
    );
    dispatch({ type: FETCH_COURSES_SUCCESS, payload: response.data });
  } catch (e) {
    dispatch({ type: FETCH_COURSES_FAILURE });
  }
};

export const toggleRosterCourse = (course) => async (dispatch) => {
  try {
    const method = course.rostered ? 'delete' : 'post';
    const urlPath = course.rostered ? `/${course.courseId}` : '';
    const url = `${API_URL}/api/v1/district/course${urlPath}`;
    await axiosWithAuth().request({ method, url, data: course });
    dispatch({ type: TOGGLE_ROSTER_COURSE, method, payload: course });
  } catch (e) {
    console.log(e);
  }
};

export const removeInvalidCourse = (course) => async (dispatch) => {
  try {
    const url = `${API_URL}/api/v1/district/course/${course.courseId}`;
    await axiosWithAuth().delete( url );
    dispatch({ type: REMOVE_INVALID_COURSE, payload: course });
  } catch (e) {
    console.log(e);
  }
};

export const setCoursesFilter = (filter) => (dispatch) => {
  dispatch({ type: SET_COURSES_FILTER, payload: filter });
};

export const resetCoursesFilter = () => (dispatch) => {
  dispatch({ type: RESET_COURSES_FILTER });
};

// Licenses
export const fetchLicenses = () => async (dispatch) => {
  try {

    dispatch({ type: FETCH_LICENSES_REQUEST });
    const response = await axiosWithAuth().get(
      `${API_URL}/api/v1/district/licenses/profile`
    );
    dispatch({ type: FETCH_LICENSES_SUCCESS, payload: response.data });
  } catch (e) {
    dispatch({ type: FETCH_LICENSES_FAILURE });
  }
};

export const assignLicense = (course) => async (dispatch ) => {
  try {
    const { courseId, eBookId } = course;

    const url = `${API_URL}/api/v1/district/license/courseId/${courseId}/eBookId/${eBookId}`;

    const response = await axiosWithAuth().post(url);
    const { _id } = response.data;
    
    dispatch({ type: ASSIGN_LICENSE, payload: {_id, ...course} });
    

  } catch (e) {
    console.log(e);
  }
};

export const updateLicense = (course) => async (dispatch ) => {
  try {
    const { courseId, eBookId, _id } = course;

    const url = `${API_URL}/api/v1/district/license/licenseId/${_id}/courseId/${courseId}/eBookId/${eBookId}`;    
    await axiosWithAuth().patch(url);
    
    dispatch({ type: UPDATE_LICENSE, payload: course });
  } catch (e) {
    console.log(e);
  }
};

export const unassignLicense = (id) => async (dispatch ) => {
  try {
    const url = `${API_URL}/api/v1/district/license/${id}`;

    await axiosWithAuth().delete( url );

    dispatch({ type: UNASSIGN_LICENSE, payload: id });
  } catch (e) {
    console.log(e);
  }
};



export const fetchLicensePools = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_LICENSEPOOLS_REQUEST });
    const response = await axiosWithAuth().get(
      `${API_URL}/api/v1/district/licensepools`
    );
    dispatch({
      type: FETCH_LICENSEPOOLS_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    dispatch({ type: FETCH_LICENSEPOOLS_FAILURE });
  }
};

export const fetchTeachers = (schoolId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_TEACHERS_REQUEST });
    const teachers = await axiosWithAuth().get(
      `${API_URL}/api/v1/district/teachers/school/${schoolId}`
    );
    dispatch({
      type: FETCH_TEACHERS_SUCCESS,
      payload: teachers.data
    });
  } catch (e) {
    dispatch({ type: FETCH_TEACHERS_FAILURE });
  }
};

export const resetTeachers = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_TEACHERS_RESET
    });
  } catch (e) {
    console.log(`unknown error ${e.message}`);
  }
};



// Settings
export const updateProfileMode = (mode) => async (dispatch, getState) => {
  try {

    await axiosWithAuth().put(
      `${API_URL}/api/v1/district/profile`,
      {
        mode
      }
    );
    dispatch({ type: UPDATE_PROFILE_MODE, payload: mode });
  } catch (e) {
    toast.warn(
      'There was a problem saving your mode settings. Please try again.'
    );
  }
};

export const updateLicensePool = (pool) => async (dispatch) => {
  try {

    const response = await axiosWithAuth().patch(
      `${API_URL}/api/v1/district/profile`,
      {
        ...pool
      }
    );
    dispatch({
      type: UPDATE_DISTRICT_PROFILE_SUCCESS,
      payload: response.data
    });
    fetchLicenses();    
  } catch (e) {
    dispatch({
      type: UPDATE_DISTRICT_PROFILE_FAILURE,
      payload: e.response.data
    });    
    toast.warn(
      'There was a problem saving your roster pool settings. Please try again.'
    );
  }
};

export const activateLicensePool = (status) => async ( dispatch ) => {
  try {

    await axiosWithAuth().patch(
      `${API_URL}/api/v1/district/licensepool/rostered/${status}`,      
    );
    dispatch(fetchLicensePools());
  } catch (e) {
    toast.warn(
      'There was a problem saving your roster pool settings. Please try again.'
    );
  }
};


export const updateTermOverride = (overrideTermDate) => async (
  dispatch,
) => {
  try {

    await axiosWithAuth().put(
      `${API_URL}/api/v1/district/profile`,
      {
        overrideTermDate
      }
    );
    dispatch({ type: UPDATE_TERM_OVERRIDE, payload: overrideTermDate });    
  } catch (e) {
    toast.warn(
      'There was a problem saving your mode settings. Please try again.'
    );
  }
};

export const freezeTerm = async (endDate) =>  
{  
    return await axiosWithAuth().patch(
      `${API_URL}/api/v1/district/roster/freeze`,
      {
        endDate
      });
};


export const updateProfileRosterTeachers = (rosterTeachers) => async (
  dispatch
) => {
  try {
    await axiosWithAuth().put(
      `${API_URL}/api/v1/district/profile`,
      {
        rosterTeachers
      }
    );
    dispatch({ type: UPDATE_PROFILE_ROSTER_TEACHERS, payload: rosterTeachers });    
  } catch (e) {
    toast.warn(
      'There was a problem saving your roster teachers settings. Please try again.'
    );
  }
};

// Login
export const loginAdmin = (payload) => async (dispatch) => {

  try {
    dispatch({ type: AUTHENTICATE_USER_SUCCESS, payload: {} });
    dispatch({ type: INIT_ADMIN, payload });
  } catch (err) {

  }
}




export const getDistrictProfile = () => async (dispatch, getState) => {
  const district = getState().district;
  if (!district.districtId) {  
    return false;
  }
  dispatch({type: FETCH_DISTRICTPROFILE_REQUEST});
  try {
    const districtProfile = await axiosWithAuth().get(
      `${API_URL}/api/v1/district/profile`
    );
    dispatch({
      type: FETCH_DISTRICTPROFILE_SUCCESS,
      payload: districtProfile.data
    });    
  } catch (err) {
    dispatch({
      type: FETCH_DISTRICTPROFILE_FAILURE
    });
  }
}

export const setDistrictId = (districtId) => (dispatch) => {
  dispatch({ type: SET_DISTRICT_ID, payload: districtId });
};

// Unused Review
export const fetchReviewResource = (resource) => (dispatch) => {
  dispatch({ type: REVIEW_ONLOAD, payload: resource });
};

export const reviewChangeGrouping = (name) => (dispatch) => {
  dispatch({ type: REVIEW_CHANGE_GROUPING, payload: name });
};





export * from './authActions';
export * from './reportActions';
export * from './eWorkspaceActions';
export * from './stickyLicenseActions';
export * from './excludedActions';
export * from './ssoActions';