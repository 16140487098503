import { axiosWithAuth, axiosFile } from './axiosWithAuth';
import { API_URL } from 'env';

export const getMockELS = async() => {
    try {
      const url = `${API_URL}/api/v1/district/reports/push/mock`
      const response = await axiosWithAuth().get(url);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }

  export const getFilterableFields = async() => {
    try {
      const url = `${API_URL}/api/v1/district/exclusions/filterable`
      const response = await axiosWithAuth().get(url);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }  


  export const getCourseClasses = async(courseId) => {
    try {
      const url = `${API_URL}/api/v1/roster/course/${courseId}/data`
      const response = await axiosWithAuth().get(url);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }

  export const uploadOneRosterZip = async ( file )  => {

    const uri = `${API_URL}/api/v1/onerostercsv/upload`;
    try {        
        const { data } = await axiosFile().post(uri, file);
        return data;
    } catch (err) {
      throw new Error(err.response.data); 
    }
  }


  export const convertCourseMappings = async ( slurpData )  => {
    const convertAction = slurpData ? 'preload' : 'nopreload';
    const uri = `${API_URL}/api/v1/district/profile/convert/${convertAction}`;
    try {        
        const { data } = await axiosWithAuth().patch(uri);
        return data;
    } catch (err) {
      throw new Error(err.response.data); 
    }
  }
  

  export const getCsvUploadLogs = async(jobId) => {
    try {
      const url = `${API_URL}/api/v1/onerostercsv/logs/${jobId}`
      const response = await axiosWithAuth().get(url);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data); 
    }
  }
  export const getCsvUploadResults = async(jobId) => {
    try {
      const url = `${API_URL}/api/v1/onerostercsv/results/${jobId}`
      const response = await axiosWithAuth().get(url);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data); 
    }
  }  

  export const preloadResults = async () => {
    try {
      const url = `${API_URL}/api/v1/onerostercsv/slurp`
      const response = await axiosWithAuth().put(url);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data); 
    }
  }  
  
  
  export const launchImport = async () => {
    try {    
      const response = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/launch/import`
      );
      return response.data;
      
    } catch (e) {
      return e;
    }
  };

  export const launchImportUsePreload = async () => {
    try {    
      const response = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/launch/import/preload`
      );
      return response.data;
      
    } catch (e) {
      return e;
    }
  };
  
  export const launchPush = async () => {
    try {    
      const response = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/launch/push`
      );
      return response.data;
      
    } catch (e) {
      return e;
    }
  };
  