import produce from 'immer';

import {
  FETCH_TEACHERS_FAILURE,
  FETCH_TEACHERS_REQUEST,
  FETCH_TEACHERS_SUCCESS,
  FETCH_TEACHERS_RESET
} from '../actions/actionTypes';

const initialState = { data: [], isLoading: false };

const teachersReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_TEACHERS_FAILURE:
      draft.isLoading = false;
      return draft;
    case FETCH_TEACHERS_REQUEST:
      draft.isLoading = true;
      return draft;
    case FETCH_TEACHERS_RESET:      
      return initialState;      
    case FETCH_TEACHERS_SUCCESS:
      draft.data = action.payload;
      draft.isLoading = false;
      return draft;
    default:
      return draft;
  }
}, initialState);

export default teachersReducer;
