import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography
} from '@material-ui/core';

import {
  completeOnboardingLocal,
  updateProfileMode,
  updateProfileRosterTeachers
} from 'actions';
import { RosterChecklist } from '../RosterChecklist';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main
  },
  button: {
    margin: theme.spacing(3, 1.5)
  },
  paper: {
    marginBottom: theme.spacing(3)
  },
  paperButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.info.main,
    color: 'white',
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingTitle: {
    marginRight: theme.spacing(0.5)
  },
  paperInput: {
    padding: theme.spacing(3)
  },
  paperInputFooter: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
    maxWidth: '460px'
  },
  paperInputHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  paperInputText: {
    marginTop: '48px'
  },
  submit: {
    marginTop: theme.spacing(2)
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(1)
  }
}));

const OnboardingComplete = () => {
  const onboardingComplete = useSelector(
    (state) => state.district.onboardingComplete
  );
  const rosterTeachers = useSelector((state) => state.district.rosterTeachers);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  // TODO: Refactor this and updateProfileMode
  const handleClickTest = () => {
    if (!onboardingComplete) {
      dispatch(completeOnboardingLocal());
    }
    dispatch(updateProfileMode('setup'));
    history.push('/dashboard');
  };

  const handleClickAutomate = () => {
    if (!onboardingComplete) {
      dispatch(completeOnboardingLocal());
    }
    dispatch(updateProfileMode('production'));
    history.push('/dashboard');
  };

  const handleChange = () => {    
    dispatch(updateProfileRosterTeachers(!rosterTeachers));
  };

  return (
    <>
    <Paper className={classes.paper} elevation={2} square>
      <div className={classes.paperHeading}>
        <Typography
          className={classes.paperHeadingTitle}
          component="h1"
          variant="body1"
        >
          Roster Setup Complete
        </Typography>
      </div>
      <div className={classes.paperInput}>
        <div className={classes.paperInputHeader}>
          <div>
            <h2>Congratulations!</h2>
          </div>
          <div className={classes.paperInputText}>
            <Typography variant="body1">
              Review the checklist below to see if you are ready to turn on automated rostering
            </Typography>
          </div>
          <div>
            <Button
              aria-label="No"
              className={classes.button}
              onClick={handleClickTest}
              variant="contained"
              color="primary"
            >
              No
            </Button>
            <Button
              aria-label="Yes"
              className={classes.button}
              onClick={handleClickAutomate}
              variant="contained"
            >
              Yes*
            </Button>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rosterTeachers}
                  onChange={handleChange}
                  name="rosterTeachers"
                  color="primary"
                />
              }
              label="Enable Roster Teachers"
            />
          </div>
          <div className={classes.paperInputFooter}>
            <Typography color="primary" variant="caption">
              *Automated Rostering will replace existing CPM licenses with the latest roster information.
            </Typography>
          </div>
        </div>
      </div>
    </Paper>

    <RosterChecklist hideBackButton={true} />
    </>
  );
};

export default OnboardingComplete;
