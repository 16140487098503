import React from 'react';
import {  Switch, Route, useHistory, useRouteMatch, Redirect } from 'react-router-dom';
import { SAMLEdit, SAMLSettings, SAMLImport, SAMLUpload, SAMLWizard  } from './SAML';

import {
  Button,
  Box,
  Divider,
} from '@material-ui/core';


const SAMLSSO = ( { ssoProvider }) => {
    const {
      // path,
      url
    } = useRouteMatch();  
    
    const history = useHistory();
    
    const handleBack = () => {
      return history.replace('./');
    }

    const editSSAML = () => {
      return history.replace('/sso/SAML/edit');
    }    
   
      const MyContainer = ({ children }) => {
        return (
        <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <h3>SAML SSO</h3>
            {ssoProvider && ssoProvider.settings && ssoProvider.settings.id && (
            <Button
                aria-label="Save"
                color="secondary"
                size="small"                            
                variant="outlined"
                onClick={() => editSSAML()}
              >
              Edit
            </Button>   
            )}
        </Box>
        <Divider />
        <Box mt={6} />
          {children}
        </>
        )
      }

      const SAMLLanding = () => {
        return (
          <>
          {ssoProvider && ssoProvider.isActive ? (
            <SAMLSettings ssoProvider={ssoProvider}/>
          ) : (
            <SAMLWizard />
          )}
          </>
        )
      }

    return (
      
      <MyContainer>
          <Switch>
            <Redirect from="/:url*(/+)" to={url.slice(0, -1)} />
            <Route exact path={url} component={() => <SAMLLanding />} />
            <Route exact path={`${url}/add`} component={() => <SAMLEdit editMode={true} ssoProvider={ssoProvider} />} />
            <Route exact path={`${url}/edit`} component={() => <SAMLEdit editMode={true} ssoProvider={ssoProvider} />} />
            <Route exact path={`${url}/import`} component={() => <SAMLImport handleCancel={handleBack} />} />
            <Route exact path={`${url}/upload`} component={() => <SAMLUpload handleCancel={handleBack} />} />
          </Switch>
    </MyContainer>
    )
}

export {
    SAMLSSO
};


