import React from 'react';
import { DynamicText, TextEdit, EditDateField, EditCurrencyField, SelectField, SelectObjectField, TextAreaEdit, SwitchField } from './DynamicFields';
import { isValidUrl } from './Util';
const DynamicField = (props) => {
    const { fieldType='text', editMode } = props;
    return (
        <>
        {editMode ? (
            <>
            {(() => {
                switch (fieldType) {
                    case 'date':
                        return (        
                            <EditDateField {...props} />
                        )
                    case 'currency':
                        return (        
                            <EditCurrencyField {...props} />
                        )
                    case 'select':
                        return (        
                            <SelectField {...props} />
                        ) 
                    case 'selectObject':
                        return (        
                            <SelectObjectField {...props} />
                        )
                    case 'textArea':
                        return (
                            <TextAreaEdit {...props} />
                        ) 
                    case 'email':
                        const emailProps = {...props, validationType: 'email'};
                        return (        
                            <TextEdit {...emailProps} />
                        )
                    case 'number':
                        const numberProps = {...props, validationType: 'number'};
                        return (        
                            <TextEdit {...numberProps} />
                        )
                    case 'url':
                        const urlProps = {...props, validationType: 'url', error: isValidUrl(props.fieldValue)};
                        return (        
                            <TextEdit {...urlProps} />
                        )
                    case 'password':
                        const passwordProps = {...props, validationType: 'password', autocomplete: 'current-password'};
                        return (        
                            <TextEdit {...passwordProps} />
                        )                                                
                    case 'switch':                        
                        return (        
                            <SwitchField {...props} />
                        )                        
                    default:
                        return (        
                            <TextEdit {...props} />
                        )
                }
            })()}
            </>
        ) : ( 
            <DynamicText
                {...props} />
        )}
        </>
    )
}

export { DynamicField };