import React from 'react';
import {
    TextField,
} from '@material-ui/core';

const TextEdit = (props) => {
    const { fieldDisplay, fieldName, fieldValue, disabled=false, autocomplete='off', required=true, handleChange, validationType='text', error=false, helperText='' } = props;
    const handleCustomChange = (e) => {
        handleChange({ [e.target.name]: e.target.value });
    }
    return (
            <TextField            
                fullWidth
                disabled={disabled}                
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={fieldDisplay}
                margin="dense"
                name={fieldName}
                onChange={handleCustomChange}
                required={required}
                type={validationType}
                error={error}
                helperText={helperText}
                value={fieldValue || ''}
                autoComplete={autocomplete}
                />
    )
}


export { TextEdit };