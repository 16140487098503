import React from 'react';
import { 
    Typography,
    Grid,
    Box,
    makeStyles,
    Container,
    Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      // backgroundColor: theme.palette.info.light,
      backgroundColor: 'inherit',
      color: theme.palette.success.main,
    },
    paperHeading: {
        alignItems: 'center',
        backgroundColor: theme.palette.info.light,
        display: 'flex',
        margin: 0,
        padding: theme.spacing(2)
      },
      myButton: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.accent.main
    },
    mailTo: {    
        color: theme.palette.secondary.dark,
        textDecoration: 'none'
      }          
  }));

const EWorkspaceRequest = () => {
    const classes = useStyles();
    const mailTo = `mailto:support@cpm.org?subject=Request for eWorkspace&body=Our district is interested in piloting eWorkspace.  Please advise regarding next steps.`;
    return (
        <div>
        
            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            >    

            <Box m={6}>
                <Typography variant="h6" component="div" gutterBottom>
                Make remote learning easier!
                </Typography>                
            </Box>

            <Box mb={8}>
                    <Button
                        variant='contained'
                        className={classes.myButton}
                        // onClick={handleImportClick}
                        >
                            <a className={classes.mailTo} href={mailTo}>Request Now</a>
                    </Button>
                </Box>
            <Container>

            <Box mx={16} mb={6} textAlign="center">
                <Typography variant="body2" component="div" gutterBottom>
                With CPM eWorkspace students can now submit work directly in their eBook, while teachers can quickly review student work and provide timely feedback.  
                CPM eWorkspace is an add-on feature available for all courses using the Core Connection curriculum. Request access today.                
                </Typography> 
                <Typography variant="body2" component="div" gutterBottom>
                    
                </Typography>                                
            </Box>     
            </Container>            

        </Grid>
            
            </div>
    )
}

export {
    EWorkspaceRequest
};