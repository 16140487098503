import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  FormControl,
  FormControlLabel,
  Divider,
  Switch,
} from '@material-ui/core';


import { updateProfileRosterTeachers } from 'actions';
import { InfoBox } from 'components/Common/InfoBox';


const RosterTeachers = () => {
  const rosterTeachers = useSelector((state) => state.district.rosterTeachers);
  

  const dispatch = useDispatch();

  const handleChange = () => {
    dispatch(updateProfileRosterTeachers(!rosterTeachers));
  };

  const MyTooltip = () => {
    return (
      <>
      Recommended Setting is "On". <Divider /> Only disable if you have a specific need to manage teacher licenses in a separate license pool.
      </>
    )
  }
  return (
    <InfoBox title='Roster Teacher Settings' tooltip={MyTooltip()} >
          <Box grow={1} />
          <FormControl component="fieldset">

        <FormControlLabel
            control={
              <Switch
                checked={rosterTeachers || false}
                onChange={handleChange}
                name="rosterTeachers"
                color="primary"
              />
            }
            label={rosterTeachers ? 'Teacher Rostering On' : 'Teacher Rostering Off'} 
            />
          </FormControl>
          <Box grow={1} />

    </InfoBox>

  );
};

export {
  RosterTeachers
};
