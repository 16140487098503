import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import MenuSharpIcon from '@material-ui/icons/MenuSharp';
import HomeIcon from '@material-ui/icons/Home';
import { SideNav } from './';
import { logoutUser } from '../../actions';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%'
    }
  },
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      flexShrink: 0,
      width: 0
    }
  },
  drawerPaper: {
    borderRight: 0,
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    width: drawerWidth
  },
  logoStyle: {
    width: drawerWidth * 0.6
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  productName: {
    color: 'rgb(14, 108, 67)',
    fontWeight: 600
  },
  title: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar
}));

const Navigation = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  
  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme();
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    dispatch(logoutUser());    
  };

  const drawer = (
    <>
      <div className={classes.toolbar}>
        <Box alignItems="center" display="flex" flexDirection="column">
          <img alt="CPM Logo" className={classes.logoStyle} src="/CPM.svg" />
          <Typography className={classes.productName} variant="body2">
            Integration Services
          </Typography>
        </Box>
      </div>
      <Divider />
      <SideNav
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
    </>
  );

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="Open Navigation Menu"
            className={classes.menuButton}
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuSharpIcon />
          </IconButton>
          <Typography className={classes.title} noWrap variant="h6">
          Integration Services Admin
          </Typography>
          <Hidden xsDown>
            {isAuthenticated && (
              <Button 
                variant="outlined"
                color="inherit"
                startIcon={<HomeIcon />}
                onClick={handleLogout}>
                My CPM
              </Button>
            )}
          </Hidden>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden mdUp>
          <Drawer
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            classes={{
              paper: classes.drawerPaper
            }}
            elevation={2}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            onClose={handleDrawerToggle}
            open={mobileOpen}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            elevation={2}
            open
            variant="permanent"
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

export { Navigation };
