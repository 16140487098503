import React, { useEffect, useState} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {

  Grid,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Paper,
  Select,

  Typography,
  IconButton
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ClassSharpIcon from '@material-ui/icons/ClassSharp';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
// import HelpIconSharp from '@material-ui/icons/HelpSharp';

import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import { LicensePool } from 'components/LicensePool';
import { fetchLicenses, assignLicense, updateLicense, unassignLicense } from 'actions';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import { ENABLE_MULTI_LICENSE, DISABLE_MULTI_LICENSE } from 'actions/actionTypes';
const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main
  },
  button: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    maxWidth: 600,
    minWidth: 200,
    width: '100%'
  },

  navigationButton: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    marginBottom: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.info.light,
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },
  secondaryHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.info.light,
    
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },  
  paperHeadingTitle: {
    marginRight: theme.spacing(0.5)
  },
  paperInput: {
    padding: theme.spacing(3)
  },
  paperOtherContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },
  tooltip: {
    backgroundColor: theme.palette.success.main,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  },
  warningIcon: {
    color: '#f1c40f',
    fontSize: '80px'
  },
  myButton: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.accent.main
},
padded: {
  paddingRight: '1em',
},
}));




const Licenses = ({ disableNext }) => {
  const courses = useSelector((state) => state.licenses.courses, shallowEqual);
  const eBooks = useSelector((state) => state.licenses.licenses.filter(x => !x.isTeacherEdition), shallowEqual);
  const rosteredSchools = useSelector((state) => state.district.schools.length);
  const licensePoolId = useSelector((state) => state.district.licensePoolId);
  const isLoading = useSelector((state) => state.licenses.isLoading);

  const [dualEnrollmentMode, setDualEnrollmentMode] = useState(false);


  const dispatch = useDispatch();
  
  const classes = useStyles();

  const assignedCourses = courses.filter(
    (c) => c.eBookId !== null && c.eBookId !== ''
  );

  
  const handleDualEnrollmentClick = () => {
    const newState = !dualEnrollmentMode
    setDualEnrollmentMode(newState);
    if (!newState) {
      dispatch({ type: DISABLE_MULTI_LICENSE});
    }
  }

  const handleLicenseChange = (e, course) => {
    const { name: courseId, value: eBookId } = e.target;
    const { _id, courseName } = course;
    console.log(course)
    if (_id && !eBookId) {
      console.log('unassign');
      dispatch(unassignLicense(_id)); 
      return false;
    }

    if (!_id) {
      dispatch(assignLicense({courseId, eBookId, courseName})); 
      console.log("assigning")
      return false;
    }    

    if (_id && eBookId) {
      dispatch(updateLicense({courseId, eBookId, _id})); 
      console.log("patching")
      return false;
    }        

    console.log("unkown")
    return false;
  }

  const enableDualEnrollment = (course) => {
    dispatch({ type: ENABLE_MULTI_LICENSE, payload: course });
  }

  useEffect(() => {
    dispatch(fetchLicenses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensePoolId]);

  useEffect(() => {
    if (assignedCourses.length > 0 && disableNext) {
      disableNext(false);
    }

    return () => {
      if (disableNext) {
        disableNext(true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedCourses]);

  return (
    <>
      {isLoading && eBooks.length === 0 ? (
        <div className={classes.spinner}>
          <CircularProgress color="secondary" size={100} />
        </div>
      ) : (
        <>
        {!licensePoolId && (
          <LicensePool />
        )}

        {licensePoolId && eBooks.length === 0 && (
          <>
          <Alert severity="error">License pool does not have any student licenses</Alert>
          <LicensePool />
          </>
        )}

        {licensePoolId && eBooks.length !== 0 && (
        <Paper className={classes.paper} elevation={2} square>
          <div className={classes.paperHeading}>
              <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >   
                <Typography
                  className={classes.paperHeadingTitle}
                  component="h1"
                  variant="body1"
                >
                  Licenses
                </Typography>

            {courses && courses.length > 0 && (
            <Grid item>
              <Typography variant="overline" className={classes.padded} align='left'>
                Multiple Books: 
              
              </Typography>
                <Button
                aria-label="Enter Multi eBook mode"
                color="secondary"
                variant={dualEnrollmentMode ? 'contained' : 'outlined'}
                onClick={handleDualEnrollmentClick}
              >
                 {dualEnrollmentMode ? 'Disable' : 'Enable' }
                </Button>
                </Grid>
            )}
            </Grid>
            </div>
          
      <div className={classes.paperInput}>
            <List>
              {licensePoolId && courses.map((course, courseIndex) => {
                return (
                  <ListItem divider key={`course-${courseIndex}-index`}>
                    <ListItemAvatar>
                      
                        {course.multiBook ? <CollectionsBookmarkIcon color='primary' /> : <ClassSharpIcon />}
                      
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography
                      id={course.courseId}
                      primary={
                        <Typography variant="body2">
                          {course.courseName.toUpperCase()} - {course.courseId}
                        </Typography>
                      }
                      secondary={
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink>Student eBook License</InputLabel>
                          <Select
                            className={classes.selectEmpty}
                            name={course.courseId}
                            // onChange={handleOnChange}
                            onChange={(e)=>handleLicenseChange(e, course)}
                            value={course.eBookId || ''}
                            //value={course}
                          >
                            <MenuItem value={null}>
                              <em>None</em>
                            </MenuItem>
                            {eBooks.map((eBook) => {
                              return (
                                <MenuItem
                                  key={eBook.eBookId}
                                  value={eBook.eBookId}
                                >
                                  {eBook.eBookName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      }
                    />
                    {course && course.eBookId && !course.blocked && dualEnrollmentMode && (
                      <ListItemSecondaryAction onClick={() => enableDualEnrollment(course)}>
                      <IconButton edge="end" aria-label="add second eBook to course">                        
                        <PlaylistAddSharpIcon color='secondary'/>                  
                      </IconButton>
                    </ListItemSecondaryAction>
                    
                    )}

                  </ListItem>
                );
              })}
            </List>

            <Container className={classes.paperOtherContent}>
              {rosteredSchools > 0 && courses.length === 0 && (
                <>
                  <Typography variant="h3">
                    <WarningSharpIcon className={classes.warningIcon} />
                  </Typography>
                  <Typography variant="body2">
                    You must roster a course before you can assign licenses.
                  </Typography>
                </>
              )}
            </Container>
          </div>
        </Paper>
          )}
        </>
      )}
    </>
  );
};

export default Licenses;
