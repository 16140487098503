import React from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
  import {
    Divider,
    Grid,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
  } from '@material-ui/core';
  const configOptions = [
    {
      display: "Import from URL",
      route: 'import',
      icon: 'http'
    },
    {
      display: "Import XML File",
      route: 'upload',
      icon: 'upload'
    },
    {
      display: "Manually configure",
      route: 'add',
      icon: 'format_list_bulleted'
    }                 
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.info.dark,
    },  
  }));
 
const SAMLWizard = () => {
    const classes = useStyles();
    const {
        path
      } = useRouteMatch();      
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="quarter-page-load fadeIn"
      >            
      <h1>Add SAML IdP</h1>
      <List component="nav" aria-label='SAML Configuration Options'>
      {configOptions.map((item, key) => (      
          <RouterLink className={classes.link} key={key} to={`${path}/${item.route}`}>
        <ListItem>
          <ListItemIcon>                      
                <Icon color="primary">{item.icon}</Icon>                
          </ListItemIcon>              
          <ListItemText primary={item.display} />
          <ListItemSecondaryAction>                 
            <Icon color="secondary">chevron_right</Icon>            
          </ListItemSecondaryAction>   
        </ListItem>
        <Divider />
        </RouterLink>
      ))}
      </List>
        </Grid>
    )
  }

export { SAMLWizard };