import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Switch, Route, useRouteMatch, BrowserRouter as Router } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {     
    Grid,
    Box,
    Divider,
    colors,
    Button,
    Paper,    
    Icon    
  } from '@material-ui/core';

  
  import {
    
    SSOProviders,

    SAMLSSO,
    SocialSSO,
    TenantSSO
  } from '../../components/SSOProviders';
  
  import { getSSOProfiles } from '../../actions';

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      },
    errorMessage: {
      alignItems: 'center',
      borderRadius: 0,
      display: 'flex',
      minHeight: '64px'
    },
    gridContainer: {
      // maxWidth: '1040px',
      minHeight: '64px'
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.info.dark,
    },
    spinner: {
      minHeight: '50vh',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(6),
      width: '100%'
    },
    marginLeft: {
      marginLeft: theme.spacing(2)
    },
    successIcon: {
      color: theme.palette.secondary.light,
      fontSize: '56px',
    },
    successIconSmall: {
        color: theme.palette.success.main,
        fontSize: '24px'    
    },  
    infoIcon: {
      color: theme.palette.info.main,
      fontSize: '56px',
      '& small': {
        fontSize: '24px'
      }    
    },  
    warningIcon: {
      color: colors.orange,
      fontSize: '56px',    
    },
    warningIconSmall: {
      color: colors.orange[600],
      fontSize: '24px'    
    },
    uncheckedIconSmall: {
      color: colors.blueGrey[600],
      fontSize: '24px'    
    },  
    errorIconSmall: {
      color: colors.red[600],
      fontSize: '24px'    
    },
  }));


const DynamicSSORouter = ({children, showBack=true}) => {
  const classes = useStyles();
  const history = useHistory();
  const handleBack = () => {
    return history.replace('/sso');
  }
return (
  <div>
      <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item sm={12} xs={12}>
                <Divider />
                
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="baseline">
                      {showBack ? (
                      <Button
                          className={classes.marginLeft}
                          variant='contained'                          
                          startIcon={<Icon>arrow_back</Icon>}
                          onClick={handleBack}
                          >
                      </Button>
                    ) : 
                    <></>
                    }
                    <Box flex={1} />
                    <h2>SSO Integrations</h2>
                    <Box flex={1} />
                    
                                
                </Grid>
                <Divider />
            </Grid>
        
      </Grid>        
      <Box mt={6} />
        <Paper elevation={3} spacing={3}>
          
          <Box p={3}>
            {children}
            </Box>
        </Paper>
        
  </div>
  );
}

const SSO = ( ) => {
  const dispatch = useDispatch();
    const classes = useStyles();
    const {
      path,
      // url
    } = useRouteMatch();
    const { ssoProfiles,  error } = useSelector((state) => state.sso);
    
    useEffect(() => { 
      dispatch(getSSOProfiles());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
      <Router>
    <div className={`${classes.root} fadeIn`}>

  
  <Switch>
    <Route exact path={`${path}`} component={() => <DynamicSSORouter showBack={false}><SSOProviders ssoProfiles={ssoProfiles}/></DynamicSSORouter>} />
    {/* <Route exact path={`/sso/:strategy`} component={() => <DynamicSSORouter showBack={true}><SSOWrapper ssoProfiles={ssoProfiles}/></DynamicSSORouter>} /> */}
    <Route path={`${path}/CLEVER`} component={() => <DynamicSSORouter><TenantSSO error={error} ssoProvider={ssoProfiles.CLEVER}/></DynamicSSORouter>} />    
    <Route path={`${path}/CLASSLINK`} component={() => <DynamicSSORouter><TenantSSO error={error} ssoProvider={ssoProfiles.CLASSLINK}/></DynamicSSORouter>} />
    <Route path={`${path}/SAML`} component={() => <DynamicSSORouter><SAMLSSO error={error} ssoProvider={ssoProfiles.SAML}/></DynamicSSORouter>} />
    <Route path={`${path}/GOOGLE`} component={() => <DynamicSSORouter><SocialSSO error={error} ssoProvider={ssoProfiles.GOOGLE} /></DynamicSSORouter>} />
    <Route path={`${path}/AZURE`} component={() => <DynamicSSORouter><SocialSSO error={error} ssoProvider={ssoProfiles.AZURE} /></DynamicSSORouter>} />
  </Switch>
                
    </div>
    </Router>
    )
}

export {
    SSO
};