
/**
 * @fullObject: object
 * @allowedKeys: string[]
 */
const cleanObject = (fullObject, allowedKeys) => {
    // Filters out the non-matching keys
    return Object.keys(fullObject)
        .filter(key => allowedKeys.includes(key))
        .reduce((obj, key) => {
            obj[key] = fullObject[key];
            return obj;
        }, {});
};

export default cleanObject;