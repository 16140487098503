import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  Divider,  
  Icon
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { freezeTerm, updateProfileMode } from 'actions';
import { InfoBox } from 'components/Common/InfoBox';

const useStyles = makeStyles((theme) => ({
  boxContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  boxContentNumber: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },

}));

const FreezeRoster = () => {
  
  const overrideTermDate = null;
  const [currentOverrideTermDate, setCurrentOverrideTermDate] = useState(null);
  const [processingOverride, updateProcessingOverride] = useState(false);
  
  const MyTooltip = () => {
    return (
      <>
      Freeze:
      <Divider /> Freezing the term date can be used to persist license assignments beyond the authoritative end date
      <Divider /> <Icon>warning_amber</Icon> This modifies the "staged" data and will be overwritten on the next import.   Set Schedule to "Paused" to persist change.
      </>
    )
  }

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = (date) => {
    setCurrentOverrideTermDate(date);
  };

  const handleClick = (e) => {
    e.preventDefault();
    updateProcessingOverride(true);
    freezeTerm(currentOverrideTermDate).then(response =>  {      
      toast.success(
        'Roster end date successfully frozen for staged records.  Please run a manual License Sync to push the changes to ELS.'
      );
      dispatch(updateProfileMode('setup'));
    }
    )
    .catch(err => {      
      toast.warn(
        'There was a problem saving your mode settings. Please try again.'
      );
    })
    .finally(() => {
      updateProcessingOverride(false);
    });

  };

  return (
<InfoBox title='Freeze Term Date' tooltip={MyTooltip()} >



  <FormControl component="fieldset">
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
          margin="normal"
          id="overrideTermDate"
          label="Set Freeze Date"
          format="MMM DD, YYYY"
          value={currentOverrideTermDate}
          onChange={handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </ MuiPickersUtilsProvider>          
    </FormControl>

  <Button
      aria-label="Save Mode Settings"
      className={classes.button}
      color="primary"
      disabled={overrideTermDate === currentOverrideTermDate || processingOverride}
      onClick={handleClick}
      variant="contained"
      fullWidth={true}
      >
      Freeze & Pause Schedule
    </Button>          

      

</InfoBox>

  );
};

export {
  FreezeRoster
};
