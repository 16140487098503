import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosWithAuth } from 'utils/axiosWithAuth';
import { LTI_API_URL } from 'env';


const initialState = {   
  platforms: [],
  isLoading: false,
  error: '',
};

export const fetchLtiPlatformList = createAsyncThunk(
  'ltiAdvantageAdmin/fetchLtiPlatformList',
  async (mapped = true) => {          
        const url = mapped ? `${LTI_API_URL}/api/integrations/admin/platforms` : `${LTI_API_URL}/api/integrations/admin/platforms/unmapped`;
        const response = await axiosWithAuth().get(url);        
        return response.data;
});



export const ltiAdvantageAdminSlice = createSlice({
  name: 'ltiAdvantageAdmin',
  initialState,
  reducers: {    
  },
  extraReducers: {
    // GET LIST
    [fetchLtiPlatformList.pending]: (state) => {
      state.loading = true;
      state.platforms = [];      
    },
    [fetchLtiPlatformList.fulfilled]: (state, { payload }) => {
      state.loading = false; 
      state.platforms = payload;
    },
    [fetchLtiPlatformList.rejected]: (state, { payload }) => {    
      state.loading = false;
      state.error = payload;
    }, 
  
  },
})


export const { seedLtiPlatform, removeSeedLtiPlatform } =  ltiAdvantageAdminSlice.actions;
export const ltiAdvantageAdminReducer = ltiAdvantageAdminSlice.reducer;


