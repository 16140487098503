export const ADD_STICKY_LICENSE = 'ADD_STICKY_LICENSE';
export const UPDATE_STICKY_LICENSE_PROFILE = 'UPDATE_STICKY_LICENSE_PROFILE';

export const ASSIGN_LICENSE = 'ASSIGN_LICENSE';
export const UNASSIGN_LICENSE = 'UNASSIGN_LICENSE';
export const UPDATE_LICENSE = 'UPDATE_LICENSE';
export const DISABLE_MULTI_LICENSE = 'DISABLE_MULTI_LICENSE';
export const ENABLE_MULTI_LICENSE = 'ENABLE_MULTI_LICENSE';




export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE';
export const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';

export const AUTHENTICATE_NAV_ADMIN = 'AUTHENTICATE_NAV_ADMIN';

export const FETCH_DISTRICTPROFILE_REQUEST = 'FETCH_DISTRICTPROFILE_REQUEST';
export const FETCH_DISTRICTPROFILE_SUCCESS = 'FETCH_DISTRICTPROFILE_SUCCESS';
export const FETCH_DISTRICTPROFILE_FAILURE = 'FETCH_DISTRICTPROFILE_FAILURE';

export const RESET_SCHEDULE_REQUEST = 'RESET_SCHEDULE_REQUEST';
export const RESET_SCHEDULE_SUCCESS = 'RESET_SCHEDULE_SUCCESS';
export const RESET_SCHEDULE_FAILURE = 'RESET_SCHEDULE_FAILURE';

export const COMPLETE_ONBOARDING = 'COMPLETE_ONBOARDING';

export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';
export const FETCH_COURSES_REQUEST = 'FETCH_COURSES_REQUEST';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const REMOVE_INVALID_COURSE = 'REMOVE_INVALID_COURSE';

export const FETCH_LICENSES_FAILURE = 'FETCH_LICENSES_FAILURE';
export const FETCH_LICENSES_REQUEST = 'FETCH_LICENSES_REQUEST';
export const FETCH_LICENSES_SUCCESS = 'FETCH_LICENSES_SUCCESS';
export const FETCH_SCHOOLS_FAILURE = 'FETCH_SCHOOLS_FAILURE';
export const FETCH_SCHOOLS_REQUEST = 'FETCH_SCHOOLS_REQUEST';
export const FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';
export const REMOVE_INVALID_SCHOOL = 'REMOVE_INVALID_SCHOOL';
export const FETCH_STICKY_LICENSES_FAILURE = 'FETCH_STICKY_LICENSES_FAILURE';
export const FETCH_STICKY_LICENSES_REQUEST = 'FETCH_STICKY_LICENSES_REQUEST';
export const FETCH_STICKY_LICENSES_SUCCESS = 'FETCH_STICKY_LICENSES_SUCCESS';

export const FETCH_LICENSEPOOLS_FAILURE = 'FETCH_LICENSEPOOLS_FAILURE';
export const FETCH_LICENSEPOOLS_REQUEST = 'FETCH_LICENSEPOOLS_REQUEST';
export const FETCH_LICENSEPOOLS_SUCCESS = 'FETCH_LICENSEPOOLS_SUCCESS';

export const FETCH_TEACHERS_FAILURE = 'FETCH_TEACHERS_FAILURE';
export const FETCH_TEACHERS_REQUEST = 'FETCH_TEACHERS_REQUEST';
export const FETCH_TEACHERS_RESET = 'FETCH_TEACHERS_RESET';
export const FETCH_TEACHERS_SUCCESS = 'FETCH_TEACHERS_SUCCESS';
export const REMOVE_STICKY_LICENSE = 'REMOVE_STICKY_LICENSE';
export const REMOVE_STICKY_LICENSE_EBOOK = 'REMOVE_STICKY_LICENSE_EBOOK';
export const RESET_COURSES_FILTER = 'RESET_COURSES_FILTER';
export const RESET_SCHOOLS_FILTER = 'RESET_SCHOOLS_FILTER';
export const RESET_STICKY_LICENSES_FILTER = 'RESET_STICKY_LICENSES_FILTER';
export const REVIEW_CHANGE_GROUPING = 'REVIEW_CHANGE_GROUPING';
export const REVIEW_ONLOAD = 'REVIEW_ONLOAD';
export const SET_COURSES_FILTER = 'SET_COURSES_FILTER';
export const SET_DISTRICT_ID = 'SET_DISTRICT_ID';
export const SET_SCHOOLS_FILTER = 'SET_SCHOOLS_FILTER';
export const SET_STICKY_LICENSES_FILTER = 'SET_STICKY_LICENSES_FILTER';
export const TOGGLE_ROSTER_COURSE = 'TOGGLE_ROSTER_COURSE';
export const TOGGLE_ROSTER_SCHOOL = 'TOGGLE_ROSTER_SCHOOL';

export const TOGGLE_CERTIFICATION_REQUEST = 'TOGGLE_CERTIFICATION_REQUEST';
export const TOGGLE_CERTIFICATION_SUCCESS = 'TOGGLE_CERTIFICATION_SUCCESS';
export const TOGGLE_CERTIFICATION_FAILURE = 'TOGGLE_CERTIFICATION_FAILURE';



export const UPDATE_DISTRICT_PROFILE_REQUEST = 'UPDATE_DISTRICT_PROFILE_REQUEST';
export const UPDATE_DISTRICT_PROFILE_SUCCESS = 'UPDATE_DISTRICT_PROFILE_SUCCESS';
export const UPDATE_DISTRICT_PROFILE_FAILURE = 'UPDATE_DISTRICT_PROFILE_FAILURE';


export const UPDATE_PROFILE_MODE = 'UPDATE_PROFILE_MODE';
export const UPDATE_TERM_OVERRIDE = 'UPDATE_TERM_OVERRIDE';
export const UPDATE_PROFILE_ROSTER_TEACHERS = 'UPDATE_PROFILE_ROSTER_TEACHERS';


export const VERIFY_ROSTER_CREDENTIALS_REQUEST = 'VERIFY_ROSTER_CREDENTIALS_REQUEST';
export const VERIFY_ROSTER_CREDENTIALS_SUCCESS = 'VERIFY_ROSTER_CREDENTIALS_SUCCESS';
export const VERIFY_ROSTER_CREDENTIALS_FAILURE = 'VERIFY_ROSTER_CREDENTIALS_FAILURE';

export const FETCH_ELSPROFILE_REQUEST = 'FETCH_ELSPROFILE_REQUEST';
export const FETCH_ELSPROFILE_SUCCESS = 'FETCH_ELSPROFILE_SUCCESS';
export const FETCH_ELSPROFILE_FAILURE = 'FETCH_ELSPROFILE_FAILURE';

// REPORTS
export const FETCH_PUSHREPORT_FAILURE = 'FETCH_PUSHREPORT_FAILURE';
export const FETCH_PUSHREPORT_REQUEST = 'FETCH_PUSHREPORT_REQUEST';
export const FETCH_PUSHREPORT_SUCCESS = 'FETCH_PUSHREPORT_SUCCESS';

export const CLEAR_IMPORTREPORT = 'CLEAR_IMPORTREPORT';
export const FETCH_IMPORTREPORT_REQUEST = 'FETCH_IMPORTREPORT_REQUEST';
export const FETCH_IMPORTREPORT_SUCCESS = 'FETCH_IMPORTREPORT_SUCCESS';
export const FETCH_IMPORTREPORT_FAILURE = 'FETCH_IMPORTREPORT_FAILURE';
export const FILTER_IMPORTREPORT = 'FILTER_IMPORTREPORT';


export const FETCH_JOBLIST_REQUEST = 'FETCH_JOBLIST_REQUEST';
export const FETCH_JOBLIST_SUCCESS = 'FETCH_JOBLIST_SUCCESS';
export const FETCH_JOBLIST_FAILURE = 'FETCH_JOBLIST_FAILURE';



// AUTH
export const INIT_ADMIN = 'INIT_ADMIN';
export const UNSET_ADMIN_MODE = 'UNSET_ADMIN_MODE';
export const SET_ADMIN_IMPERSONATE = 'SET_ADMIN_IMPERSONATE';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';



// EWORKSPACE
export const FETCH_EWORKSPACE_REQUEST = 'FETCH_EWORKSPACE_REQUEST';
export const FETCH_EWORKSPACE_SUCCESS = 'FETCH_EWORKSPACE_SUCCESS';
export const FETCH_EWORKSPACE_FAILURE = 'FETCH_EWORKSPACE_FAILURE';
export const ENABLE_EWORKSPACETEACHER_REQUEST = 'ENABLE_EWORKSPACETEACHER_REQUEST';
export const ENABLE_EWORKSPACETEACHER_SUCCESS = 'ENABLE_EWORKSPACETEACHER_SUCCESS';
export const DISABLE_EWORKSPACETEACHER_REQUEST = 'DISABLE_EWORKSPACETEACHER_REQUEST';
export const DISABLE_EWORKSPACETEACHER_SUCCESS = 'DISABLE_EWORKSPACETEACHER_SUCCESS';
export const DISABLE_EWORKSPACETEACHER_FAILURE = 'DISABLE_EWORKSPACETEACHER_FAILURE';



// EXCLUDED
export const FETCH_EXCLUDED_REQUEST = 'FETCH_EXCLUDED_REQUEST';
export const FETCH_EXCLUDED_SUCCESS = 'FETCH_EXCLUDED_SUCCESS';
export const FETCH_EXCLUDED_FAILURE = 'FETCH_EXCLUDED_FAILURE';

export const ADD_EXCLUDED_SUCCESS = 'ADD_EXCLUDED_SUCCESS';
export const DELETE_EXCLUDED_SUCCESS = 'DELETE_EXCLUDED_SUCCESS';



// SSO
export const FETCH_SSO_REQUEST = 'FETCH_SSO_REQUEST';
export const FETCH_SSO_SUCCESS = 'FETCH_SSO_SUCCESS';
export const FETCH_SSO_FAILURE = 'FETCH_SSO_FAILURE';

export const SSO_UPDATE_REQUEST = 'SSO_UPDATE_REQUEST';
export const SSO_UPDATE_SUCCESS = 'SSO_UPDATE_SUCCESS';
export const SSO_UPDATE_FAILURE = 'SSO_UPDATE_FAILURE';