import React, { createRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isValidDomain from 'is-valid-domain';
import { 
  Button,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import HttpIcon from '@material-ui/icons/Http';
import { DynamicField } from '../Common/DynamicField';
import { CustomSnackbar } from '../Common/CustomSnackbar';
import { addSocialSSO, deleteSocialSSO, updateSocialSSO } from "../../actions";
  const blankDomain = {
    emailSuffix: ""
  }
const SocialSSO = ({ ssoProvider, error })  => {
  const form = createRef();
  const dispatch = useDispatch();
  const [editIndex, updateEditIndex] = useState();
  const [editItem, updateEditItem] = useState();
  const [socialDomains, updateSocialDomains] = useState([]);
  const [snackbarState, updateSnackbarState] = useState({
    open: false,
    severity: 'error',
    message: ""
  });


  useEffect(() => {
    if (error) {
      updateSnackbarState({...snackbarState, open: true, message: `Error => ${error}`}); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])  

  useEffect(() => {
    updateSocialDomains(ssoProvider.settings.domains)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoProvider.settings]);


  useEffect(() => {
    if (!!(editIndex || editIndex === 0)) {
      updateEditItem(socialDomains[editIndex]);
    } else {
      updateEditItem(blankDomain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editIndex, socialDomains]);

  const handleChange = (value) => {
      updateEditItem({...editItem, ...value, emailSuffix: value.emailSuffix.toLowerCase()});
  }

  const handleCancel = () => {
    const activeItem = socialDomains[editIndex];
    updateEditIndex();
    if ('id' in activeItem) {
      return false;
    } else {
      const updatedSocialDomains = [...socialDomains];
      updatedSocialDomains.splice(editIndex, 1);
      updateSocialDomains(updatedSocialDomains);
    }
  }

    const handleCloseSnackbar = () => {
      updateSnackbarState({ ...snackbarState, open: false });
    };

    const handleDelete = (id) => {     
        updateEditIndex();
        const updatedSocialDomains = [...socialDomains];
        updatedSocialDomains.splice(editIndex, 1);
        updateSocialDomains(updatedSocialDomains);
        dispatch(deleteSocialSSO(id));
    }

    const addDomain = () => {
      const index = socialDomains.length;      
      updateSocialDomains([...socialDomains, {type: ssoProvider.type, emailSuffix: "", useEmailAsUserName: "t"}]);
      updateEditIndex(index);
    }
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      // Custom validation that emailSuffix is a valid domain name
      const inputs = form.current.elements;
      for (let i = 0; i < inputs.length; i++) {        
        if (inputs[i].name === "emailSuffix" && !isValidDomain(inputs[i].value)) {
          inputs[i].setCustomValidity("must be valid domain name");
        } else {
          inputs[i].setCustomValidity("");
        }
      }
      if(form.current.reportValidity()) {        
        const updatedSocialDomains = socialDomains.reduce((memo, item, index) => {        
          return index === editIndex ? [...memo, {...item, ...editItem}] : [...memo, item];
        }, []);
        const activeItem = {...updatedSocialDomains[editIndex], type: ssoProvider.type}
        
        
          if ('id' in activeItem) {
             await dispatch(updateSocialSSO(activeItem));
          } else {
            await dispatch(addSocialSSO(activeItem));
          }
          updateEditIndex();                          
      }
    }

    return (        
      <form ref={form} onSubmit={e => e.preventDefault()} noValidate>
            <CustomSnackbar snackbarState={snackbarState} handleClose={handleCloseSnackbar} />
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <h3>{ssoProvider.displayName}</h3>
                <Button
                  aria-label="Save"
                  color="secondary"
                  size="small"
                  disabled={!!(editIndex || editIndex === 0)}
                  onClick={addDomain}
                  variant="outlined"
                >
                Add
              </Button>                              
            </Box>
            
            <Divider />
            
          <List component="nav" aria-label='List of configured domains'>
          {socialDomains.map((item, index) => (                          
                  <ListItem key={index}>
                    <ListItemIcon>
                      <HttpIcon />
                    </ListItemIcon>
                    <ListItemText                       
                      primary={
                        <div style={{maxWidth: '85%'}}>
                          <DynamicField                       
                            editMode={(editIndex === index)}
                            variant="outlined"
                            handleChange={handleChange}
                            fieldDisplay="Domain Name"
                            fieldName="emailSuffix"
                            fieldValue={editIndex === index ? editItem.emailSuffix : item.emailSuffix}
                          />
                        </div>
                      } />
                    {editIndex === index ? (
                      <ListItemSecondaryAction >
                         {editItem.emailSuffix === "" && editItem.id ? (
                           <span className="clickable-link" onClick={()=>handleDelete(item.id)}>Delete </span>
                           ) : (
                            <span className="clickable-link" onClick={handleSubmit}>Save </span>     
                          )}                            
                         | <span className="clickable-link" onClick={handleCancel}>Cancel</span>
                    </ListItemSecondaryAction>
                    ) : (
                    <ListItemSecondaryAction className="clickable-link" onClick={() => updateEditIndex(index)}>
                        Edit 
                    </ListItemSecondaryAction>
                    )}
                </ListItem>
          ))}
          </List>
    </form>
    )
}

export {
  SocialSSO
};