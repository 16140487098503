import React from 'react';
import {
    Box,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    textColor: {
        color: theme.palette.primary.dark
    },
}));

const LabelHeading = ({fieldDisplay, fieldValue, fieldType='h6'}) => {
    const classes = useStyles();
    return (
        <Box flexDirection='column'>
            <Typography
                color="textSecondary"
                variant="subtitle2"
            >
                {fieldDisplay}
            </Typography>

            <>
            {(() => {
  
                switch (fieldType) {
                   case 'date':
                       return (
                        <Typography        
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        className={classes.textColor}
                        >

                        {fieldValue ? moment(fieldValue).local().format('MMM, DD YYYY') : 'xxxx'}
                        </Typography>
                       )
                    case 'datetime':
                        return (
                         <Typography        
                         variant="subtitle2"
                         display="block"
                         gutterBottom
                         className={classes.textColor}
                         >
 
                         {fieldValue ? moment(fieldValue).local().format('MMM, DD YYYY hh:mm a') : 'xxxx'}
                         </Typography>
                        )
                   case 'currency':
                       return (
                        <Typography        
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        className={classes.textColor}
                        >
                        {fieldValue ? (
                        <NumberFormat
                            prefix="$"
                            displayType={'text'}
                            thousandSeparator
                            value={fieldValue}
                            />
                        ) : (
                            <>
                            xxxx
                            </>
                        )}
                        </Typography>
                       )
                   default:
                       return (
                        <Typography        
                        variant={fieldType}
                        display="block"
                        gutterBottom
                        className={classes.textColor}
                        >
                        {fieldValue || 'xxxx'}
                        </Typography>
                       )
                }
       
             })()}
                </>

                    

        </Box>
    )
}

export { LabelHeading };