import React, { useEffect, useState, useReducer } from 'react';
import { axiosWithAuth } from 'utils/axiosWithAuth';
import { API_URL } from 'env';
import { makeStyles } from '@material-ui/core/styles';
import { intersectionBy, differenceBy  } from 'lodash';
import {
  Paper,
  Grid
} from '@material-ui/core';

import Chip from '@material-ui/core/Chip';
import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import { DynamicFormat } from '../Common/DynamicFormat';


import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minHeight: '30vh',
  },
  paper: {
    backgroundColor: theme.palette.info.light,
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: '1em'
  },
  spinner: {
    minHeight: '50vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    width: '100%'
  },
}));



const multiPropsFilter = (products, filters) => {
  const filterKeys = Object.keys(filters);
  return products.filter(product => {
    return filterKeys.every(key => {
      // if (!filters[key].length) {
      //   return true;
      // }
      // Loops again if product[key] is an array.
      if (Array.isArray(product[key])) {
        return product[key].some(keyEle => filters[key].includes(keyEle));
      }
      return filters[key].includes(product[key]);
    });
  });
};

const initlogData = {
  jobId: '',
  searchFilter: '', 
  severityFilters: [
    { severity: 'info', count: 0, show: true }, 
    { severity: 'debug', count: 0, show: false }, 
    { severity: 'error', count: 0, show: true }
  ],
  logs: [], _logs: [] 
};

const logDataReducer = (state, action) => {
  switch (action.type) {
    case 'reset':      
      return initlogData;
    case 'update':            
      // find out if there is anything new coming in
      const newStuff = differenceBy(state.severityFilters, action.payload.severityFilters, 'severity');
      const newStuff2 = differenceBy(action.payload.severityFilters, state.severityFilters, 'severity');
      const intersectionStuff = intersectionBy(state.severityFilters, action.payload.severityFilters, 'severity');

      // combine data and make sure that we have the correct totals coming in from the server
      const updatedSeverityFilters = [...intersectionStuff, ...newStuff2, ...newStuff].reduce((memo, mySeverityItem) => {
        const countItem = action.payload.severityFilters.find(x => x.severity === mySeverityItem.severity);
        return [...memo, {...mySeverityItem, count: countItem ? countItem.count : 0}];
      }, []);

      const fullLogs = state._logs.concat(action.payload.logs);
      const updateActiveSeverityFilters = updatedSeverityFilters.filter(x => x.show).map(x => x.severity);      
      const updateFilteredLogs = multiPropsFilter(fullLogs, { severity: updateActiveSeverityFilters });
            
      return {...state, _logs: fullLogs, logs: updateFilteredLogs, severityFilters: updatedSeverityFilters };
    case 'toggleSeverity':
      const updatedToggledSeverityFilters = state.severityFilters.map( myFilter => ({...myFilter, show: myFilter.severity === action.payload ? !myFilter.show : myFilter.show }));
      const toggleActiveFilters = updatedToggledSeverityFilters.filter(x => x.show).map(x => x.severity);
      const toggledFilteredLogs = multiPropsFilter(state._logs, { severity: toggleActiveFilters });      
      return {...state, logs: toggledFilteredLogs, severityFilters: updatedToggledSeverityFilters };        
    default:
      throw new Error('Unsupported reducer action');
  }
}

const JobLogPaged = (  {jobId, refreshList } ) => {
  
  const classes = useStyles(); 
  const [logData, updateLogData] = useReducer(logDataReducer, initlogData);
  const [nextPageToken, updateNextPageToken] = useState();

  const toggleFilter = (severity) => {
    updateLogData({ type: 'toggleSeverity', payload: severity });
  }


  useEffect(() => {
    console.log(`jobId Changed: ${jobId}`);
    updateLogData({ type: 'reset' });
    (async () => {          
      const { data } = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/reports/job/${jobId}/status/token`
      );
      updateNextPageToken(data);
    })();
  
    return () => {
      updateNextPageToken();
    };
  }, [jobId]);


  useEffect(() => {
    if (!nextPageToken) {
        return () => {};
    }
    (async () => {
      const { data } = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/reports/job/status/${nextPageToken}`
      );

      updateLogData({ type: 'update', payload: { logs: data.logs, severityFilters: data.totals }});

      // No need to delay next query if the job is in a completed state
      // No nextPageToken will cancel the loading bar
      if(data.nextPageToken && data.state === 'running') {
        setTimeout(() => {
          updateNextPageToken(data.nextPageToken);
        }, "2000");          
      } else {        
        updateNextPageToken(data.nextPageToken);
        if (!data.nextPageToken) {          
          refreshList();          
        }
      }
      

    })();
  
    // Unmount
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPageToken]);

  return (
    <>

      <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      // className='fadeIn'
      >



        <Grid item lg={12} md={12}  className={classes.paper} >
          {logData.severityFilters.map((row) => (
              <Chip
              key={row.severity}
              size="small"
              icon={row.show ? <ClearIcon /> : <AddIcon />}
              label={`${row.severity} (${row.count})`}
              onClick={() => toggleFilter(row.severity)}
              color={row.show ? 'secondary' : 'default' }
            />
              ))}  
              
        </Grid>
    


        <Grid item lg={12} md={12}>
          <TableContainer className={classes.table} component={Paper}>
              <Table  size="small" aria-label="staged licenses table">
                <TableHead>
                  <TableRow>
                    <TableCell>Job Type</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Severity</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logData.logs.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell scope="row">
                        {row.context}
                      </TableCell>
                      <TableCell>
                        <DynamicFormat 
                          fieldType='time'
                          fieldValue={row.date}
                        />
                      </TableCell>
                      <TableCell>{row.severity}</TableCell>
                      <TableCell>{row.details}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {nextPageToken && (                    
              <Grid
                  container
                  direction="column"
                  alignItems="center">
                                  
                  <div className="lds-facebook">
                      <div></div>
                      <div></div>
                      <div></div>
                  </div>
              </Grid>
            )}                      
            </TableContainer>       
          </Grid>
      </Grid>
</>
  );
};
export {
  JobLogPaged
};

