import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {    
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    width: '100%',
    minHeight: '30vh',
    // minWidth: '650',
  },
  spinner: {
    minHeight: '35vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },
  iconSmall: {
    color: theme.palette.secondary.main,
    fontSize: '16px'    
  },
}));





const PreloadReport = (  ) => {  
  const classes = useStyles();
  const report = useSelector((state) => state.importReport, shallowEqual);
    

  return (
    <>
      {report && report.results && (
        <>

          <TableContainer component={Paper} className={classes.table}>  
              <Table size="small" aria-label="preloaded data">
                <TableHead>
                  <TableRow>
                    <TableCell>Resource</TableCell>
                    <TableCell align="center">Anticipated</TableCell>
                    <TableCell align="center">Successful</TableCell>
                    <TableCell>Status</TableCell>                    
                  </TableRow>
                </TableHead>
                <TableBody className={report.isLoading ? 'fadeOut' : 'fadeIn'}>
                  {Object.keys(report.results).map((key, index) => (
                    <TableRow key={`class-${index}-key`}>
                      <TableCell component="th" scope="row">
                        {key}
                      </TableCell>
                        <TableCell align="center">{report.results[key].anticipate}</TableCell>
                        <TableCell align="center">{report.results[key].successful}</TableCell>
                        <TableCell>{report.results[key].status}</TableCell>                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </>
  )} 
<div>{report.state}</div>
    {report && report.state && (
      <div>{report.state}</div>
    )}
    </>
  );
};
export {
  PreloadReport
};

