import { 
    FETCH_SSO_REQUEST,
    FETCH_SSO_SUCCESS,
    FETCH_SSO_FAILURE,
    SSO_UPDATE_REQUEST,
    SSO_UPDATE_SUCCESS,
    SSO_UPDATE_FAILURE,
} from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    error: '',
    ssoProfiles: { 
      AZURE: {
        isActive: false,
        displayName: "",
        settings: {
          domains: []
        }
      },
      GOOGLE: {
        isActive: false,
        displayName: "",
        settings: {
          domains: []
        }
      },
      CLEVER: {
        isActive: false,
        displayName: "",
        settings: { tenantId: null }
      },
      CLASSLINK: {
        isActive: false,
        displayName: "",
        settings: { tenantId: null }
      },
      SAML: {
        isActive: false,
        displayName: "",
        settings: {}
      }      
    }
};

const ssoReducer = ((state = initialState, action) => {
    switch (action.type) {         
      case FETCH_SSO_REQUEST:
        return {...initialState, isLoading: true };
      case FETCH_SSO_SUCCESS:        
        return { ...state, isLoading: false, ssoProfiles: action.payload };      
      case FETCH_SSO_FAILURE:
        return {...state, error: action.payload, isLoading: false };        
      case SSO_UPDATE_REQUEST:
        return {...state, isLoading: true };
      case SSO_UPDATE_SUCCESS:
        return { ...initialState, isLoading: false, ssoProfiles: action.payload };
      case SSO_UPDATE_FAILURE:
        return {...state, error: action.payload, isLoading: false };           


      default:
        return state;
    }
  });
  
  export default ssoReducer;
  