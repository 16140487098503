
import React, { createRef, useState } from 'react';
import { useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { 
    Button,
    Box,
    Grid,
} from '@material-ui/core'; 
import { DynamicField,  } from '../../Common/DynamicField';
import { importSAML } from '../../../actions';
const SAMLImport = ({handleCancel}) => {
    const form = createRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const [formValues, updateFormValues] = useState({url: ''}); //  test using https://samltest.id/saml/idp
    const handleChange = (value) => {
        updateFormValues({...formValues, ...value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();       
        if (form.current.reportValidity()) {
            try {
                await dispatch(importSAML(formValues));
                return history.replace('./');
            } catch (err) {
                console.log(err)
            }
        }
    }

    return (
    <form ref={form} onSubmit={e => e.preventDefault()} noValidate>
            
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        >
            <h3>Import SAML IdP configuration from URL</h3>
        </Grid>


        <Grid container>
            <Grid item lg={12}>
                <DynamicField 
                    editMode={true}     
                    variant="outlined"
                    handleChange={handleChange}
                    fieldName='url'
                    fieldDisplay= 'Url'
                    fieldValue={formValues.url}
                    fieldType='text'                    
                />
            <Box mt={4} />
            </Grid>
            <Grid item xs>
            
                <Button
                    fullWidth
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={handleSubmit}
                > Save
                </Button> 

            </Grid>
            <Grid item xs>
                <Button
                    fullWidth
                    style={{ marginLeft: '1em' }}
                    variant="outlined"
                    component="label"
                    color="secondary"
                    onClick={handleCancel}
                > Cancel
                </Button> 
            </Grid>
        </Grid>

    </form>
    )
}

export {
    SAMLImport
}