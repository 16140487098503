import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Paper,
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DoneIcon from '@material-ui/icons/Done';

import District from 'components/District';
import Schools from 'components/Schools';
import Courses from 'components/Courses';
import Licenses from 'components/Licenses';
import OnboardingComplete from 'components/OnboardingComplete';

import { completeOnboarding } from 'actions';

const useStyles = makeStyles((theme) => ({
  instructions: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(3)
  },
  paperContainer: {
    marginBottom: '24px'
  },
  root: {
    width: '100%'
  },
  stepper: {
    display: 'flex',
    margin: theme.spacing(0, 1),
    padding: 0,
    width: '100%'
  },
  stepperContent: {
    marginTop: '24px'
  }
}));

const UserSetup = () => {
  const onboardingComplete = useSelector(
    (state) => state.district.onboardingComplete
  );
  const [activeStep, setActiveStep] = useState(0);
  const [disableNext, setDisableNext] = useState(true);

  const dispatch = useDispatch();
  const classes = useStyles();

  const steps = [
    'Enter Credentials',
    'Select Schools',
    'Select Courses',
    'Assign Licenses'
  ];

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleComplete = () => {
    if (!onboardingComplete) {
      dispatch(completeOnboarding());
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <District disableNext={setDisableNext} />;
      case 1:
        return <Schools disableNext={setDisableNext} />;
      case 2:
        return <Courses disableNext={setDisableNext} />;
      case 3:
        return <Licenses disableNext={setDisableNext} />;
      default:
        return 'Error';
    }
  };

  return (
    <div className={classes.root}>
      {activeStep === steps.length ? (
        <OnboardingComplete />
      ) : (
        <>
          <div className={classes.paperContainer}>
            <Paper className={classes.paper} elevation={2} square>
              <Box
                alignContent="center"
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  aria-label="Back"
                  className={classes.button}
                  color="default"
                  disabled={activeStep === 0 || activeStep === steps.length}
                  onClick={handleBack}
                  startIcon={<ArrowBackIosIcon />}
                  variant="contained"
                >
                  Back
                </Button>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  className={classes.stepper}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Button
                  aria-label="Next"
                  className={classes.button}
                  color="primary"
                  disabled={disableNext}
                  endIcon={
                    activeStep === steps.length - 1 ? (
                      <DoneIcon />
                    ) : (
                      <ArrowForwardIosIcon />
                    )
                  }
                  onClick={
                    activeStep === steps.length - 1
                      ? handleComplete
                      : handleNext
                  }
                  variant="contained"
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </Paper>
          </div>
          <div className={classes.stepperContent}>
            {getStepContent(activeStep)}
          </div>
        </>
      )}
    </div>
  );
};

export default UserSetup;
