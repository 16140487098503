import React from 'react';
import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';
import { 
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },
  paper: {    
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.success.main,
    color: 'white',
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingTitle: {
    marginRight: theme.spacing(0.5)
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },  
}));




function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};


const DashboardInfoBox = ({ content, headingIcon, headingTitle }) => {
  const classes = useStyles();

  return (

    <Paper className={classes.paper} elevation={5} square>
      <div className={classes.paperHeading}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >        
          <Typography
            className={classes.paperHeadingTitle}
            component="h3"
            variant="body1"
          >
            {headingTitle}
          </Typography>
          {headingIcon}
        </Grid>
      </div>
      <div className={classes.paperContent}>
      <List component="nav" aria-label={headingTitle}>
        {content && Array.isArray(content) && content.map((i, key) => (
          <RouterLink key={key} className={classes.link} to={i.link}>
            <ListItem  button>
          
              <ListItemIcon>
                {i.icon}
              </ListItemIcon>
              <ListItemText primary={i.display} />
              <ListItemSecondaryAction>
                      <ChevronRightIcon />                    
                  </ListItemSecondaryAction>                    
            </ListItem>
            </RouterLink>
        ))}
      </List>
      </div>
    </Paper>
  );
};

export {
  DashboardInfoBox
}; 
  
