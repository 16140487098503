import produce from 'immer';

import {
  CLEAR_IMPORTREPORT,
  FETCH_IMPORTREPORT_REQUEST,
  FETCH_IMPORTREPORT_SUCCESS,
  FETCH_IMPORTREPORT_FAILURE,
  FILTER_IMPORTREPORT
} from '../actions/actionTypes';

const totalsDefault = { studentCount: 0, teacherCount: 0 };
const initialState = { 
  isLoading: false,
  error: '',
  data: {
    date: '',
    daysSinceJob: '',
    jobType: '',
    licenseTotals: []
  },
  results: [],
  _results: [],
  totals: totalsDefault,
};

const importReportReducer = produce((draft, action) => {
  switch (action.type) {
    case CLEAR_IMPORTREPORT:
      return initialState;
    case FETCH_IMPORTREPORT_FAILURE:
      return { ...initialState, error: action.payload, isLoading: false };
    case FETCH_IMPORTREPORT_REQUEST:
      draft.isLoading = true;
      return;
    case FETCH_IMPORTREPORT_SUCCESS:
      const { results, ...data} = action.payload;
      let totals = totalsDefault;
      if (action.payload.hasOwnProperty('licenseTotals')) {
        totals = action.payload.licenseTotals.reduce((memo, total) => {
          if (total.type === 'teacher') {
            return {
              ...memo, 
              teacherCount: memo.teacherCount += total.count || 0, 
            }
           } else {
            return {
              ...memo, 
              studentCount: memo.studentCount += total.count || 0, 
            }
          }         
        }, { studentCount: 0, teacherCount: 0 });
      }
      
      return {...draft, isLoading: false, data, _results: results, results, totals }
    case FILTER_IMPORTREPORT:
      const searchTerm = action.payload;     
      try {

        const filteredResults = draft._results.reduce((memo, item) => {
          if (
            item.classId.includes(searchTerm) ||
            item.className.includes(searchTerm) ||
            item.courseName.includes(searchTerm) ||
            item.schoolName.includes(searchTerm) ||
            (item.teacherEmail && item.teacherEmail.includes(searchTerm)) ||
            item.eBook.includes(searchTerm)
            ) {
              return [...memo, item];
            } else {
              return memo;
            }
          }, [])
          return {...draft, results: filteredResults };
        } catch (err) {          
          return {...draft }
        }
    default:      
      return draft;
  }
}, initialState);

export default importReportReducer;
