import React, { useEffect, useState, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setImpersonate,
} from 'actions';
import {
  fetchDistrictListPaginated,
  fetchDistrictList
} from '../../reducers/adminDistrictsReducer';
import { 
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TablePagination,
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Hidden
} from '@material-ui/core';

import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import GetAppIcon from '@material-ui/icons/GetApp';

import { CSVLink } from "react-csv";


const useStyles = makeStyles((theme) => ({

  root: {
    
     width: '100%'
  },
  container: {
    width: '100%'
    
  },
  spinner: {
    minHeight: '50vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },  
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1),
  },
  table: {
    width: '100%'
  },
  link: {    
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  badStatus: {
    backgroundColor: theme.palette.warning.main
  },  

  searchTop: {
    clear: 'both',
    marginTop: '3em',
  }, 
  searchBottom: {
    clear: 'both',
    marginBottom: '.6em',
  },    
  hidden: {
    display: 'none',
  },
  
}));

const initialSearchState = { };
const searchReducer = (state, action) => {
  switch (action.type) {
    case 'INIT': 
      return initialSearchState;
    case 'REMOVE_FILTER':
      const { [action.payload]: value, ...newState } = state;
      return newState;
    case 'UPDATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const AdminDistricts = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);    
  const { districts, totalCount, isLoading } = useSelector((state) => state.districts, shallowEqual);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  
  const [fullDistrictList, setFullDistrictList] = useState([]);

  const [searchFilter, setSearchFilter] = useReducer(searchReducer, {});
  const history = useHistory();
  const classes = useStyles();
  const getList = () => {
    dispatch(fetchDistrictListPaginated({page, limit: rowsPerPage, ...searchFilter}));
  }

  const handleFilter = (filterItem) => {
    setSearchFilter({ type: 'UPDATE', payload: filterItem }); 
  };
  const unsetFilter = (filterItem) => {    
    setSearchFilter({ type: 'REMOVE_FILTER', payload: filterItem });
    // setSearchFilter({ type: 'UPDATE', payload: filterItem });    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // data = json2csv(arrayOfLiteralObjects);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, searchFilter]);

  const handleClick = (districtProfile) => {
    const { districtId,  } = districtProfile;        
    dispatch(setImpersonate(districtId));
    return history.replace('/dashboard');
  } 

  let csvLink;
  const download = () => {
    const myLink = csvLink;
    fetchDistrictList()
    .then(results => {
      setFullDistrictList(results.districts);
    }).finally(() => {
      myLink.link.click();
    })
  }


  return (
    <>
    <CSVLink
      data={fullDistrictList}
      filename="roster_districts.csv"
      className="hidden"
      ref={(r) => csvLink = r}                   
      target="_blank"/>

    <Grid
        container
        spacing={1}
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        // style={{ minHeight: '80vh' }}
        >
      <div className={classes.searchTop}></div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        >


      <TextField
        className={classes.paperInputField}
        name="districtName"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchSharpIcon />
            </InputAdornment>
          )
        }}
        onChange={(e) => handleFilter({[e.target.name]:e.target.value})}
        placeholder="Search"
        value={searchFilter.districtName || ""}
      />


    {searchFilter && Object.keys(searchFilter).length > 0 && Object.keys(searchFilter).filter(x => x !== 'districtName').map((myFilter, index) => (
        <Chip
          key={index}
          label={`${myFilter}: ${searchFilter[myFilter]}`}
          onDelete={() => unsetFilter(myFilter)}
        />

    ))}
    <Button 
      onClick={download}
      variant="contained"
      color="default"
      startIcon={<GetAppIcon />}
      >
      Download
    </Button>      
        
      
      </Grid>


      <div className={classes.searchBottom}></div>


    <div className={classes.root}>
    
      <TableContainer component={Paper} className={classes.table} >
      {isLoading && isAuthenticated ? (
        <div className={classes.spinner}>
           <CircularProgress color="secondary" size={100} />
         </div>
       ) : (  
        <>
        <TablePagination
                  component="div"
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalCount || 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
        />     
          <Table size="small" aria-label="district list">
            <TableHead>
              <TableRow>
              <Hidden xsDown>
                <TableCell>District ID</TableCell>
              </Hidden>
                <TableCell>District</TableCell>
                <TableCell>Mode</TableCell>
                <Hidden lgDown>
                  <TableCell>Platform</TableCell>
                  <TableCell align="center">Teachers</TableCell>
                  <TableCell align="center">Pool</TableCell>
                  <TableCell align="right">Last Job</TableCell>
                </Hidden>
                <Hidden mdDown>
                  <TableCell align="right">Schedule Status</TableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              {districts.map((row) => (
              <TableRow key={row._id} className={row.badState ? classes.badStatus : '' } >
                <Hidden xsDown>
                  <TableCell 
                    component="th"
                    scope="row"
                    onClick={() =>handleClick(row)}
                    className={classes.link}>
                      {row.districtId}
                  </TableCell>   
                </Hidden>         
                <TableCell 
                  onClick={()=>handleClick(row)}
                  className={classes.link}
                  >{row.districtName}</TableCell>

                {searchFilter && searchFilter.mode ? (
                    <TableCell>{row.mode}</TableCell>
                    ) : (
                    <TableCell
                      onClick={() => handleFilter({ 'mode': row.mode })}
                      className={classes.link}
                    >{row.mode}
                    </TableCell>
                  )}  

                <Hidden lgDown>
                  {searchFilter && searchFilter.rosterPlatform ? (
                    <TableCell>{row.rosterPlatform}</TableCell>
                    ) : (
                    <TableCell
                      onClick={() => handleFilter({ 'rosterPlatform': row.rosterPlatform })}
                      className={classes.link}
                    >{row.rosterPlatform}
                    </TableCell>
                  )}              
                  <TableCell align="center">{row.rosterTeachers ? 'yes' : 'no' }</TableCell>
                  <TableCell align="center">{row.licensePoolId ? 'yes' : 'no' }</TableCell>        
                  <TableCell align="right">{row.daysSinceLastJob ||  'n/a' }</TableCell>
                </Hidden>
                <Hidden mdDown>
                  {searchFilter && searchFilter.scheduleStatus ? (
                    <TableCell align="right">{row.scheduleStatus || 'NONE' }</TableCell>
                    ) : (
                    <TableCell
                      align="right"
                      onClick={() => handleFilter({ 'scheduleStatus': row.scheduleStatus })}
                      className={classes.link}
                    >{row.scheduleStatus || 'NONE' }
                    </TableCell>
                  )}  
                </Hidden>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </>
       )}
        </TableContainer>
    </div>

    
    </Grid>


  </>
    
  )};

export {
  AdminDistricts
};
