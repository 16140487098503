import produce from 'immer';
import moment from 'moment';
import {
  FETCH_JOBLIST_REQUEST,
  FETCH_JOBLIST_SUCCESS,
  FETCH_JOBLIST_FAILURE,
} from '../actions/actionTypes';

const initialState = { 
  isLoading: false,
  data: []
};

const importReportReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_JOBLIST_FAILURE:
      return { data: [], isLoading: false };
    case FETCH_JOBLIST_REQUEST:
      return { data: [], isLoading: true };
    case FETCH_JOBLIST_SUCCESS:
      const data = action.payload.map(x => ({...x, date: moment.utc(x.date).local().format('MMM DD, YYYY hh:mm A')} ))
      return {...draft, isLoading: false, data}
    default:
      return draft;
  }
}, initialState);

export default importReportReducer;
