import axios from 'axios';
import { SSO_REDIRECT } from 'env';
const getInstance = () => {
  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)cpm-sso-token\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
  
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Impersonate": sessionStorage.getItem("X-Impersonate")
    }
  });
  instance.interceptors.response.use(response=>response,
    err=>{      
      if (err.response && err.response.status === 401) {        
         window.location.href = SSO_REDIRECT;
      } else if (err.response && err.response.data) {
        throw err;
      } else {
        throw new Error("An unkown error has occurred");
      }
    }
    );
  return instance;
}

const axiosWithAuth = () => {
  const instance = getInstance();
  return instance;  
};


const axiosFile = () => {
  const instance = getInstance();
  instance.headers = {...instance.headers, 'content-type': 'multipart/form-data' }
  return instance;  
};




export { 
  axiosWithAuth,
  axiosFile
};
