import { axiosWithAuth } from 'utils/axiosWithAuth';
import { API_URL } from 'env';
import {
  FETCH_EWORKSPACE_REQUEST,
  FETCH_EWORKSPACE_SUCCESS,
  FETCH_EWORKSPACE_FAILURE,
  ENABLE_EWORKSPACETEACHER_REQUEST,
  ENABLE_EWORKSPACETEACHER_SUCCESS,
  DISABLE_EWORKSPACETEACHER_REQUEST,
  DISABLE_EWORKSPACETEACHER_SUCCESS,
} from "./actionTypes";

export const fetchEworkspaceTeachers = ( schoolId ) => async (dispatch) => {   
    dispatch({ type: FETCH_EWORKSPACE_REQUEST });
    const myBody = schoolId ? { schoolId } : {}; 

    try {
      const url = `${API_URL}/api/v1/district/eworkspace/teachers`
      const response = await axiosWithAuth().post(url, myBody);
      dispatch({ type: FETCH_EWORKSPACE_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: FETCH_EWORKSPACE_FAILURE, payload: e.message });
    }
  };

  export const enableEworkspace = ( user ) => async (dispatch) => {    
    dispatch({ type: ENABLE_EWORKSPACETEACHER_REQUEST });
    try {
      const url = `${API_URL}/api/v1/district/eworkspace/teacher`
      const response = await axiosWithAuth().post(url, user);
      dispatch({ type: ENABLE_EWORKSPACETEACHER_SUCCESS, payload: response.data });
      
    } catch (err) {
      console.log(err.message)
      // dispatch({ type: FETCH_EWORKSPACE_FAILURE, payload: e.message });
    }
  };

  export const disableEworkspace = ( user ) => async (dispatch) => { 
    dispatch({ type: DISABLE_EWORKSPACETEACHER_REQUEST });   
    try {
      const { _id } = user;
      const url = `${API_URL}/api/v1/district/eworkspace/teacher/${_id}`
      await axiosWithAuth().delete(url);
      dispatch({ type: DISABLE_EWORKSPACETEACHER_SUCCESS, payload: user });
      
    } catch (err) {
      // alert('err')
      console.log(err.message)
      // dispatch({ type: FETCH_EWORKSPACE_FAILURE, payload: e.message });
    }
  };  


