import { axiosWithAuth } from 'utils/axiosWithAuth';
import { API_URL } from 'env';

import {
    FETCH_PUSHREPORT_FAILURE,
    FETCH_PUSHREPORT_REQUEST,
    FETCH_PUSHREPORT_SUCCESS,

    FETCH_IMPORTREPORT_REQUEST,
    FETCH_IMPORTREPORT_SUCCESS,
    FETCH_IMPORTREPORT_FAILURE,
    FILTER_IMPORTREPORT,

    FETCH_JOBLIST_REQUEST,
    FETCH_JOBLIST_SUCCESS,
    FETCH_JOBLIST_FAILURE,

} from './actionTypes';


// Reports
export const fetchPushReport = (jobId) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_PUSHREPORT_REQUEST });
      const response = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/reports/push/job/${jobId}`
      );
      dispatch({ type: FETCH_PUSHREPORT_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: FETCH_PUSHREPORT_FAILURE, payload: e.message });
    }
  };
  
  export const fetchLatestPushReport = () => async (dispatch) => {
    try {
      dispatch({ type: FETCH_PUSHREPORT_REQUEST });
      const response = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/reports/push/last`
      );
      dispatch({ type: FETCH_PUSHREPORT_SUCCESS, payload: response.data });
    } catch (e) {
      console.log(e)
      dispatch({ type: FETCH_IMPORTREPORT_FAILURE, payload: e.message });
    }
  };
  
  
  
  export const fetchJobList = (jobType) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_JOBLIST_REQUEST });
      const response = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/reports/${jobType}/list`
      );
      dispatch({ type: FETCH_JOBLIST_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: FETCH_JOBLIST_FAILURE });
    }
  };
  
  export const fetchImportReport = (jobId) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_IMPORTREPORT_REQUEST });
      const response = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/reports/import/job/${jobId}`
      );
      dispatch({ type: FETCH_IMPORTREPORT_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: FETCH_IMPORTREPORT_FAILURE, payload: e.message});
    }
  };
  
  export const fetchLatestImportReport = () => async (dispatch) => {
    try {
      dispatch({ type: FETCH_IMPORTREPORT_REQUEST });
      const response = await axiosWithAuth().get(
        `${API_URL}/api/v1/district/reports/import/last`
      );
      dispatch({ type: FETCH_IMPORTREPORT_SUCCESS, payload: response.data });
    } catch (e) {
      
      dispatch({ type: FETCH_IMPORTREPORT_FAILURE, payload: e.message });
    }
  };

  export const filterImportReport = (searchTerm) => (dispatch) => {
    
      // dispatch({ type: FETCH_IMPORTREPORT_REQUEST });    
      dispatch({ type: FILTER_IMPORTREPORT, payload: searchTerm });    
  };


