import { axiosWithAuth } from 'utils/axiosWithAuth';
import { API_URL } from 'env';
import {
  FETCH_EXCLUDED_REQUEST,
  FETCH_EXCLUDED_SUCCESS,
  FETCH_EXCLUDED_FAILURE,
  ADD_EXCLUDED_SUCCESS,
  DELETE_EXCLUDED_SUCCESS
} from "./actionTypes";

export const fetchExcluded = ( EXCLUDED ) => async (dispatch) => {   
    dispatch({ type: FETCH_EXCLUDED_REQUEST });
    const myBody = EXCLUDED || {};
    try {
      const url = `${API_URL}/api/v1/district/reports/push/mock/excluded`
      const response = await axiosWithAuth().post(url, myBody);
      dispatch({ type: FETCH_EXCLUDED_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: FETCH_EXCLUDED_FAILURE, payload: e.message });
    }
  };
  export const addExclusionCriteria = ( criteria ) => async (dispatch) => {   
    // dispatch({ type: FETCH_EXCLUDED_REQUEST });    
    try {
      const url = `${API_URL}/api/v1/district/exclusion`
      const response = await axiosWithAuth().post(url, criteria);
      dispatch({ type: ADD_EXCLUDED_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: FETCH_EXCLUDED_FAILURE, payload: e.message });
    }
  };
  export const deleteExclusionCriteria = ( exclusionId ) => async (dispatch) => {   

    // dispatch({ type: FETCH_EXCLUDED_REQUEST });    
    try {
      const url = `${API_URL}/api/v1/district/exclusion/${exclusionId}`
      await axiosWithAuth().delete(url);
      dispatch({ type: DELETE_EXCLUDED_SUCCESS, payload: exclusionId });
    } catch (e) {
      dispatch({ type: FETCH_EXCLUDED_FAILURE, payload: exclusionId });
    }
  };    