import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { 
  Box,
  Button,
  Divider,
  Grid,
  Icon
} from '@material-ui/core';


import { fetchLtiPlatformList, updateLTIPlatform, deleteLTIPlatform,  seedLtiPlatform, removeSeedLtiPlatform, saveAddLTIPlatform, linkLTIPlatform } from "../../../reducers/ltiAdvantageDistrictReducer";
import { LTIAvantagePlatform } from "./LTIAvantagePlatform";



const LTIAvantage = () => {
  
  const dispatch = useDispatch();

  const { platforms } = useSelector((state) => state.ltiAdvantage, shallowEqual);
  const [isDynamicRegistration, updateisDynamicRegistration] = useState(false);

  useEffect(() => {
    dispatch(fetchLtiPlatformList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleDelete = (platformId) => {
    dispatch(deleteLTIPlatform(platformId))
  }

  const handleUpdate = (platformId, platform) => {
    dispatch(updateLTIPlatform({platformId, platform}))
  }

  const handleAdd = (platform) => {
    dispatch(saveAddLTIPlatform({platform}))
  }

  const handleSeedPlatform = (isDynamic = true) => {
    updateisDynamicRegistration(isDynamic);
    dispatch(seedLtiPlatform())
  }

  const handleCancelAdd = () => {
    updateisDynamicRegistration(false);
    dispatch(removeSeedLtiPlatform())
  }

  const handleLink = (platformId) => {    
    dispatch(linkLTIPlatform(platformId))
  }  
  

  return (
    <Box>
      
      <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      >        
      <h3>LTI Advantage (LTI 1.3)</h3>
        <div>

          <Button
          variant="contained"
          color="primary"
          className='button-list'
          startIcon={<Icon>add</Icon>}
          onClick={() => handleSeedPlatform(false)}
          >Manual Registration</Button>

          <Button
          variant="contained"
          color="primary"
          className='button-list'
          startIcon={<Icon>add</Icon>}
          onClick={() => handleSeedPlatform(true)}
          >Dynamic Registration</Button>        
        </div>
      </Grid>

      <Divider />

      <>
        {Array.isArray(platforms) && platforms.length > 0 && platforms.map((platform, index) => (
          <Box key={index} pb={3} >
              {platform.id ? (
                  <LTIAvantagePlatform key={index} handleDelete={() => handleDelete(platform.id)} handleSave={(formData) => handleUpdate(platform.id, formData)} platform={platform}/>
                ) : (
                  <LTIAvantagePlatform 
                    handleSave={handleAdd}
                    handleCancel={handleCancelAdd}
                    handleLink={handleLink}
                    isDynamicRegistration={isDynamicRegistration}
                    platform={platform}/>
              )}  
          </Box>
        ))}   
      </>
    
    </Box>   
          
    )
}

export {
  LTIAvantage
}