import produce from 'immer';

import {
  FETCH_LICENSEPOOLS_SUCCESS,
} from '../actions/actionTypes';
// {  groupName: null, id: null, isRostered: false }
const initialState = [  ];

const licensePoolReducer = produce((draft, action) => {
  switch (action.type) {   
    case FETCH_LICENSEPOOLS_SUCCESS:      
      return action.payload;
    default:
      return draft;
  }
}, initialState);

export default licensePoolReducer;
