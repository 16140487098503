import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination    
} from '@material-ui/core';


import {
    Paper,
    CircularProgress,
    Button,
    Tooltip
} from '@material-ui/core';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { fetchExcluded, deleteExclusionCriteria } from '../../actions'
import { ExclusionEntry } from "./";
const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  navigationButton: {
   marginBottom: theme.spacing(3)
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },  
  link: {
    color: theme.palette.secondary.main,
  }
}),
);

const NavAction = ({exclusionId}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory(); 
    const handleDelete = () => {
        dispatch(deleteExclusionCriteria(exclusionId));
        history.push('/exclusions');
    };
        
    return (      
      <Tooltip title="Delete">
        <Button 
          className={classes.link}
          startIcon={<DeleteForeverIcon />}
          onClick={handleDelete}
          
          aria-label="delete">
          Delete
        </Button>
      </Tooltip>
    )
}

const ExclusionResults = () => {
    const classes = useStyles();
    const history = useHistory(); 
    const dispatch = useDispatch();
    const { exclusionId } = useParams();
    // State

    const exclusion = useSelector((state) => state.district.exclusions).find(x => x._id === exclusionId) || {_id: "", items: []};
    const { excluded, isLoading } = useSelector((state) => state.excludedRecords);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 25));
      console.log(excluded)
      setPage(0);
    };

   const handleGoToExclusions = () => {
      history.push('/exclusions');
   }

    useEffect(() => {         
      if(exclusion) {
        dispatch(fetchExcluded(exclusion.items));
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [exclusionId]);

    return (
        <div className={classes.root}>        

            <h2>Excluded Records</h2>
            <Button
                    aria-label="Back"
                    className={classes.navigationButton}
                    color="default"
                    onClick={handleGoToExclusions}
                    startIcon={<ArrowBackIosIcon />}
                    variant="contained"
                >
                    Back
                </Button>


            <ExclusionEntry exclusion={exclusion} navAction={<NavAction exclusionId={exclusion._id} />} />

         {isLoading ? (
            <div className={classes.spinner}>
              <CircularProgress color="secondary" size={100} />
            </div>
          ) : (

         <TableContainer component={Paper}>     
            <TablePagination
              component="div"
              rowsPerPageOptions={[25, 50, 100, 500]}
              count={excluded.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />                       
            <Table className={classes.table} size="small" aria-label="staged licenses table">
              <TableHead>
                <TableRow>
                  <TableCell>Role</TableCell>
                  <TableCell>School</TableCell>
                  <TableCell>Course</TableCell>          
                  <TableCell>Roster Id</TableCell>
                  <TableCell>First</TableCell>
                  <TableCell>Last</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

              {excluded && 
              excluded.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map( (row, index) => (
                <TableRow key={`enrollment-${index}-key`}>
                  <TableCell>{row.schoolRole}</TableCell>
                  <TableCell>{row.schoolName}</TableCell>
                  <TableCell>{row.courseName}</TableCell>        
                  <TableCell>{row.rosterSourceId}</TableCell>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.email}</TableCell>     
                </TableRow>              
              ))}

              </TableBody>
            </Table>
          
          </TableContainer>

          )}         


        </div>
    )
}

export { ExclusionResults };