import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';


import {
  fetchLtiPlatformList,
} from '../../reducers/ltiAdvantageAdminReducer';
import { 
  Table,
  TablePagination,

  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const columns = [
  { id: 'districtId', label: 'District' },
  { id: 'name', label: 'Name', minWidth: 100 },
  {
    id: 'clientId',
    label: 'Client Id',
    minWidth: 30,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'url',
    label: 'Platform URL',
    minWidth: 170,
    align: 'left'
  },  
  {
    id: 'active',
    label: 'Status',
    minWidth: 30,
    align: 'left'
  },
];



const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});


const AdminLTI = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const { platforms } = useSelector((state) => state.ltiAdvantageAdmin, shallowEqual);
  const [isMapped, updateIsMapped] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  
  const getList = () => {
    dispatch(fetchLtiPlatformList(isMapped));
  }

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapped])

const handleMappedChange = () => {
  updateIsMapped(!isMapped);
}

    return (
      <>



    <ToggleButtonGroup
      value={isMapped}
      exclusive
      onChange={handleMappedChange}
      aria-label="text alignment"
    >
      <ToggleButton value={true} aria-label="left aligned">
        Mapped
      </ToggleButton>
      <ToggleButton value={false} aria-label="centered">
        UnMapped
      </ToggleButton>
      
    </ToggleButtonGroup>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {platforms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={platforms.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    
  )};

export {
  AdminLTI
};
