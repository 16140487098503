import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SSO_REDIRECT } from "../../env";
import {
  createTheme,
  makeStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors/pink';
import { CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';

import CoursesStep from 'containers/CoursesStep';
import Dashboard from 'containers/Dashboard';
import DistrictStep from 'containers/DistrictStep';
import LicensesStep from 'containers/LicensesStep';
import { SSO } from 'containers/SSO';
import { LTI } from 'containers/LTI';
import { Login, LoginRestricted } from 'components/Login';

import { Navigation } from 'components/Navigation';
import {  PrivateAdminRoute } from 'components/PrivateRoutes';

import SchoolsStep from 'containers/SchoolsStep';
import UserSetup from 'containers/UserSetup';
import StickyStep from 'containers/StickyStep';
import { Exclusions, ExclusionResults } from 'components/Exclusions';

import { ImportList, LicenseSyncList } from 'containers/Reports';
import { Admin } from 'components/Admin';
import { EWorkspace } from 'components/EWorkspace';
import { RosterChecklist } from 'components/RosterChecklist';
import { ScheduleState } from '../IntegrationSettings/ScheduleState';
import {getDistrictProfile, setUser, validateUser} from 'actions';

import { CourseExplorer } from '../../components/Courses/CourseExplorer';
import { LicensePool } from '../../components/LicensePool';
import { RosterTeachers } from '../../components/RosterTeachers';
import { CPMCertify } from '../../components/CPMCertify';

import 'react-toastify/dist/ReactToastify.min.css';

import './App.css';
import { BackToButton } from 'components/Common/BackToButton';

import { registerRefreshTimeout, getSessionType, refresh } from '@cpmdevops/cpm-auth';

const theme = createTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0
      }
    },
    MuiPopover: {
      root: {
        zIndex: '10000 !important'
      }
    },
    MuiAutocomplete: {
      popper: {
        zIndex: '10000 !important'
      }
    }
  },

  palette: {
    primary: { main: '#1F3466', light: "#4f5d94", dark: "#000e3b" },
    accent: { light: '#ff79b0', main: '#ff4081', dark: '#c60055'},
    warning: { light: '#F9F1F0', main: '#F8AFA6', dark: '#F79489'  },
    danger: { light: '#ea5252', main: '#c80808', dark: '#880808'  },
    // secondary: { light: "#ff79b0", main: '#ff4081', dark: '#c60055' },
    secondary: pink,
    info: { light: '#EFEFEF', main: '#737d87', dark: '#424242' },
    success: { light: "#60ad5e", main: "#2e7d32", dark: "#005005" }
  }
});

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: '#f5f6fa'
    },
    palette: {
      primary: {
        main: 'rgb(31, 52, 102)',
        secondary: '#106c43'
      }
    }
  },
  content: {
    flex: 1
  },
  offset: theme.mixins.toolbar,
  root: {
    display: 'flex'
  },
  routeContent: {
    display: 'flex',
    padding: theme.spacing(3),
    position: 'relative'
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center'
  },
  tooltip: {
    backgroundColor: theme.palette.success.main,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  }    
}));

const App = () => {
  const { districtId } = useSelector((state) => state.district);

   
  const { isAuthenticated, triggerNavToAdminDashboard, unauthorizedUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const updateUserInRedux = () => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)cpm-sso-token\s*=\s*([^;]*).*$)|^.*$/,'$1');
    console.log('Calling setUser with token', {token})
    if(token && token.length) {
      dispatch(setUser(token));
    }
  };

  const tryToRefresh = async () => {
      console.log('Refreshing token');
      try{
        await refresh(SSO_REDIRECT, false, true);
        updateUserInRedux();
      } catch (err) {
        console.log('Error refreshing token', {err});
        history.push('/login');
      }
  };

  useEffect(() => {
    dispatch(validateUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      if (districtId) {
         dispatch(getDistrictProfile());
      } else if (triggerNavToAdminDashboard) {
        history.push('/admin');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [districtId, triggerNavToAdminDashboard]);


  useEffect(() => {
    if (!isAuthenticated) {
      const sessionType = getSessionType();
      if (sessionType === 'refresh') {
            tryToRefresh();
      } else {
        history.push('/login');
      }
    }  else if (unauthorizedUser) {
      history.push('/login/restricted');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unauthorizedUser, isAuthenticated]);

  useEffect(() => {
    registerRefreshTimeout({
      ssoUrl: SSO_REDIRECT,
      redirect: false,
      callback: updateUserInRedux
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <div className={classes.overlay} />          
          {/* CSS reset */}
          <CssBaseline />
          {isAuthenticated && <Navigation />}
          <main className={classes.content}>
            {/* Prevent route content from appearing behind app bar */}
            <div className={classes.offset} />
            <div className={classes.routeContent}>
              <ToastContainer
                autoClose={3000}
                closeOnClick
                draggable
                hideProgressBar={false}
                newestOnTop={false}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                position="top-left"
                rtl={false}
              />
              <Switch>
                <PrivateAdminRoute path="/admin" component={Admin} />
                <Route exact path="/" component={Dashboard} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/checklist" component={RosterChecklist} />
                <Route path="/courses" component={CoursesStep} />
                <Route path="/explorer" component={CourseExplorer} />
                <Route path="/licenses" component={LicensesStep} />
                <Route path="/licensepool" component={() => <BackToButton><LicensePool /></BackToButton>} />
                <Route path="/certify" component={() => <BackToButton><CPMCertify /></BackToButton>} />
                {/* <Route path="/schedule" component={ScheduleState} /> */}
                <Route path="/schedule" component={() => <BackToButton><ScheduleState /></BackToButton>} />
                <Route path="/profile" component={DistrictStep} />
                <Route path="/schools" component={SchoolsStep} />

                {/* <Route path="/teachers" component={RosterTeachersStep} /> */}
                <Route path="/teachers" component={() => <BackToButton><RosterTeachers /></BackToButton>} />
                <Route path="/setup" component={UserSetup} />
                <Route path="/sticky/teacher" component={() =><StickyStep role='teacher' />} />
                <Route exact path="/import" component={ImportList} />
                <Route path="/import/:jobId" component={ImportList} />
                <Route path="/sync/:jobId" component={LicenseSyncList} />
                <Route path="/eworkspace" component={EWorkspace} />
                <Route exact path="/exclusions" component={Exclusions} />
                <Route path="/exclusions/:exclusionId" component={ExclusionResults} />

                <Route path="/sso" component={SSO} />
                {/* <Route path="/sso/:ssoProvider" component={SSOProvider} />      */}
                <Route exact path="/lti" component={LTI} />

                <Route exact path="/login" component={Login} />
                <Route path="/login/restricted" component={LoginRestricted} />
                <Route component={Login} />
              </Switch>
            </div>
          </main>
        </div>

    </ThemeProvider>
  );
};

export default App;
