import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    TextField,
    InputAdornment,   
    IconButton,
    
} from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';

const useStyles = makeStyles((theme) => ({
    myButton: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.accent.main
    },
}));

const SearchBar = ({handleFilter, handleDownload}) => {
    const classes = useStyles();    
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignContent="center"
        >
        <TextField
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <SearchSharpIcon />
                </InputAdornment>
            )
            }}
            onChange={handleFilter}
            placeholder="Search"
        />
        {handleDownload && (
            <IconButton
                className={classes.myButton}
                onClick={handleDownload}
                aria-label="download">          
            <GetAppIcon />
            </IconButton>
            )}
        </Box>
    );
}

export default SearchBar;