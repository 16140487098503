
const clientId = {
    fieldName: 'clientId',
    fieldDisplay: 'Client ID',
    fieldValue: '',
    fieldType: 'text',    
};


const platformName = {
    fieldName: 'name',
    fieldDisplay: 'Display Name',
    fieldValue: '',
    fieldType: 'text',      
};

const baseUrl = {
    fieldName: 'baseUrl',
    fieldDisplay: 'Base URL', 
    fieldValue: '',
    fieldType: 'url',
    
}

const platformUrl = {
    fieldName: 'url',
    fieldDisplay: 'Platform URL', 
    fieldValue: '',
    fieldType: 'url',
    
}

const formFields = [clientId, platformName,
    platformUrl,
    {
        fieldName: 'accesstokenEndpoint',
        fieldDisplay: 'Access Token Endpoint',
        fieldValue: '',
        fieldType: 'url',
    },    
    {
        fieldName: 'authenticationEndpoint',
        fieldDisplay: 'Auth Endpoint',
        fieldValue: '',
        fieldType: 'url',
    },    
    {
        fieldName: 'key',
        fieldDisplay: 'Auth Config URL Key',
        fieldValue: '',
        fieldType: 'url',
    },    
    {
        fieldName: 'method',
        fieldDisplay: 'Auth Config Method',
        fieldValue: 'JWK_SET',
        fieldType: 'selectObject',        
        options: [
            {
                fieldDisplay: "JWK_SET",
                fieldValue: 'JWK_SET'
            },
            {
                fieldDisplay: "JWK_KEY",
                fieldValue: 'JWK_KEY'
            },
            {
                fieldDisplay: "RSA_KEY",
                fieldValue: 'RSA_KEY'
            }
        ]
    },
    // {
    //     fieldName: 'publicKey',
    //     fieldDisplay: 'Public Key',
    //     fieldValue: '',
    //     fieldType: 'textArea',
    // },    
  ];


  const ltiProvider = {
        fieldName: 'ltiProvider',
        fieldDisplay: 'LMS Provider',
        fieldValue: '',
        fieldType: 'selectObject',        
        options: [
            {
                fieldDisplay: "Canvas",
                fieldValue: "canvas"
            },
            {
                fieldDisplay: "Moodle",
                fieldValue: "moodle"
            },
            {
                fieldDisplay: "Schoology",
                fieldValue: "schoology"
            },
            {
                fieldDisplay: "BrightSpace",
                fieldValue: "brightspace"
            }
        ]    
  }

  const dynamicRegistrationFields = {
    'moodle': [
        clientId
    ],
    'canvas': [
        clientId,
        platformName,
        baseUrl         
    ],
    'schoology': [
        clientId,
        platformName,
    ],
    'brightspace': [
        clientId,
        baseUrl
    ]
}


  export {
    formFields, ltiProvider, dynamicRegistrationFields
  }