import React from 'react';

import { Link as RouterLink,  useRouteMatch } from 'react-router-dom';
import { 
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

  
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.info.dark,
    },  
  }));

const SSOProviders = ({ ssoProfiles}) => {
    const classes = useStyles();
    const {
        path,
        // url
      } = useRouteMatch();

    return (
    <List component="nav" aria-label='List of SSO Providers'>
        {ssoProfiles && Object.keys(ssoProfiles).map((key) => (
        <RouterLink key={key} className={classes.link} to={`${path}/${key}`}>
                <ListItem  button>
                    <ListItemIcon>
                    {ssoProfiles[key].isActive ? (
                        <Icon color="secondary">toggle_on</Icon>
                        ) : (
                            <Icon>toggle_off</Icon>
                    )}
                    </ListItemIcon>
                    <ListItemText primary={ssoProfiles[key].displayName} />
                    <ListItemSecondaryAction>                 
                        <ChevronRightIcon />                 
                    </ListItemSecondaryAction>
                </ListItem>
            <Divider />
        </RouterLink>
        ))}
    </List>
    )
}

export {
    SSOProviders
};