import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Typography
} from '@material-ui/core';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BookIcon from '@material-ui/icons/Book';
import EmailIcon from '@material-ui/icons/Email';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  avatar: {
    backgroundColor: theme.palette.warning.main,    
  },
  paper: {    
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.info.light,
    padding: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.info.light,    
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingTitle: {
    marginRight: theme.spacing(2)
  },
  viewButton: {    
    color:theme.palette.primary.dark
  },
  link: {
    textDecoration: 'none',
    color: '#fff'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },   
}));



const RenderSimpleObjectList = ({ items, myKey, avatarImage }) => {
  const classes = useStyles();
    return  (
      <List dense className={classes.root}>
      {items && Object.keys(items).map((item, index) => {
        return (
          <ListItem key={`${index}-${myKey}`} divider={true} button>
              <ListItemAvatar>              
                <Avatar
                  className={classes.avatar}
                  alt={`Avatar n°${item}`}
                >{avatarImage}</Avatar>
              </ListItemAvatar>       
              <ListItemText id={item} primary={item} />
              <ListItemSecondaryAction>
                {items[item]}
              </ListItemSecondaryAction>
          </ListItem>                   
        )
      })}
      </List>
    )
}

const RenderSimpleArrayList = ({ items, myKey, avatarImage }) => {
  const classes = useStyles();
  return  (
    <List dense className={classes.root}>
      {items && items.map((item) => {
        return (
          <ListItem key={`${myKey}-${item}`} divider={true} button>
              <ListItemAvatar>              
                <Avatar
                  className={classes.avatar}
                  alt={`Avatar n°${item}`}
                >{avatarImage}</Avatar>
              </ListItemAvatar>       
              <ListItemText id={item} primary={item} />
              <ListItemSecondaryAction>
                {items[item]}
              </ListItemSecondaryAction>
          </ListItem>          
        )
      })}
    </List>
    )
}

const getTotalValue = (myObject) => {
  return Object.values(myObject).reduce((total, item) => {
      return total += item
   }, 0);
}

const SyncWarningsReport = (  ) => {
  const classes = useStyles();
  const { data: report } = useSelector((state) => state.pushReport, shallowEqual);

  return (
    <>
    {report && report.warningsReport && report.warningsReport.overages && getTotalValue(report.warningsReport.overages) > 0 && (
      <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Overages-content"
          id="Overages-header"
          >
          <Typography className={classes.heading}>Overages</Typography>
          </AccordionSummary>
          <AccordionDetails>             
              <RenderSimpleObjectList myKey='overages' items={report.warningsReport.overages} avatarImage={<BookIcon />}/>              
          </AccordionDetails>
      </Accordion>
      )}

      {report && report.warningsReport && report.warningsReport.noEmail && getTotalValue(report.warningsReport.noEmail) > 0 && (
      <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="noemail-content"
          id="noemail-header"
          >
          <Typography className={classes.heading}>Missing Email ({getTotalValue(report.warningsReport.noEmail)})</Typography>
          </AccordionSummary>
          <AccordionDetails>
              <RenderSimpleObjectList items={report.warningsReport.noEmail} myKey='email'  avatarImage={<EmailIcon />}/>
          </AccordionDetails>
      </Accordion>
      )}

      {report && report.warningsReport && report.warningsReport.noTeacher && getTotalValue(report.warningsReport.noTeacher) > 0 && (
      <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="noteacher-content"
          id="noteacher-header"
          >
          <Typography className={classes.heading}>Missing Teacher ({getTotalValue(report.warningsReport.noTeacher)})</Typography>
          </AccordionSummary>
          <AccordionDetails>
              <RenderSimpleObjectList items={report.warningsReport.noTeacher} myKey='teacher' avatarImage={<EmailIcon />}/>
          </AccordionDetails>
      </Accordion>
      )}      

      {report && report.warningsReport && report.warningsReport.ignored && getTotalValue(report.warningsReport.ignored) > 0 && (
      <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ignored-content"
            id="ignored-header"
          >
          <Typography className={classes.heading}>Unable to process ({getTotalValue(report.warningsReport.ignored)})</Typography>
          </AccordionSummary>
          <AccordionDetails>                          
              <RenderSimpleObjectList myKey='ignored' items={report.warningsReport.ignored} avatarImage={<ErrorOutlineOutlinedIcon />}/>   
          </AccordionDetails>
      </Accordion>
      )}

      {report && report.warningsReport && report.warningsReport.other && report.warningsReport.other.length > 0 && (
      <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="other-content"
          id="other-header"
          >
          <Typography className={classes.heading}>({report.warningsReport.other.length}) Other Errors</Typography>

          </AccordionSummary>
          <AccordionDetails>              
              <RenderSimpleArrayList myKey='other' items={report.warningsReport.other} avatarImage={<ErrorOutlineOutlinedIcon />}/>
          </AccordionDetails>
      </Accordion>
      )}
    </>

  );
};
export {
  SyncWarningsReport
};

