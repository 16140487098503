import React, { useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Paper,
    Table,
    CircularProgress,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Hidden,
    Modal,
  } from '@material-ui/core';

import { getCourseClasses } from '../../utils/statelessCalls';
import { ClassEnrollment } from '../Jobs';

const useStyles = makeStyles((theme) => ({

    root: {
      
       width: '100%'
    },
    container: {
      width: '100%'
      
    },
    spinner: {
      minHeight: '50vh',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(6)
    },  
    modalWindow: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
      },
      modal: {
        display: 'flex',
        border: 0,
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
        outline: 0,
      },   
  }));


  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  
  function getModalStyle() {
    const top = 10 + rand();   
    return {
      outline: 0,
      top: `${top}%`,
    };
  }

  
const CourseClasses = ({courseId}) => {
    const classes = useStyles();
    const [isLoading, updateIsLoading] = useState(false);
    const [courseClasses, updateCourseClasses] = useState([]);
    const [activeClass, updateActiveClass] = useState("");
    
    const [modalStyle] = useState(getModalStyle);
    const [modalOpen, updateModalOpen] = useState(false);

    const closeModal = () => {
        updateModalOpen(false);
      }


      const fetchClassRoster = async (item) => {
        const { classId, schoolId, teacherEmail, className, activeTerm: { startDate, endDate, termId, termName } } = item;
        updateActiveClass({ classId, schoolId, teacherEmail, className, startDate, endDate, termId, termName });
        updateModalOpen(true);
      };

    useEffect(() => {
        if (courseId) {                    
            updateIsLoading(true);
            updateCourseClasses([]);
            getCourseClasses(courseId)
            .then((myClasses) => {
                updateCourseClasses(myClasses);
                updateIsLoading(false);
            }).catch((err) => {
                console.log(err.message);
                updateIsLoading(false);
            })
        }

      }, [courseId]);


      return (
<>
    {modalOpen && activeClass && activeClass.classId && (
            <Modal
                open={modalOpen}
                className={classes.modal}
                aria-labelledby="classroom report"
                aria-describedby="List of users returned by SIS"
            >
            <div style={modalStyle} className={classes.modalWindow}>
              <ClassEnrollment item={activeClass} handleClick={closeModal} />
            </div>                            
            </Modal>
      )}
        <div className={classes.root}>
    
      


        <TableContainer component={Paper} className={classes.table} >
        {isLoading ? (
          <div className={classes.spinner}>
             <CircularProgress color="secondary" size={100} />
           </div>
         ) : (  
          <>   
            <Table size="small" aria-label="course classes">
              <TableHead>
                <TableRow>
                  <Hidden xsDown>
                    <TableCell component="th">School</TableCell>
                  </Hidden>
                  <TableCell component="th">Class</TableCell> 
                  <Hidden xsDown>
                  <Hidden smDown>
                    <TableCell component="th">Class ID</TableCell>
                  </Hidden>
                    <TableCell component="th">Term</TableCell>
                    <TableCell component="th">Start Date</TableCell>
                  </Hidden>
                  <TableCell component="th">End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(courseClasses) && courseClasses.map((row) => (
                <TableRow key={row.classId} className="clickable" onClick={() => fetchClassRoster(row)}>
                  <Hidden xsDown>
                    <TableCell className='inactiveClass'>{row.schoolName}</TableCell>   
                  </Hidden>
                  <TableCell>{row.className}</TableCell>
                  <Hidden smDown>
                    <TableCell>{row.classId}</TableCell>
                  </Hidden>
                  <Hidden xsDown>                    
                    <TableCell>{row && row.activeTerm && row.activeTerm.termName}</TableCell>
                    <TableCell>{row && row.activeTerm && row.activeTerm.startDate}</TableCell>
                  </Hidden>
                  <TableCell>{row && row.activeTerm && row.activeTerm.endDate}</TableCell>    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </>
         )}
          </TableContainer>
      </div>
  </>
      )
}

export { 
    CourseClasses
};