import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    activeLink: {    
        cursor: 'pointer',
        color: theme.palette.primary.dark,
        textDecoration: 'underline'
      },
      inactiveLink: {    
        cursor: 'pointer',
        textDecoration: 'none'
      }     
}));


const ToggleText = ({items=[], activeIndex=0, handleClick}) => {
  const classes = useStyles();
    return (
        <Box display="flex" justifyContent="flex-end">
          {items.map((item, itemIndex) => (
            <React.Fragment key={itemIndex}>
        
            <Box >
              <Typography onClick={() => handleClick(itemIndex)} 
                  className={activeIndex === itemIndex ? classes.activeLink : classes.inactiveLink}                   
                  variant="overline" 
                  display="block"
                  color="primary"
                  gutterBottom>
                {item}
                
              </Typography>
              </Box>
              {itemIndex < items.length - 1 && (
                <Box pr={1} pl={1}>
                <Typography variant="overline">|</Typography>
                </Box> 
              )}
              </React.Fragment>
        
          ))}
          </Box>        
    );

}

export { ToggleText };