import React from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Grid,
  Hidden
} from '@material-ui/core';


import { InfoBox } from 'components/Common/InfoBox';
import { LabelHeading } from 'components/Common/DynamicFields/LabelHeading';

const DistrictOverview = () => {
  const { districtName, rosterPlatform, rosterProvider, overrideTermDate, rosterTeachers } = useSelector((state) => state.district);
  
  
  return (
    <InfoBox title="District Overview" alignContent="start">
    <LabelHeading fieldDisplay=' District Name' fieldValue={districtName}  />
    <Box mt={1} />
    <Box grow={1} />

    
      <Grid container spacing={1}>

        <Grid item lg={8} md={12} sm={12} xs={12}>
          <LabelHeading fieldDisplay='Roster Platform' 
                fieldValue={rosterPlatform && (
                `${rosterPlatform.charAt(0).toUpperCase() + rosterPlatform.slice(1)}`
                )} 
        />
                <LabelHeading fieldDisplay='Roster Protocol' 
                fieldValue={rosterProvider && (
                `${rosterProvider.charAt(0).toUpperCase() + rosterProvider.slice(1)}`
                )} 
        />
                <LabelHeading fieldDisplay='Teachers Rostered' 
                fieldValue={rosterTeachers ? 'Yes' : 'No'}
        />     

        {overrideTermDate && (
                <LabelHeading fieldDisplay='Start Date Override' 
                fieldType='date'
                fieldValue={rosterProvider && (
                `${overrideTermDate}`
                )} 
        />          
        )}
        
      </Grid>
      <Hidden mdDown>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <img alt="" src="/icons/edtech.png"/>
      </Grid>
      </Hidden>
        
      </Grid>      
      
      {/* <Grid  container spacing={1}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <LabelHeading fieldDisplay='Status' fieldValue={mode === 'production' ? 'Live' : 'Paused'} />            
        </Grid>
        
        <Grid item lg={6} md={12} sm={12} xs={12}>
        <LabelHeading fieldDisplay='Teachers Rostered' 
                fieldValue={rosterTeachers ? 'Yes' : 'No'}
        />                    
        </Grid>   
                 

      </Grid> */}
    



</InfoBox>

  );
};

export {
  DistrictOverview
};
