import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination  
} from '@material-ui/core';


import {
    Paper,
    CircularProgress,
} from '@material-ui/core';

import { fetchExcluded } from '../../actions'

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  navigationButton: {
   marginBottom: theme.spacing(3)
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },  
  link: {
    color: theme.palette.secondary.main,
  }
}),
);


const ExclusionResults2 = ( { criteria } ) => {
    const classes = useStyles();    
    const dispatch = useDispatch();
    
    const { excluded, isLoading } = useSelector((state) => state.excludedRecords);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 25));
      console.log(excluded)
      setPage(0);
    };


    useEffect(() => {
      
      if(criteria && Object.keys(criteria).length) {
        console.log(Object.keys(criteria).length)
        dispatch(fetchExcluded(criteria));
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [criteria]);

    return (
        <div className={classes.root}>

         {isLoading ? (
            <div className={classes.spinner}>
              <CircularProgress color="secondary" size={100} />
            </div>
          ) : (
         <TableContainer component={Paper}>     
            <TablePagination
              component="div"
              rowsPerPageOptions={[25, 50, 100, 500]}
              count={excluded.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />                       
            <Table className={classes.table} size="small" aria-label="staged licenses table">
              <TableHead>
                <TableRow>
                  <TableCell>Role</TableCell>
                  <TableCell>School</TableCell>
                  <TableCell>Course</TableCell>          
                  <TableCell>Roster Id</TableCell>
                  <TableCell>First</TableCell>
                  <TableCell>Last</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>           

              {excluded && 
              excluded.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map( (row, index) => (
                <TableRow key={`enrollment-${index}-key`}>
                  <TableCell>{row.schoolRole}</TableCell>
                  <TableCell>{row.schoolName}</TableCell>
                  <TableCell>{row.courseName}</TableCell>        
                  <TableCell>{row.rosterSourceId}</TableCell>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.email}</TableCell>     
                </TableRow>              
              ))}

              </TableBody>
            </Table>
          
          </TableContainer>

          )}         


        </div>
    )
}

export { ExclusionResults2 };