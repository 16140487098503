import React, { createRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  Grid,
  Box,
} from '@material-ui/core';
import { updateSAML, addSAML, deleteSAML } from '../../../actions';
import { DynamicField,  } from '../../Common/DynamicField';

import { CustomSnackbar } from '../../Common/CustomSnackbar';


  const formFields = [
    {
      fieldName: 'displayName',
      fieldDisplay: 'Connection Name',
      fieldValue: '',
      fieldType: 'text',      
    },       
    {
        fieldName: 'entryPoint',
        fieldDisplay: 'Log in Url',
        fieldValue: '',
        fieldType: 'text',
    },    
    {
        fieldName: 'logoutUrl',
        fieldDisplay: 'Log out Url',
        fieldValue: '',
        fieldType: 'text',
    },
    {
      fieldName: 'cert',
      fieldDisplay: 'Certificate',
      fieldValue: '',
      fieldType: 'textArea',
    },    
    {
      fieldName: 'signatureAlgorithm',
      fieldDisplay: 'Certificate Algorithm',
      fieldValue: 'sha1',
      fieldType: 'selectObject',        
      options: [
          {
            fieldDisplay: "sha1",
            fieldValue: 'sha1'
          },         
          {
              fieldDisplay: "sha256",
              fieldValue: 'sha256'
          },
          {
            fieldDisplay: "sha512",
            fieldValue: 'sha512'
        },  
      ]
  }
  ];

const defaultFormValues = formFields.reduce((memo, item) => {
  return {...memo, [item.fieldName] : item.fieldValue };
}, {});

const SAMLEdit = ({ssoProvider}) => {
    const form = createRef();
    const history = useHistory();
    const dispatch = useDispatch();
    
    const [formValues, updateFormValues] = useState(defaultFormValues);
    const [snackbarState, updateSnackbarState] = useState({
      open: false,
      severity: 'error',
      message: ""
    });



    useEffect(() => {      
        if (ssoProvider && ssoProvider.isActive && ssoProvider.settings) {
          const { settings } = ssoProvider;
          const updatedValues = formFields.reduce((memo, item) => {                
              return {...memo, [item.fieldName]: settings.hasOwnProperty(item.fieldName) ? settings[item.fieldName] : item.fieldValue};
          }, {});
          
          updateFormValues(updatedValues)
        }      
      }, [ssoProvider]);

    const handleChange = (value) => {      
        updateFormValues({...formValues, ...value});
    }
    const handleCancel = () => {  
        return history.replace('./');
    }
    const handleDelete = async () => {
      if (ssoProvider.settings && ssoProvider.settings.id) {         
        try {
          await dispatch(deleteSAML(ssoProvider.settings.id));
          return history.replace('./');
        } catch(err) {
          updateSnackbarState({...snackbarState, open: true, message: 'Error deleting SAML IdP.'}); 
        }
        
      }
  }    
    const handleSubmit = async (e) => {
      e.preventDefault();
      if(form.current.reportValidity()) {        
        if (ssoProvider.settings && ssoProvider.settings.id) {
          try {       
            await dispatch(updateSAML(ssoProvider.settings.id, formValues));            
            return history.replace('./');
          } catch(err) {
            updateSnackbarState({...snackbarState, open: true, message: 'Error updating SAML IdP.'});
          }
        } else {
          try {       
            await dispatch(addSAML(formValues));
            return history.replace('./');
          } catch(err) {
            updateSnackbarState({...snackbarState, open: true, message: 'Error adding SAML IdP.'});
          }          
        }
      }
    }

  const handleClose = () => {
      updateSnackbarState({ ...snackbarState, open: false });
  };

    return (
        <form ref={form} onSubmit={e => e.preventDefault()} noValidate>
          <CustomSnackbar handleClose={handleClose} snackbarState={snackbarState}/>


      <h2>IdP Settings</h2>
  {formFields.map((myField, myIndex) => (
      <DynamicField
        key={myIndex}
        editMode={true}
        variant="outlined"
        handleChange={handleChange}
        {...myField}
        fieldValue = {formValues[myField.fieldName] || ''}
      />
    ))}
    <Box mt={6} />
  <Grid
    container
    direction="row-reverse"
    justifyContent="space-between"
    alignItems="baseline"
    
  >

    <Grid item>
      <Button
          aria-label="Save"
          color="primary"
          type="submit"
          variant="outlined"
          onClick={handleSubmit}
      >Save</Button>
      
      <Button
          aria-label="Cancel"
          color="secondary"
          className='button-list'
          type="button"
          variant="outlined"
          onClick={handleCancel}
      >Cancel</Button>
    </Grid>
    {ssoProvider.isActive && (    
    <Button
          aria-label="Delete"
          color="secondary"
          type="button"
          variant="contained"
          onClick={handleDelete}
      >Delete</Button>
    )}
    </Grid>
    
    </form>
    )
}

export { SAMLEdit }