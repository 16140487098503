import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {    
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress
  } from '@material-ui/core';
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    table: {
      minWidth: '650',
    },
    paper: {
      backgroundColor: theme.palette.info.light,
      paddingTop: '1em',
      paddingBottom: '1em',
      paddingLeft: '1em'
    },
    spinner: {
      minHeight: '50vh',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(6)
    },    
  }));
  
  
const SyncWarnings = () => {
    const classes = useStyles(); 
    const {  data, isLoading } = useSelector((state) => state.pushReport, shallowEqual);
    return (
      <>
        {isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress color="secondary" size={100} />
          </div>
        ) : (      

        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="staged licenses table">
              <TableHead>
                <TableRow>
                  <TableCell>Severity</TableCell>
                  <TableCell>Error</TableCell>
                  <TableCell>message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='fadeIn'>
                {data && data.warnings && data.warnings.map((item, index) => (
                  <TableRow key={index}>                    
                    <TableCell scope="row">{item.severity}</TableCell>
                    <TableCell>{item.errorCode}</TableCell>
                    <TableCell>{item.message}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        </>

    )
}

export { SyncWarnings };