import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateAdminRoute = ({ component: Component, ...restOfProps }) => {
  const { isAdmin } = useSelector((state) => state.auth);
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        (isAdmin && <Component {...props} />) || (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

export {
  PrivateAdminRoute
};
