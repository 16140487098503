
const rosterTimeZone = {
  fieldName: 'rosterTimeZone',
  fieldDisplay: 'Timezone of roster server',
  fieldValue: 'US/Pacific',      
  fieldType: 'selectObject',
  options: [
    {fieldValue: 'UTC', fieldDisplay: 'UTC'},
    {fieldValue: 'US/Pacific', fieldDisplay: 'US Pacific'},
    {fieldValue: 'US/Mountain', fieldDisplay: 'US Mountain'},
    {fieldValue: 'US/Arizona', fieldDisplay: 'US Arizona'},
    {fieldValue: 'US/Central', fieldDisplay: 'US Central'},
    {fieldValue: 'US/Eastern', fieldDisplay: 'US Eastern'},
    {fieldValue: 'US/Hawaii', fieldDisplay: 'US Hawaii'},
  
    {fieldValue: 'Etc/GMT', fieldDisplay: 'GMT'},
    {fieldValue: 'Etc/GMT+1', fieldDisplay: 'GMT + 1'},
    {fieldValue: 'Etc/GMT+2', fieldDisplay: 'GMT + 2'},
    {fieldValue: 'Etc/GMT+3', fieldDisplay: 'GMT + 3'},
    {fieldValue: 'Etc/GMT+4', fieldDisplay: 'GMT + 4'},
    {fieldValue: 'Etc/GMT+5', fieldDisplay: 'GMT + 5'},
    {fieldValue: 'Etc/GMT+6', fieldDisplay: 'GMT + 6'},
    {fieldValue: 'Etc/GMT+7', fieldDisplay: 'GMT + 7'},
    {fieldValue: 'Etc/GMT+8', fieldDisplay: 'GMT + 8'},
    {fieldValue: 'Etc/GMT+9', fieldDisplay: 'GMT + 9'},
    {fieldValue: 'Etc/GMT+10', fieldDisplay: 'GMT + 10'},
    {fieldValue: 'Etc/GMT+11', fieldDisplay: 'GMT + 11'},
    {fieldValue: 'Etc/GMT+12', fieldDisplay: 'GMT + 12'},
    {fieldValue: 'Etc/GMT-1', fieldDisplay: 'GMT -1'},
    {fieldValue: 'Etc/GMT-2', fieldDisplay: 'GMT -2'},
    {fieldValue: 'Etc/GMT-3', fieldDisplay: 'GMT -3'},
    {fieldValue: 'Etc/GMT-4', fieldDisplay: 'GMT -4'},
    {fieldValue: 'Etc/GMT-5', fieldDisplay: 'GMT -5'},
    {fieldValue: 'Etc/GMT-6', fieldDisplay: 'GMT -6'},
    {fieldValue: 'Etc/GMT-7', fieldDisplay: 'GMT -7'},
    {fieldValue: 'Etc/GMT-8', fieldDisplay: 'GMT -8'},
    {fieldValue: 'Etc/GMT-9', fieldDisplay: 'GMT -9'},
    {fieldValue: 'Etc/GMT-10', fieldDisplay: 'GMT -10'},
    {fieldValue: 'Etc/GMT-11', fieldDisplay: 'GMT -11'},
    {fieldValue: 'Etc/GMT-12', fieldDisplay: 'GMT -12'},
    {fieldValue: 'Etc/GMT-13', fieldDisplay: 'GMT -13'},
    {fieldValue: 'Etc/GMT-14', fieldDisplay: 'GMT -14'},      
  ],
  disabled:false
}


  
  const rosterPlatforms =  {
    fieldName: 'rosterPlatform',
    fieldDisplay: 'Roster Platform',
    fieldValue: '',
    fieldType: 'selectObject',  
    options: [
      { fieldDisplay: 'OneRoster CSV', fieldValue: 'oneroster-csv'},
      { fieldDisplay: 'Aeries', fieldValue: 'aeries' },
      { fieldDisplay: 'Aequitas', fieldValue: 'aequitas' },
      { fieldDisplay: 'ClassLink', fieldValue: 'classlink'},
      { fieldDisplay: 'GG4L', fieldValue: 'gg4l' },
      { fieldDisplay: 'Infinite Campus', fieldValue: 'infiniteCampus' },
      { fieldDisplay: 'PowerSchool - Plugin', fieldValue: 'powerschool'},
      { fieldDisplay: 'Powerschool - Unified ClassRoom', fieldValue: 'unifiedClassroom' },    
      { fieldDisplay: 'Skyward', fieldValue: 'skyward'},
      { fieldDisplay: 'Synergy', fieldValue: 'synergy' },
      { fieldDisplay: 'OneRoster Compliant Provider', fieldValue: 'other'},
    ]
  }

    const authProtocol = {
      fieldName: 'authProtocol',
      fieldDisplay: 'Authentication Method',
      fieldValue: '',      
      fieldType: 'selectObject',
      options: [
        {
          fieldDisplay: 'OAuth 1.0',
          fieldValue: 'OAuth1',        
        },
        {
          fieldDisplay: 'OAuth 2.0',
          fieldValue: 'OAuth2',        
        }        
      ],
      disabled:true
    }

    const rosterCredentials = [
      rosterPlatforms,
      {
        fieldName: 'rosterProvider',
        fieldDisplay: 'Roster Protocol',
        fieldValue: '',
        fieldType: 'text',
        disabled: true,
        hidden: true
      },


      authProtocol,
      {
        fieldName: 'clientId',
        fieldDisplay: 'Public Client ID',
        fieldValue: '',
        fieldType: 'text',      
      },
      {
        fieldName: 'clientSecret',
        fieldDisplay: 'Secret Key',
        fieldValue: '',
        validationType: 'password',
        required: false,
        fieldType: 'password',
        helperText: 'Previously saved Secret Key will be used if none provided'
      },
      {
        fieldName: 'baseUrl',
        fieldDisplay: 'Base Url',
        fieldValue: 'https://',
        fieldType: 'url',
      },
      {
        fieldName: 'rosterServer',
        fieldDisplay: 'Server Endpoint',
        fieldValue: 'https://',
        fieldType: 'url',
        disabled: true
      }
    ]
    const slurpData = 
      {
        fieldName: 'slurpData',
        fieldDisplay: 'Preload data',
        fieldValue: false,
        fieldType: 'switch',        
        disabled: false,
      }
    ;    

    const oAuth2Fields = [
      {
        fieldName: 'OAuth2TokenEndpoint',
        fieldDisplay: 'OAuth2 Token Endpoint',
        fieldValue: 'https://',
        fieldType: 'url',
        disabled: true
      }
    ];

    const getOAuth1 = (overrides = {}) => {
      const defaultSettings = [...rosterCredentials, rosterTimeZone, slurpData];
      return defaultSettings.reduce((memo, x) => {        
          if (overrides.hasOwnProperty(x.fieldName)) {
            return [...memo, {...x, ...overrides[x.fieldName]}]
          } else {
            return [...memo, x];
          }
        }, []);
    }

    const getOAuth2 = (overrides = {}) => {
      const defaultSettings = [...rosterCredentials, ...oAuth2Fields, rosterTimeZone, slurpData];
      return defaultSettings.reduce((memo, x) => {
        if (overrides.hasOwnProperty(x.fieldName)) {
          return [...memo, {...x, ...overrides[x.fieldName]}]
        } else {
          return [...memo, x];
        }
      }, []);
      
    }    



    

    const rosterPlatformProfiles = 
    {
      'oneroster-csv':
        {
          defaults: {rosterProvider: 'oneroster-csv', slurpData: false},
          getFormFields: (authProtocol = 'OAuth2') => [
            rosterPlatforms,
            {...slurpData, hidden: true}
          ]
        },
      'aeries':
      {
        defaults: { authProtocol: 'OAuth2', rosterProvider: 'oneroster'},
        getBaseUrl: (rosterServer) => rosterServer.replace('ims/oneroster/v1p1', ''),
        urlWizards: (baseUrl) => {
          return {
            rosterServer: baseUrl.concat('ims/oneroster/v1p1'),
            OAuth2TokenEndpoint: baseUrl.concat('token'),
         }
        },
        getFormFields: (authProtocol = 'OAuth2') => {          
          return getOAuth2 ({ 
            authProtocol: {hidden: true},
          })                      
        }
      },
      'aequitas':
        {
          defaults: { authProtocol: 'OAuth2', rosterProvider: 'oneroster'},
          getBaseUrl: (rosterServer) => rosterServer.replace('QWebAPI/ims/oneroster/v1p1', ''),
          urlWizards: (baseUrl) => {
            return {
              rosterServer: baseUrl.concat('QWebAPI/ims/oneroster/v1p1'),
              OAuth2TokenEndpoint: baseUrl.concat('QWebAPI/ims/oneroster/v1p1/token')              
           }
          },
          getFormFields: (authProtocol = 'OAuth2') => {            
            return getOAuth2 ({ 
              authProtocol: {hidden: true},
            })                      
          }
        },
      'classlink':
      {
        defaults: { authProtocol: 'OAuth1', rosterProvider: 'oneroster'},
        getFormFields: (authProtocol = 'OAuth1') => {            
          return getOAuth1 ({ 
            authProtocol: {hidden: true},
            baseUrl: {hidden: true },
            rosterServer: {disabled: false}
          })                      
      },    
      },
      'gg4l':
        {
          defaults: { authProtocol: 'OAuth2', rosterProvider: 'oneroster'},
          urlWizards: (baseUrl) => {
            return {
              rosterServer: baseUrl.concat('ims/oneroster/v1p1'),              
           }
          },
          getFormFields: (authProtocol = 'OAuth2') => {            
            return getOAuth2 ({ 
              authProtocol: {hidden: true},
            })                      
          }          
        },
      'infiniteCampus':
      {
        defaults: { authProtocol: 'OAuth2', rosterProvider: 'oneroster'},
        getFormFields: (authProtocol = 'OAuth2') => {
          if (authProtocol === 'OAuth2') {
            return getOAuth2 ({ 
              authProtocol: {disabled: false},
              // rosterServer: {disabled: false, helperText: 'https://<subdomain>infinitecampus.org/campus/oneroster/<district>/ims/oneroster/v1p1'},
              // OAuth2TokenEndpoint: {disabled: false, helperText: 'https://<subdomain>infinitecampus.org/campus/oauth2/token/<district>'},
              rosterServer: {disabled: false},
              OAuth2TokenEndpoint: {disabled: false},
              baseUrl: { hidden: true}
              })            
          } else {
            return getOAuth1 ({ 
              authProtocol: {disabled: 0},
              rosterServer: {disabled: false},
              baseUrl: { hidden: true}
            })
            
          }
        },     
      },
      'synergy':
        {
          defaults: { authProtocol: 'OAuth2', rosterProvider: 'oneroster'},
          getBaseUrl: (rosterServer) => rosterServer.replace('ims/oneroster/v1p1', ''),
          urlWizards: (baseUrl) => {
            return {
              rosterServer: baseUrl.concat('ims/oneroster/v1p1'),
              OAuth2TokenEndpoint: baseUrl.concat('oauth/access_token'),
           }
          },          
          getFormFields: (authProtocol = 'OAuth2') => {            
            return getOAuth2 ({ 
              authProtocol: {hidden: true},
            })                      
          }    
        },
      'skyward':
      {
        defaults: { authProtocol: 'OAuth2', rosterProvider: 'oneroster'},
        getBaseUrl: (rosterServer) => rosterServer.replace('ims/oneroster/v1p1', ''),
        urlWizards: (baseUrl) => {
          return {
            rosterServer: baseUrl.concat('ims/oneroster/v1p1'),
            OAuth2TokenEndpoint: baseUrl.concat('token'),
         }
        },            
        getFormFields: (authProtocol = 'OAuth2') => {            
          return getOAuth2 ({ 
            authProtocol: {hidden: true},
          })                      
        }    
      },
      'unifiedClassroom':
      {
        defaults: { 
          authProtocol: 'OAuth2',
          rosterProvider: 'oneroster',
          rosterServer: 'https://classroom.powerschool.com/ims/oneroster/v1p1',
          OAuth2TokenEndpoint: 'https://classroom.powerschool.com/token'
        },
        getBaseUrl: (rosterServer) => rosterServer.replace('ims/oneroster/v1p1', ''),
        urlWizards: (baseUrl) => {
          return {
            rosterServer: baseUrl.concat('ims/oneroster/v1p1'),
         }
        },
        getFormFields: (authProtocol = 'OAuth2') => {            
          return getOAuth2 ({ 
            authProtocol: {hidden: true},
            baseUrl: {hidden: true},
          })                      
        }        
      },
      'powerschool':
        {
          defaults: { authProtocol: 'OAuth1', rosterProvider: 'powerschool'},
          getFormFields: (authProtocol = 'OAuth1') => {            
              return getOAuth1 ({ 
                authProtocol: {hidden: true},
                baseUrl: {hidden: true },
                rosterServer: {disabled: false}
              })                      
          },
        },
      'other':
      {
        defaults: { authProtocol: 'OAuth2', rosterProvider: 'oneroster'},
        getFormFields: (authProtocol = 'OAuth2') => {        
          if (authProtocol === 'OAuth2') {
            return getOAuth2 ({ 
                authProtocol: {disabled: false},              
                rosterServer: {disabled: false},
                OAuth2TokenEndpoint: {disabled: false},
                baseUrl: { hidden: true}
              })            
          } else {
            return getOAuth1 ({ 
              authProtocol: {disabled: 0},
              rosterServer: {disabled: false},
              baseUrl: { hidden: true}
            })
            
          }
        },
      },                        
  }
    


  
    export { rosterPlatforms,  rosterPlatformProfiles, slurpData, };