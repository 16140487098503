import React, { useState} from 'react';
import { useSelector } from 'react-redux';
import {
    Box, 
    FormControl,
    InputLabel,
    Select,    
    MenuItem,    
    }
from '@material-ui/core';

import { BackToButton } from '../Common/BackToButton';
import { CourseClasses } from './CourseClasses';
const CourseExplorer = () => {
    
    const district = useSelector((state) => state.district);
    const [activeCourseId, updateActiveCourseId] = useState("");
    
    const handleChange = (e) => {
        updateActiveCourseId(e.target.value);        
    }

    return (
        <div style={{width: '100%'}}>
            <BackToButton />
        <>
        <Box mt={2} />
        <h2>Data Explorer </h2>
        <p>Select a course from the list below to view relevant sections. </p>
        <FormControl  fullWidth>
        <InputLabel className='MuiInputLabel-shrink' htmlFor='courseId'>Courses</InputLabel>
        <Select
            displayEmpty
            value={activeCourseId}
            onChange={handleChange}            
            label='Courses'
            inputProps={{
                name: 'courseId',
                id: 'courseId',
            }}
        >            
        {district.courses.map((myOption, index) => (      
            <MenuItem key={index} value={myOption.courseId}>
                {myOption.courseName} ({myOption.courseId})
            </MenuItem>
        ))}
        </Select>
        </FormControl>
        <Box mb={2} />
        <CourseClasses courseId={activeCourseId} />
        </> 
        </div>
    )
}

export { CourseExplorer };