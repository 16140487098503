import React from 'react';
import {
    TextField,
} from '@material-ui/core';

const TextAreaEdit = (props) => {
    const { fieldDisplay, fieldName, fieldValue, disabled=false, required=true, handleChange } = props;
    return (
            <TextField            
                fullWidth
                disabled={disabled}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                label={fieldDisplay}
                margin="normal"
                name={fieldName}
                multiline
                maxRows={4}
                onChange={(e)=>handleChange({ [e.target.name]: e.target.value })}
                required={required}
                value={fieldValue || ''}
                />
    )
}


export { TextAreaEdit };