import produce from 'immer';

import {
  FETCH_ELSPROFILE_REQUEST,
  FETCH_ELSPROFILE_SUCCESS,
  FETCH_ELSPROFILE_FAILURE,
} from '../actions/actionTypes';

const initialState = { elsProfile: {  
  description: '',
  districtId: '',
  name: '',
  isEWorkspaceEnabled: false,
}, isLoading: false };

const districtElsReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_ELSPROFILE_REQUEST:
      return { ...draft, isLoading: true };
    case FETCH_ELSPROFILE_SUCCESS:
      return { ...draft, isLoading: false, profile: {
        districtId: action.payload.customerId,
        name: action.payload.name,
        description: action.payload.description,
        isEWorkspaceEnabled: action.payload.properties?.isEWorkspaceEnabled || false,
      } };    
    case FETCH_ELSPROFILE_FAILURE:
      console.log('error loading ELS Profile')
      return draft;
    default:
      return draft;
  }
}, initialState);

export default districtElsReducer;
