import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { SyncNumbers } from "./";
import Pluralize from 'react-pluralize'
import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Typography,
  } from '@material-ui/core';
  import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
  import { launchPush } from '../../utils/statelessCalls';
  import { DialogConfirm } from "../../components/Common/DialogConfirm";

  const useStyles = makeStyles((theme) => ({
    boxContent: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        padding: 0
      },
      boxContentNumber: {
        display: 'flex',
        justifyContent: 'center',
        
      },
      button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      },
      paperContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        padding: theme.spacing(3),        
      },
      paperHeading: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: "white",
        display: 'flex',
        margin: 0,
        padding: theme.spacing(2)
      },
      paperHeadingTitle: {
        marginRight: theme.spacing(0.5)
      },
      warningIcon: {
        color: '#ff9800',
        fontSize: '56px'
      }
  }));


const SyncDashboard = ( ) => {
    const classes = useStyles();
    const history = useHistory();
    const { data: pushReport } = useSelector((state) => state.pushReport, shallowEqual);
    const [open, setOpen] = useState(false);
    const syncNumber = (pushReport && pushReport.hoursSinceJob < 24) ? pushReport.hoursSinceJob : pushReport.daysSinceJob;
    const syncText = (pushReport && pushReport.hoursSinceJob < 24) ? `hour` : `day`;
    const handleConfirmPush = async() => {
      try {
          const { jobId } = await launchPush();
          history.push(`/sync/${jobId}`);
          // setManualPushSubmitted({submitted: true, message: 'License Sync has been launched.  Syncs can take between 1 to 10 minutes to complete.'});   
      } catch(err) {
          // setManualPushSubmitted({submitted: true, error: true, message: 'Unable to launch license sync. Please contact support.'});
      }
    }

      return (
        <>
            <DialogConfirm 
              open={open}
              handleCancel={() => setOpen(false)}
              handleConfirm={handleConfirmPush}
              title='Sync Licenses'
              description='CPM licenses will be replaced with the latest staged roster data.  This cannot be undone.'
            />        
 
        <Paper className={classes.paper} elevation={4} square>
        <div className={classes.paperHeading}>
          <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
          >        
            <Typography
              className={classes.paperHeadingTitle}
              component="h3"
              variant="body1"
            >
              Last License Sync
            </Typography>
            <SyncOutlinedIcon />
          </Grid>
        </div>
        <div className={classes.paperContent}>

        <Container className={classes.boxContent}>
            <Typography className={classes.boxContentNumber} variant="caption" color="primary">
                Last completed
            </Typography>          
            {pushReport && pushReport._id ? (
                  <>            
              <Typography className={classes.boxContentNumber} variant="h4" color="primary">                
                  <Pluralize singular={syncText} count={syncNumber} /> <Box pl={1}> ago </Box>
              </Typography>
              <Typography className={classes.boxContentNumber} variant="caption">{moment(pushReport.date).local().format('MMM DD, YYYY LT')}</Typography>  
              </>
                ) : (
                  <Typography className={classes.boxContentNumber} variant="h4" color="primary">
                    No Record
                  </Typography>
                )}
                
            </Container>


          <Container className={classes.boxContent}>
          {pushReport && pushReport._id ? (
            <Button
                className={classes.button}
                aria-label="View Report"              
                color="primary"
                variant="outlined"
                component={RouterLink}              
                disabled={!pushReport._id}
                to={`/sync/${pushReport._id}`}
              >
                View
              </Button>
          ) : (
            <Button
                className={classes.button}
                aria-label="View Report"              
                color="primary"
                variant="outlined" 
                onClick={() => setOpen(true)}
              >
                Sync Now
              </Button>
          )}
  
            <SyncNumbers />
              
          </Container>

            

          
        </div>
        </Paper>
    </>


      );
    };
    export {
      SyncDashboard
    };
    
