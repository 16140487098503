import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Paper, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%' // Fix IE 11 issue.
  },
  logo: {
    width: '150px'
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  title: {
    color: 'rgb(14, 108, 67)',
    fontWeight: 600
  },
  link: {    
    color: theme.palette.primary.dark
  }
}));

const LoginRestricted = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper} elevation={2} square>
        <Box alignItems="center" display="flex" flexDirection="column">
          <img alt="CPM Logo" className={classes.logo} src="/CPM.svg" />
          <Typography className={classes.title} variant="body2">
            Integration Services
          </Typography>
        </Box>
        <div style={{padding: '32px 0', textAlign: 'center' }}>
        <Typography  variant="body1">
            This site requires elevated privileges
          </Typography>
          <Typography variant="caption">
          Contact <a className={classes.link} href="mailto:support@cpm.org">support@cpm.org</a> for access
        </Typography>                  
        </div>

        <div style={{padding: '1em', textAlign: 'center' }}>
          <Typography variant="caption">
          *Please note you must already be a CPM administrator
        </Typography>          
        </div>        


      </Paper>
    </Container>
  );
};

export {
  LoginRestricted
};
