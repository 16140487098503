import React from 'react';
import { 
    Grid,
    Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverSharpIcon from '@material-ui/icons/DeleteForeverSharp';
import { DynamicFormat } from '../../Common/DynamicFormat';
const useStyles = makeStyles((theme) => ({
  addedEbook: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
    backgroundColor: theme.palette.info.light,
    color: theme.palette.primary.main
  },
  autoExpireBook: {
    color: theme.palette.primary.main
  }

}));

const StickyBooksList = ({ licenses=[], lastAddedEbookId, handleClick}) => {
  const classes = useStyles();
  return (
    <>
    {licenses.map((eBook) => (

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          className={lastAddedEbookId === eBook.eBookId ? classes.addedEbook : classes.eBooks} key={eBook.eBookId}
        >
          <Grid item >            
            {eBook.eBookName}
          </Grid>
        

          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-around"                            
            >
              
              {eBook.expireAt && (
                  <Grid item>
                  <DynamicFormat style={{paddingRight: '2em'}} fieldValue={eBook.expireAt} fieldType='date' />
                  <Box pr={3} />              
                  </Grid>
              )}          
              <DeleteForeverSharpIcon
                style={{ cursor: 'pointer' }}
                onClick={() => handleClick(eBook)}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}    
      </>
    )
}

export { StickyBooksList }