import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import District from 'components/District';

const useStyles = makeStyles((theme) => ({
  navigationButton: {
    marginBottom: theme.spacing(3)
  },
  root: {
    width: '100%'
  }
}));

const DistrictStep = () => {
  const history = useHistory();
  const classes = useStyles();

  const handleGoToDashboard = () => {
    history.push('/dashboard');
  };

  return (
    <div className={classes.root}>
      <Button
        aria-label="Back"
        className={classes.navigationButton}
        color="default"
        onClick={handleGoToDashboard}
        startIcon={<ArrowBackIosIcon />}
        variant="contained"
      >
        Back to Dashboard
      </Button>
      <District maxWidth="xs"/>
    </div>
  );
};

export default DistrictStep;
