import produce from 'immer';
import { reject } from 'lodash';
import {
  ASSIGN_LICENSE,
  UNASSIGN_LICENSE,
  DISABLE_MULTI_LICENSE,
  ENABLE_MULTI_LICENSE,
  UPDATE_LICENSE,
  FETCH_LICENSES_FAILURE,
  FETCH_LICENSES_REQUEST,
  FETCH_LICENSES_SUCCESS,

} from '../actions/actionTypes';

const initialState = { courses: [], licenses: [], isLoading: false };

const licensesReducer = produce((draft, action) => {
  switch (action.type) {
    case UNASSIGN_LICENSE: {      
      const courses = draft.courses.map(x => {
        if ( x._id === action.payload ) {          
          const {_id, ...rest } = x;
          return {...rest, eBookId: null };
        } else {
          return x;
        }
      });
      return { ...draft, courses };
    }
    case ASSIGN_LICENSE: {      
      const { courseId } = action.payload;
      const courses = draft.courses.map(x => {
        if ( x.courseId === courseId && !x.eBookId && !x._id) {          
          return {...x, ...action.payload};
        } else {
          return x;
        }
      });

      return { ...draft, courses };
    }
    case ENABLE_MULTI_LICENSE: {      
      const { _id, eBookId, ...rest } = action.payload;
      const courses = draft.courses.reduce((memo, course) => {
        if (course.multiBook && !eBookId) {
          return memo;
        }
        if ( course._id !== _id ) {
          return [...memo, course];
        } else {
          return [...memo, {...course, blocked: true}, {...rest, multiBook: true}];
        }
      }, []);

      return { ...draft, courses };
    }

    case DISABLE_MULTI_LICENSE: {      

      const courses = reject(draft.courses, (x) => { return x.multiBook && !x.eBookId; });

      return { ...draft, courses };
    }    
    
    case UPDATE_LICENSE: {      
      const { _id } = action.payload;
      const courses = draft.courses.map(x => {
        if ( x._id === _id ) {
          return {...x, ...action.payload};
        } else {
          return x;
        }
      });

      return { ...draft, courses };
    }        

    case FETCH_LICENSES_FAILURE:
      return {...draft, isLoading: false };
    case FETCH_LICENSES_REQUEST:
      return {...draft, isLoading: true };
    case FETCH_LICENSES_SUCCESS: {
      const { courses, licenses } = action.payload;
      return {...draft, isLoading: false, courses, licenses };      
    }
    default:
      return draft;
  }
}, initialState);

export default licensesReducer;
