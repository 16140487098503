import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    Divider
 } from '@material-ui/core';
  

 const useStyles = makeStyles((theme) =>
 createStyles({   
   paper: {
     padding: theme.spacing(2),
     marginBottom: theme.spacing(2),
     marginTop: theme.spacing(2),
    //  textAlign: 'left',
     color: theme.palette.text.primary,
   },
   excludeField: {
    color: theme.palette.text.secondary,
   },
   excludeValue: {
    color: theme.palette.secondary.main,
   },   
   excludeTitle: {
    fontWeight: 'bold',
    fontSize: '90%',
    color: theme.palette.text.secondary,
   },
   excludeName: {
    fontWeight: 'bold',
    fontSize: '150%',
    color: theme.palette.primary.main,
   }         
 }),
);

 const ExclusionEntry = ({exclusion, navAction}) => {    
    const classes = useStyles();
    
     return (
        <Paper key={exclusion._id} className={classes.paper}>
        <Grid 
            container
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            
            <Grid item xs={8}>
                {exclusion && exclusion.name && (
                    <>
                    <div className={classes.excludeName}>{exclusion.name}</div>
                    <Divider />
                    </>
                )}
                
                <div className={classes.excludeTitle}>Exclude when</div>
                    {exclusion.items.map((item, index) => {
                        return (
                            <div key={`exclusion-${index}-criteria`}>
                                {index > 0 && (
                                    <span>and </span>
                                )}
                                <span className={classes.excludeField}>"{item.exclusionField}" </span> 
                                {item.exclusionOperator}
                                <span className={classes.excludeValue}> {item.exclusionValue}</span>
                            </div>
                        )
                    })}
        </Grid>
        {navAction}        
     </Grid>
    </Paper>
         
     )
 }

 export { ExclusionEntry };