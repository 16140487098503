import React, { useState, createRef } from 'react';
import { 
    Button,
    Box,
    Grid
} from '@material-ui/core';  
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { uploadOneRosterZip, getCsvUploadLogs, getCsvUploadResults } from '../../utils/statelessCalls';
import { UseIntervalHook } from "./hooks";

const useStyles = makeStyles((theme) => ({
    invalidButton: {
      color: '#fff',
      backgroundColor: theme.palette.danger.main,
      '&:hover': {
        backgroundColor: theme.palette.danger.dark,
      },      
    },
    successButton: {
        color: '#fff',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
        },      
    }
  }));

const initJob = {jobId: null, isRunning: false, error: '', logs: [], results: {}};

const UploadFile = ( ) => {
    const classes = useStyles();
    const [file, setFile] = useState();
    const [noFileError, setNoFileError] = useState(false);    
    const form = createRef();
    const [showFiles, updateShowFiles] = useState(false);
    
    const [job, updateJob] = useState(initJob);
    const [pollingInterval, updatePollingInterval] = useState(5000000);
    
    UseIntervalHook(async () => {
      if (job.jobId) {
        const resultsPromise = getCsvUploadResults(job.jobId);
        const logsPromise = getCsvUploadLogs(job.jobId);

        try {
          const [results, logs] = await Promise.all([resultsPromise, logsPromise]);
        
          if (results && results.completed) {
            updateJob({...job, results, logs, isRunning: false});
            updatePollingInterval(5000000000000);
          } else {
            updatePollingInterval(2000);
            updateJob({...job, results, logs});
          }
        } catch (err) {
          updateJob({...job, isRunning: false, error: err.message});
        }
      }
    }, pollingInterval)   

    const handleFileChange = (e) => {
        setNoFileError(false);
        const newFile=e.target.files[0];
        if (newFile && newFile.type === "application/zip") {
            setFile(newFile);
        }
        else { 
            setNoFileError(true);            
        }
        
    }    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setNoFileError(true);
        }        
        if (form.current.reportValidity() && file) {
            updateJob({...initJob, isRunning: true});
            const formData = new FormData();             
            formData.append('file', file);
            try {
              const response = await uploadOneRosterZip(formData);
              updateJob({...job, jobId: response.id, isRunning: true});
              updatePollingInterval(1000);
            } catch (err) {
              updateJob({...initJob, isRunning: false, error: err.message});
            }
        }     
    }

    const requiredFiles = [
      'academicSessions.csv',
      'orgs.csv',
      'courses.csv',
      'classes.csv',
      'users.csv',
      'enrollments.csv'
    ];

    return (
        <form ref={form} onSubmit={e => e.preventDefault()} style={{width: '100%'}}>
          
            <Box width="100%" pt={6} pb={1}>
            
        <Box display="flex" justifyContent="space-between" alignItems="center">
              <h4>SFTP Server</h4>
              <div className='clickable-link' onClick={() => updateShowFiles(!showFiles)}>
                {showFiles ? (
                  <div>hide</div>
                ) : (
                  <div>required files</div>
                )}
                </div>
            </Box>
            {showFiles && (
              <Box mb={3}>
            <Alert icon={false} severity='warning'>              
              <Grid container spacing={1}>
                {requiredFiles.map((requiredFile, index) => (
                  <React.Fragment key={index}>
                  <Grid item xs={3}>
                    file:
                  </Grid>
                  <Grid item xs={9}>
                    {requiredFile}
                  </Grid>
                  </React.Fragment>
                ))}              

              </Grid>                             
            </Alert>
            </Box>
            )}      
            
            <Alert icon={false} severity='info'>

            <Grid container spacing={1}>
                <Grid item xs={3}>
                  server:
                </Grid>
                <Grid item xs={9}>
                  oneroster-sftp.cpm.org
                </Grid>

                <Grid item xs={3}>
                  port:
                </Grid>
                <Grid item xs={9}>
                  22
                </Grid>

                <Grid item xs={3}>
                  directory:
                </Grid>
                <Grid item xs={9}>
                  /tmp
                </Grid>                

                <Grid item xs={3}>
                  username:
                </Grid>
                <Grid item xs={9}>
                  (same as CPM District Admin account)
                </Grid>
                
                <Grid item xs={3}>
                  password:
                </Grid>
                <Grid item xs={9}>
                  (same as CPM District Admin account)
                </Grid>
            </Grid> 
            </Alert>
            
            <Box mt={6} />

            <h4>For testing / setup purposes:</h4>
              Manually upload the OneRoster zip file
            </Box>

            <Box display="flex" justifyContent="space-between">
                <Box mr={3} flexGrow={1}>
                
                    <Button
                        fullWidth
                        className={noFileError ? classes.invalidButton : file ? classes.successButton : ''}
                        variant="contained"
                        component="label"
                        onChange={handleFileChange}
                        startIcon={<FileCopyIcon />}
                    >
                    {file ? (
                        <span>{noFileError ? 'select file' : file.name}</span>
                    ) : (
                        <span>Select File</span>
                    )}                           
                        <input
                            type="file"
                            accept="application/zip"              
                            hidden
                        />
                    </Button>

                    </Box>
                    <Box>
                    <Button
                        fullWidth
                        disabled={!file || job.isRunning}
                        variant="outlined"
                        component="label"
                        color="primary"
                        onClick={handleSubmit}
                    >
                    Upload
                    </Button>
                    </Box>                
              </Box> 
              

              {job && (job.jobId || job.isRunning) && (
                <>
                <h3>Job Results</h3>
                <Box p={2} boxShadow={5} component='div' display="flex" flexDirection='column' alignItems="flex-start">
                  <div>
                  
                  <div>Uploading file</div>
                  {Array.isArray(job.logs) && job.logs.map((myLog, index) => (
                    <div key={index}>{myLog.message}</div>
                  ))}
                  
                  </div>                
                {job.isRunning && (
                <Box item justifySelf="center" alignSelf="center">
                                        
                    <div className="lds-facebook">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    
                    </Box>  
                  
                  )}                
                  </Box>
                </>
              )}
                {job.error && (
                  <Box pt={3}>
                    <Alert severity='error'>{job.error}</Alert>
                  </Box>
                )}                              
        </form>
    );
}

export { UploadFile };