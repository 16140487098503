import React from 'react';
import { 
    FormControl, 
    TextField,
    MenuItem,
    }
from '@material-ui/core';
const SelectObjectField = (props) => {
    const { fieldDisplay, fieldName, fieldValue, disabled=false, required=true, options, handleChange } = props;
    return (
        <FormControl fullWidth>
        <TextField
            disabled={disabled}
            required={required}
            margin="dense"
            value={fieldValue}
            InputLabelProps={{ shrink: true }}
            onChange={(e)=>handleChange({ [e.target.name]: e.target.value })}            
            label={fieldDisplay}
            select
            variant="outlined"
            inputProps={{
            name: fieldName,
            id: fieldName,            
            }}
        >            
        {options.map((myOption, index) => (      
            <MenuItem key={`${fieldName}-${index}`} value={myOption.fieldValue || myOption.fieldName}>
                {myOption.fieldDisplay}
            </MenuItem>
        ))}
        </TextField>
        </FormControl>        
    )
}

export { SelectObjectField }