import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Grid,
  Button,
  Box,
  CircularProgress,
  colors,
  Container,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import HelpIconSharp from '@material-ui/icons/HelpSharp';
import SchoolSharpIcon from '@material-ui/icons/SchoolSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import {
  fetchSchools,
  resetSchoolsFilter,
  setSchoolsFilter,
  toggleRosterSchool,
  removeInvalidSchool
} from 'actions';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main
  },
  button: {
    marginTop: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(0.5, 0)
  },
  list: {
    backgroundColor: 'white',
    flex: '1',
    height: '100vh'
  },
  listsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  navigationButton: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    marginBottom: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.info.light,
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingError: {
    alignItems: 'center',
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.info.light,
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },    
  paperHeadingTitle: {
    marginRight: theme.spacing(0.5)
  },
  paperInput: {
    padding: theme.spacing(3)
  },
  paperInputField: {
    width: '100%'
  },
  paperList: {
    flex: '1',
    height: '100vh',
    overflow: 'hidden',
    '&:first-child': {
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
        marginRight: 0
      }
    }
  },
  paperOtherContent: {
    minHeight: '35vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 0
  },
  row: {
    padding: theme.spacing(0, 3)
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },
  tooltip: {
    backgroundColor: theme.palette.success.main,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    justifyContent: 'center'
  },
  warningIcon: {
    color: '#f1c40f',
    fontSize: '80px'
  },
  rosteredAvatar: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark
  },
  errorIcon: {
    color: colors.red[600],
    fontSize: '24px'    
  }  
}));

const Schools = ({ disableNext }) => {
  const { schools, invalid } = useSelector((state) => state.schools, shallowEqual);
  const schoolsFilter = useSelector((state) => state.schools.filter);
  const rosteredSchools = useSelector((state) => state.district.schools.length);
  const isLoading = useSelector((state) => state.schools.isLoading);
  
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const match = new RegExp(schoolsFilter, 'i');
  const filteredUnrosteredSchools = schools.filter(
    (s) => !s.rostered && match.test(s.schoolName)
  );
  const filteredRosteredSchools = schools.filter((s) => s.rostered);

  const handleFilter = (searchString) => {
    dispatch(setSchoolsFilter(searchString));
  };

  const toggleSchool = (school) => {
    dispatch(toggleRosterSchool(school));
  };
  const removeBadSchool = (school) => {
    dispatch(removeInvalidSchool(school));
  };  


  const handleClick = () => {
    history.push('/profile');
  };


  const ItemRow = ({ item }) => {
    return (
      
      <ListItem divider key={item.schoolId}>
      <ListItemAvatar>
          <WarningSharpIcon className={classes.errorIcon}/>
      </ListItemAvatar>
      <ListItemText
        id={item.schoolId}
        primary={
          <Typography variant="body2">
            {item.schoolName.toUpperCase()}
            {' - '}
            {item.schoolId}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        {item.rostered ? (
          <IconButton
          aria-label="Remove School"
          edge="end"
          onClick={() => removeBadSchool(item)}
        >
          <ClearOutlinedIcon />
        </IconButton>
        ) : (
          <IconButton
          aria-label="Add School"
          edge="end"
          onClick={() => toggleSchool(item)}
        >
          <ClearOutlinedIcon />
        </IconButton>          
        )}
        
      </ListItemSecondaryAction>
    </ListItem>
    );
  };


  // TODO: Create reusable row components
  const unrosteredRow = ({ index, style }) => {
    return (
      <div className={classes.row} style={style}>
        <ListItem divider key={filteredUnrosteredSchools[index].schoolId}>
          <ListItemAvatar>
            <Avatar>
              <SchoolSharpIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            id={filteredUnrosteredSchools[index].schoolId}
            primary={
              <Typography variant="body2">
                {filteredUnrosteredSchools[index].schoolName.toUpperCase()}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Add School"
              edge="end"
              onClick={() => toggleSchool(filteredUnrosteredSchools[index])}
            >
              <AddSharpIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  };

  const rosteredRow = ({ index, style }) => {
    return (
      <div className={classes.row} style={style}>
        <ListItem divider key={filteredRosteredSchools[index].schoolId}>
          <ListItemAvatar>
            <Avatar className={classes.rosteredAvatar}>
              <SchoolSharpIcon/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            id={filteredRosteredSchools[index].schoolId}
            primary={
              <Typography variant="body2">
                {filteredRosteredSchools[index].schoolName.toUpperCase()}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Remove School"
              edge="end"
              onClick={() => toggleSchool(filteredRosteredSchools[index])}
            >
              <ClearOutlinedIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  };

  useEffect(() => {
    dispatch(fetchSchools());

    return () => {
      dispatch(resetSchoolsFilter());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rosteredSchools > 0 && disableNext) {
      disableNext(false);
    }

    return () => {
      if (disableNext) {
        disableNext(true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rosteredSchools]);

  return (
    <>
      {isLoading ? (
        <div className={classes.spinner}>
          <CircularProgress color="secondary" size={100} />
        </div>
      ) : (
        <>
          {invalid && invalid.length > 0 && (
          <Box pb={2}>
            <Paper  elevation={2} square>
                <div className={classes.paperHeadingError}>
                  <Typography component="h3" variant="body1">
                    Exception Report
                  </Typography>
                </div>
                <Box 
                  p={2}
                  sm={{ p: 3 }}
                  md={{ p: 4 }}
                >
                  <h4>Immediate attention needed</h4>
                  The following schools are invalid and may prevent the data syncronization from completing.   This is most likely caused by a school that is 
                  no longer valid or is no longer being shared.  Unless the school should be rostered, the common solution is to remove the school. 
                </Box>
                <List>
                {invalid.map((item, index) => (
                  <ItemRow key={`invalid-${index}-school`} item={item} />
                ))}
                </List>
            </Paper>
            </Box>
          )}


          <Paper className={classes.paper} elevation={2} square>
            <div className={classes.paperHeading}>
              <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >   
                <Typography
                  className={classes.paperHeadingTitle}
                  component="h1"
                  variant="body1"
                >
                  Schools
                </Typography>
                <Tooltip
                  arrow
                  classes={{
                    arrow: classes.arrow,
                    tooltip: classes.tooltip
                  }}
                  placement="right"
                  title={
                    <div className={classes.tooltip}>
                      <Typography variant="subtitle2">
                        Roster a school by clicking on the <AddSharpIcon /> icon
                        next to the school name.
                      </Typography>
                      <span className={classes.divider} />
                      <Typography variant="subtitle2">
                        Unroster a school by clicking on the <ClearOutlinedIcon />{' '}
                        icon next to the school name.
                      </Typography>
                    </div>
                  }
                >
                  <HelpIconSharp aria-label="Help Text" fontSize="small" />
                </Tooltip>
              </Grid>
            </div>


            {/* TODO: Scale filter text field based on breakpoints: 100% for mobile and tablet? */}
            <div className={classes.paperInput}>
              {schools.length > 0 ? (
                <TextField
                  className={classes.paperInputField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchSharpIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => handleFilter(e.target.value)}
                  placeholder="Search unrostered schools"
                  value={schoolsFilter}
                />
              ) : (
                <>
                  <Container className={classes.paperOtherContent}>
                    <Typography variant="h3">
                      <WarningSharpIcon className={classes.warningIcon} />
                    </Typography>
                    <Typography variant="body2">
                      There was a problem retrieving schools. Please verify that you have shared schools with the CPM rostering app.
                    </Typography>
                    <Button
                      aria-label="Settings"
                      className={classes.button}
                      color="primary"
                      onClick={handleClick}
                      startIcon={<SettingsSharpIcon />}
                      variant="contained"
                    >
                      Settings
                    </Button>
                  </Container>
                </>
              )}
            </div>
          </Paper>
          {schools.length > 0 && (
            <div className={classes.listsContainer}>
              <Paper className={classes.paperList} elevation={2} square>
                <div className={classes.list}>
                  <div className={classes.paperHeading}>
                    <Typography component="h3" variant="body1">
                      Unrostered
                    </Typography>
                  </div>
                  {/* TODO: Fix row height for mobile and tablet */}
                  {/* SEE react-virtualized CellMeasurer */}
                  <AutoSizer>
                    {({ height, width }) => (
                      <List>
                        <FixedSizeList
                          height={height}
                          itemCount={filteredUnrosteredSchools.length}
                          itemSize={57}
                          width={width}
                        >
                          {unrosteredRow}
                        </FixedSizeList>
                      </List>
                    )}
                  </AutoSizer>
                </div>
              </Paper>
              <Paper className={classes.paperList} elevation={2} square>
                <div className={classes.list}>
                  <div className={classes.paperHeading}>
                    <Typography component="h3" variant="body1">
                      Rostered
                    </Typography>
                  </div>
                  {/* TODO: Fix row height for mobile and tablet */}
                  {/* SEE react-virtualized CellMeasurer */}
                  <AutoSizer>
                    {({ height, width }) => (
                      <List>
                        <FixedSizeList
                          height={height}
                          itemCount={filteredRosteredSchools.length}
                          itemSize={57}
                          width={width}
                        >
                          {rosteredRow}
                        </FixedSizeList>
                      </List>
                    )}
                  </AutoSizer>
                </div>
              </Paper>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Schools;
