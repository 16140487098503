import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Box,
 } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ExclusionEntry, AddExclusion } from "./";

const useStyles = makeStyles((theme) =>
 createStyles({
   root: {
     flexGrow: 1,
   },
   paper: {
     padding: theme.spacing(2),
     marginBottom: theme.spacing(2),
     marginTop: theme.spacing(2),
     color: theme.palette.text.primary,
   },
   navigationButton: {
    marginBottom: theme.spacing(3)
   },  
   addButton: {
    // marginBottom: theme.spacing(3),
    color: theme.palette.secondary.main,
   },      
   margin: {
    margin: theme.spacing(1),
  },
  link: {
    color: theme.palette.secondary.main,
  }  
 }),
);

const NavAction = ({ myLink }) => {
    const classes = useStyles();
    return (
        <Link className={classes.link} to={`/exclusions/${myLink}`}>
            <ChevronRightIcon fontSize="large" />
        </Link>        
    )
}

 const Exclusions = () => {
    const exclusions = useSelector((state) => state.district.exclusions);

    const classes = useStyles();
    const history = useHistory(); 
    const handleGoToDashboard = () => {
        history.push('/dashboard');
    };
    
    const [showAdd, updateShowAdd] = useState(false);

     return (
         <div className={classes.root}>

        <Grid 
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
                <Button
                    aria-label="Back"
                    className={classes.navigationButton}
                    color="default"
                    onClick={handleGoToDashboard}
                    startIcon={<ArrowBackIosIcon />}
                    variant="contained"
                >
                    Back to Dashboard
                </Button>
         
        </Grid>
        <Alert severity="info">
            "Roster Exclusions" don't affect the import of enrollment data from the roster server, however any results matching the criteria blocks will be ignored when provisionsing eBook licenses. <br />        
        </Alert>
        <Alert severity="warning">        
            Advanced Feature - "Roster Exclusions" disrupts the anticpated flow of user records.  By enabling this feature you agree to review the exclusion settings prior to submitting a support ticket for missing results.
        </Alert>        
        <Box display="flex" justifyContent="space-between" m={1} >
            <h2>Roster Exclusions</h2>
            {!showAdd && (
            <Box alignSelf="center">
                <Button
                        aria-label="Add"                        
                        color="primary"
                        size="small"
                        onClick={() => {updateShowAdd(true)}}
                        endIcon={<AddCircleIcon />}
                        variant="contained"
                    >
                        Add
                </Button>      
            </Box>             
            )}
        </Box>        
                
                

                {showAdd && (
                    <AddExclusion handleDoneAdd={() => {updateShowAdd(false)}}/>
                )}


            {exclusions && exclusions.map((exclusion) => {
                return (
                    <ExclusionEntry key={exclusion._id} exclusion={exclusion} navAction={<NavAction myLink={exclusion._id} />} />                    
                )
            })}          
        

         </div>
   
         
     )
 }

 export { Exclusions };