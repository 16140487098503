import React from 'react';
import { DatePicker } from '@material-ui/pickers';

const EditDateField = ({fieldDisplay, fieldName, fieldValue, required=true, handleChange}) => { 
    
    return (    
        <DatePicker
            // error={isRequired && !fieldValue}
            // helperText={isRequired && !fieldValue ? "Please fill out this field" : ""}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={fieldDisplay}
            margin="normal"
            format="MM/DD/yyyy"
            name={fieldName}
            required={required}
            value={fieldValue || null}
            onChange={date => handleChange({ [fieldName]: date.format() } )}
            animateYearScrolling
        />
    )
}

export { EditDateField };