import React, {  useState, useEffect } from 'react';
import { DynamicField,  } from '../../Common/DynamicField';
import { getSAML } from '../../../actions';

import {
    Button,
    Box }
from '@material-ui/core';

const formDefaults = [
    // {
    //     fieldName: 'metaDataUrl',
    //     fieldDisplay: 'Meta data Link',
    //     fieldValue: '',
    //     fieldType: 'text',
    // },    
    {
      fieldName: 'displayName',
      fieldDisplay: 'Connection Name',
      fieldValue: '',
      fieldType: 'text',      
    },       
    {
        fieldName: 'entryPoint',
        fieldDisplay: 'Log in Url',
        fieldValue: '',
        fieldType: 'text',
    },    
    {
        fieldName: 'logoutUrl',
        fieldDisplay: 'Log out Url',
        fieldValue: '',
        fieldType: 'text',
    }  
  ];



const SAMLSettings = ( { ssoProvider }) => {
    const [formValues, updateFormValues] = useState(formDefaults);
    const [samlAssertion, updateSamlAssertion] = useState('');

    useEffect(() => {        
        if (ssoProvider && ssoProvider.isActive && ssoProvider.settings) {
            const { settings } = ssoProvider;
            const updatedValues = formValues.reduce((memo, item) => {
                const mergedValue = settings.hasOwnProperty(item.fieldName) ? {...item, fieldValue: settings[item.fieldName]} : item;
                return [...memo, mergedValue];
            }, []);
            updateFormValues(updatedValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ssoProvider]);

    const download = async () => {
        const myAssertion = await getSAML(ssoProvider.settings.id);
        updateSamlAssertion(myAssertion)
    }

    return (
        <div>
            
            
        {formValues.map((myField, myIndex) => (
            <DynamicField
                key={myIndex}
                editMode={false}
                variant="outlined"
                handleChange={() => {return null}}
                {...myField}
            />
            ))}

            <Box mt={6} />
    {ssoProvider && ssoProvider.active && ssoProvider.settings && (
        <div>
        <Button 
        onClick={download}
        variant='outlined'
        color='secondary'
        >GET CPM Assertion</Button>
            <Box mt={3} />
        <div>{samlAssertion}</div>
        </div>
        )}
        </div>
    )
}
export {
    SAMLSettings
};