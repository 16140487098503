import React, { useState } from 'react';


import { 
  Icon,
  makeStyles,  
  Paper,
  Tabs,
  Tab, 
} from '@material-ui/core'; 

import {

  TabPanel,
  TabContext }
 from '@material-ui/lab';


import { AdminDistricts } from "./AdminDistricts";
import { AdminLTI } from "./AdminLTI";

const useStyles = makeStyles((theme) => ({

  root: {
    
     width: '100%'
  },
  container: {
    width: '100%'
    
  },
  spinner: {
    minHeight: '50vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },  
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1),
  },
  table: {
    width: '100%'
  },
  link: {    
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  badStatus: {
    backgroundColor: theme.palette.warning.main
  },  

  searchTop: {
    clear: 'both',
    marginTop: '3em',
  }, 
  searchBottom: {
    clear: 'both',
    marginBottom: '.6em',
  },    
  hidden: {
    display: 'none',
  },
  
}));




const Admin = () => {
  const [tabIndex, setTabIndex] = useState("0");
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };


  const classes = useStyles();


  return (
    <>

<div className={classes.root}>
   <Paper square>
            
        <TabContext value={tabIndex}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            variant="fullWidth"
            centered
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
          >
            {/* <Tab icon={() => category && category.icon ? <Icon>{category.icon}</Icon> : <Icon>details_icon</Icon>} label="Overview" /> */}
            <Tab value="0" icon={<Icon>domain</Icon>} label="Districts" />
            <Tab value="1" icon={<Icon>cast_connected</Icon>} label="LTI" />
          </Tabs>
          <TabPanel value="0">
            <AdminDistricts />
          </TabPanel>          
          <TabPanel value="1">
            <AdminLTI />
          </TabPanel>                    
          
        </TabContext>
        </Paper>
      </div>

    
      </>
    
  )};

export {
  Admin
};
