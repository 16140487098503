import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosWithAuth } from 'utils/axiosWithAuth';
import { API_URL } from 'env';

const initialState = {
  entities: [],
  loading: false,
  error: ''
}

export const getLti = createAsyncThunk(
  'ltiLegacy/getLti',
  async (thunkAPI) => {
    try {
        const url = `${API_URL}/api/v1/lti/legacy`
        const response = await axiosWithAuth().get(url);
        return response.data;
      } catch (e) {
        return e.message;
      }  
});

export const createLti = createAsyncThunk(
    'ltiLegacy/createLti',
    async (thunkAPI) => {
      try {
          const url = `${API_URL}/api/v1/lti/legacy`
          const response = await axiosWithAuth().post(url);
          return response.data;
        } catch (e) {
          return e.message;
        }  
  });

export const deleteLti = createAsyncThunk(
    'ltiLegacy/deleteLti',
    async (consumerKey, thunkAPI) => {
      try {
          const url = `${API_URL}/api/v1/lti/legacy/${consumerKey}`
          await axiosWithAuth().delete(url);
          return consumerKey
        } catch (e) {
          return e.message;
        }  
  });


export const ltiSlice = createSlice({
  name: 'ltiLegacy',
  initialState,
  reducers: {},
  extraReducers: {
    [getLti.pending]: (state) => {
        state.loading = true;
        state.entities = [];
    },
    [getLti.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.entities = payload
    },
    [getLti.rejected]: (state) => {
      state.loading = false
    },

    [createLti.pending]: (state) => {
        state.loading = true;
    },
    [createLti.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.entities = [...state.entities, payload];
    },
    [createLti.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [deleteLti.pending]: (state) => {
        state.loading = true;
    },
    [deleteLti.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.entities = state.entities.reduce((memo, item) => {
        return item.consumerKey === payload ? memo : [...memo, item];
    }, []);
    },
    [deleteLti.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },     
  },
})

export const ltiLegacyReducer = ltiSlice.reducer;