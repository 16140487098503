import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { fetchJobList, fetchImportReport } from 'actions';
import { ImportReport, PreloadReport, JobLogPaged } from 'components/Jobs';
import { getMockELS, preloadResults, launchImport, launchImportUsePreload } from '../../utils/statelessCalls';

import {    
    Grid,
    Button,
    Paper,
    FormControl,
    Typography,   
    TextField,
    MenuItem,
    Box,
    AppBar,
    Tabs,
    Tab,
    Icon
} from '@material-ui/core';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
    formControl: {
        maxWidth: 300,
        minWidth: 200,
        width: '100%',
        marginBottom: theme.spacing(3)
      },
      navigationButton: {
        marginBottom: theme.spacing(3)
      },
      root: {
        width: '100%',
      },
      myButton: {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.accent.main
      },
      paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #fff',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center'
      },
      tabIndicatorColor: theme.palette.accent.main
}));

  
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`job-tabpanel-${index}`}
        aria-labelledby={`job-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `import-tab-${index}`,
      'aria-controls': `import-tabpanel-${index}`,
    };
  }
  

const ImportList = ( ) => {
    const { jobId } = useParams();

    const classes = useStyles();
    const history = useHistory();        
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = React.useState(0);
    const { data: jobList } = useSelector((state) => state.jobList, shallowEqual);
    const { data: reportData } = useSelector((state) => state.importReport, shallowEqual);
    const { slurpData } = useSelector((state) => state.district);  
    const [manualImportSubmitted, setManualImportSubmitted] = useState({submitted: false, error: false, message: '', counter: 0});
    
    const refreshList = () => {
        dispatch(fetchJobList('import'));
        setManualImportSubmitted({...manualImportSubmitted, message: `List Refreshed. ${manualImportSubmitted.counter || 0}`, counter: +manualImportSubmitted.counter + 1 });
    }
    useEffect(() => {       
        refreshList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    useEffect(() => {        
        if(jobId) {            
            dispatch(fetchImportReport(jobId));

            // manually launched jobs won't be in the job list.   Detect and refresh list if needed
            if (jobList.length) {
                if (!jobList.find(x => x._id === jobId)) {
                    refreshList();
                }
            }

        } else if (jobList.length > 0) {
            const latestJob = jobList[0];
            history.push(`/import/${latestJob._id}`);
        } else {
            console.error('Uh oh.  I don\t know what to do with this jobId');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId, jobList.length]);
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };
    
    const handleJobRefresh = () => {
        refreshList();
    };

      const download = () => {   
        getMockELS()
        .then(results => {
            const myJson = [JSON.stringify(results)];
            const blob1 = new Blob(myJson, { type: "text/plain;charset=utf-8" });
            const url = window.URL || window.webkitURL;
            const link = url.createObjectURL(blob1);
            const container = document.getElementById('data-container')
            const a = document.createElement("a");
            a.download = "staged_data.json";
            a.href = link;
            container.appendChild(a);
            a.click();
            container.removeChild(a);
            
        }).catch((err) => {
          console.log(err.message);
        })
      }

    const handleGoToDashboard = () => {
      history.push("/dashboard");
      };
    const handleOnChange = (e) => {
        const { value } = e.target;
        setTimeout(() => {
            dispatch(fetchImportReport(value));
            history.push(`/import/${value}`);
        }, 200);
        
      };


    const handleImportClick = async() => {
        try {
            setManualImportSubmitted({submitted: true, counter: 0, message: 'Roster import has been launched.  Imports can take anywhere between 1 minute to 2 hours for large configurations.'});   
            setTabValue(1);
            const { jobId: newJobId } = await launchImport();
            history.push(newJobId);
        } catch(err) {
            const message = err && err.response && err.response.data ? err.response.data : "Unknown error";
            setManualImportSubmitted({submitted: true, error: true, message });
        }
    }

    const handlePreloadClick = async() => {
        try {           
            setManualImportSubmitted({submitted: true, counter: 0, message: 'Preload job has been launched.  Imports can take anywhere between 1 minute to an hour for large configurations.'});
            setTabValue(1);
            const { jobId: newJobId } = await preloadResults();            
            history.push(newJobId);
        } catch(err) {
            const message = err && err.response && err.response.data ? err.response.data : "Unknown error";
            setManualImportSubmitted({submitted: true, error: true, message });
        }
    }

    const handleCsvImportClick = async() => {
        try { 
            setManualImportSubmitted({submitted: true, counter: 0, message: 'Process job has been launched.  Imports can take anywhere between 1 minute to an hour for large configurations.'});
            setTabValue(1);       
            const { jobId: newJobId } = await launchImportUsePreload();
            history.push(newJobId);
        } catch(err) {
            const message = err && err.response && err.response.data ? err.response.data : "Unknown error";
            setManualImportSubmitted({submitted: true, error: true, message });
        }
    }    


    return (
        <>
        {/* required for JSON to download */}
        <div id="data-container" />
        
        <Grid
        container
        direction="column"
        justifyContent="space-between"
        >   
            <Grid item>
                <Button
                    aria-label="Back"
                    className={classes.navigationButton}
                    color="default"
                    onClick={handleGoToDashboard}
                    startIcon={<ArrowBackIosIcon />}
                    variant="contained"
                >
                    Back to Dashboard
                </Button>              
            </Grid>


            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"                
                className='fadeIn'
            >

                <FormControl className={classes.formControl} >
                    <TextField
                        label="Import Reports"
                        name="jobId"
                        onChange={handleOnChange}
                        select
                        value={jobId || ''}
                        variant="outlined"
                        required
                    >              
                        {jobList.map((job, index) => (
                        <MenuItem key={index} value={job._id}>
                            {job.date} - {job.hasOwnProperty('success') ? job.success ? 'complete' : 'failed' : job.state}
                        </MenuItem>
                        ))}
                    
                    </TextField>
                </FormControl>
                    {slurpData ? (
                        <div>
                        <Button
                            variant='contained'
                            className={classes.myButton}
                            onClick={handlePreloadClick}
                            startIcon={<Icon>downloading</Icon>}
                        >PreLoad
                        </Button>
                        
                        <Button
                            variant='contained'
                            className={classes.myButton}
                            style={{ marginLeft: '1em' }}
                            onClick={handleCsvImportClick}
                            startIcon={<Icon>input</Icon>}
                        >Process
                        </Button>
                        <Button
                            variant='contained'
                            className={classes.myButton}
                            style={{ marginLeft: '1em' }}
                            onClick={handleImportClick}
                            startIcon={<Icon>published_with_changes</Icon>}
                        >Preload & Process
                        </Button>                        
                        </div>                                            
                    ) : (
                        <div>
                        <Button
                                variant='contained'
                                className={classes.myButton}
                                onClick={handleImportClick}
                                startIcon={<CloudDownloadIcon />}
                        >Manual Import
                        </Button>                                             
                    </div>
                    )}

                        <div>
                            <Button
                                style={{ marginLeft: '1em' }}
                                variant='contained'                       
                                color='primary'
                                onClick={download}
                                startIcon={<GetAppIcon />}
                            >Data
                            </Button>                                                
                        </div>
            </Grid>
            <Grid item>
                {manualImportSubmitted && manualImportSubmitted.submitted && (
                    <Alert severity={manualImportSubmitted.error ? 'error' :  'success'}>{manualImportSubmitted.message}</Alert>        
                )}
            </Grid>

            <Paper elevation={2} square>
                <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor='secondary' aria-label="Job results using tabbed navigation">
                        <Tab label={`${reportData.jobType === 'preload' ? 'Preload Results' : 'Import Results'}`} {...a11yProps(0)} />
                        <Tab label="Logs" {...a11yProps(1)} />          
                    </Tabs>
                </AppBar>

                <TabPanel value={tabValue} index={0}>
                    {reportData && reportData.jobType === 'preload' ? (                        
                        <PreloadReport />
                    ) : (
                        <ImportReport />                        
                    )}

                    {reportData && !reportData.success && reportData.state === 'running' && (                    
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                        className="quarter-page-load"
                        alignItems="center">
                         <Grid item xs={12}>
                             Report is Running
                        </Grid>    
                        <Grid item xs={12}>
                        <Button
                            variant='contained'
                            className={classes.myButton}
                            onClick={handleJobRefresh}
                            endIcon={<Icon>restore_page</Icon>}
                        >Refresh
                        </Button>
                        </Grid>
                    </Grid>
                    )}           
                </TabPanel>
                <TabPanel value={tabValue} index={1}> 
                    <JobLogPaged jobId={jobId} refreshList={refreshList}/>
                </TabPanel>        
            </Paper>
          </Grid>


         </>
    );
};


export {
    ImportList 
};