import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,  
  CircularProgress,
  LinearProgress,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Divider,
} from '@material-ui/core';

import BookIcon from '@material-ui/icons/Book';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  avatar: {
    backgroundColor: theme.palette.info.main,
    height: 56,
    width: 56
  },
  paper: {    
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.info.light,
    padding: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.info.light,    
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingTitle: {
    marginRight: theme.spacing(2)
  },
  viewButton: {    
    color:theme.palette.primary.dark
  },
  link: {
    textDecoration: 'none',
    color: '#fff'
  },
  dividerMargins: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  spinner: {
    minHeight: '50vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },  
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.info.main,
  },
  
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.main,    
  },
}))(LinearProgress);

const BorderLinearDangerProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.info.main,
  },
  
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.danger.main,    
  },
}))(LinearProgress);

const RenderEbookUtilization = ({item}) => {

  const myPct = Math.round(item.usedPct * 100 || 0) || 0;
  return (
    <div>
    <Grid    
      container
      direction="row"
      justifyContent="space-between"
    >
      <div>      
        {item.name}      
      </div>
      {item.overages > 0 && (
        <div>      
          {item.overages} overages
        </div>
      )}
      
    </Grid>

    
    {myPct > 90 ? (
      <BorderLinearDangerProgress
        value={myPct}
        variant="determinate"
    />
    ) : (
      <BorderLinearProgress
        value={myPct}
        variant="determinate"
      />
    )}
    
    <Grid    
      container
      key={item}
      direction="row"
      justifyContent="space-between"
    >
      <div>
        {item.used  || 0}  / {item.count || 0}
      </div>
      <div>
        {Math.round(item.usedPct * 100 || 0) || 0}%
      </div>
    </Grid>
    
    </div>
  )
}


const RenderMyEbooks = ({ report, licenseType }) => {
  
  const classes = useStyles();
  const { eBooks } = report;
  if (eBooks) {
    return  Object.keys(eBooks).map((item) => {        
      return (        
          <div key={item}>
            <RenderEbookUtilization key={item} item={eBooks[item][licenseType] || {}}></RenderEbookUtilization>
            <Divider className={classes.dividerMargins} />
          </div>
      )          
    })
    } else {
      return <></>
    }
}

const SyncEbookReport = (  ) => {
  const classes = useStyles();
  const { data: report, isLoading } = useSelector((state) => state.pushReport, shallowEqual);

  return (

    <>
        {isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress color="secondary" size={100} />
          </div>
        ) : (

          <Grid
          container
          direction="column"
          justifyContent="space-around"
          alignItems="stretch"
          style={{ minHeight: '20vh'}}
          className='fadeIn'
         >  
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={1}
          >
          <Grid item md={6} xs={12}>
            <Card>
                <CardHeader
                  title="Student Licenses"                
                  action={
                    <Avatar aria-label="teacher license" className={classes.avatar}>
                        <BookIcon />
                    </Avatar>
                  }
                >         
                  </CardHeader>
                  <Divider />
                  <CardContent>
                    <RenderMyEbooks report={report} licenseType='student' />
                  </CardContent>
                </Card>          
          </Grid>            
          <Grid item md={6} xs={12}>
            <Card>
                <CardHeader
                  title="Teacher Licenses"                
                  action={
                    <Avatar aria-label="student license" className={classes.avatar}>
                        <CollectionsBookmarkIcon />
                    </Avatar>
                  }
                >         
                  </CardHeader>
                  <Divider />
                  <CardContent>
                    <RenderMyEbooks report={report} licenseType='teacher' />
                  </CardContent>
                </Card>          
          </Grid>    
        </Grid>
      </Grid>
      )}
    </>
  );
};
export {
  SyncEbookReport
};

