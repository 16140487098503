import React, { createRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { uploadSAML } from '../../../actions';
import { 
    Button,
    Box,
    Grid,
    Icon
} from '@material-ui/core';  
const MIME_TYPE = 'application/xml'
const useStyles = makeStyles((theme) => ({
    invalidButton: {
      color: '#fff',
      backgroundColor: theme.palette.danger.main,
      '&:hover': {
        backgroundColor: theme.palette.danger.dark,
      },      
    },
    successButton: {
        color: '#fff',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
        },
    }
  }));

const SAMLUpload = ({ handleCancel }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const form = createRef();
    const history = useHistory();
    const [file, setFile] = useState();
    const [noFileError, setNoFileError] = useState(false);


    const handleFileChange = async (e) => {
        setNoFileError(false);
        const newFile=e.target.files[0];
        if (newFile && (newFile.type === MIME_TYPE || newFile.type === 'text/xml')) {
            setFile(newFile);
            if (form.current.reportValidity()) {
                const formData = new FormData();
                formData.append('file', newFile);

                try {
                    await dispatch(uploadSAML(formData));
                    return history.replace('./');
                } catch (err) {
                    console.log(err)
                }                
            }
        }
        else { 
            setNoFileError(true);
        }
    }

    return (
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="quarter-page-load fadeIn"
      >   
        <form ref={form} onSubmit={e => e.preventDefault()} style={{width: '100%'}}>
            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            >
                <h3>Configure SAML using IdP configuration file</h3>
            </Grid>


             <Box display="flex" justifyContent="space-between">
                 
                <Box mr={3} flexGrow={1}>
                
                    <Button
                        fullWidth
                        className={noFileError ? classes.invalidButton : file ? classes.successButton : ''}
                        variant="contained"
                        component="label"
                        onChange={handleFileChange}
                        startIcon={<Icon>file_copy</Icon>}
                    >
                    {file ? (
                        <span>{file.name}</span>
                    ) : (
                        <span>Select XML File</span>
                    )}                           
                        <input
                            type="file"
                            accept={MIME_TYPE}
                            hidden
                        />
                    </Button>

                    </Box>
                    <Box>
                    <Button
                        fullWidth
                        variant="outlined"
                        component="label"
                        color="secondary"
                        onClick={handleCancel}
                    >
                    Cancel
                    </Button>
                    </Box>                
              </Box> 
        </form>
              </Grid> 
    )
}

export {
    SAMLUpload
}