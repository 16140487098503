import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Button,
    Box,
    Divider,
    Grid,
    Paper,        
    Typography
  } from '@material-ui/core';
  import { Alert, AlertTitle } from '@material-ui/lab';
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TableCell from '@material-ui/core/TableCell';
  import TableContainer from '@material-ui/core/TableContainer';
  import TableHead from '@material-ui/core/TableHead';
  import TableRow from '@material-ui/core/TableRow';
  
  import { getLti, createLti, deleteLti } from '../../../reducers/ltiReducer';

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    margin: {
      margin: theme.spacing(1),
    },    
  }));


 
  const LTI1 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const { entities, loading } = useSelector((state) => state.ltiLegacy, shallowEqual);

  
  useEffect(() => {
    dispatch(getLti());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    dispatch(createLti());
  }

  const handleDelete = (consumerKey) => {
    dispatch(deleteLti(consumerKey));
  }

    return (
      <div className={classes.root}>
          <Paper elevation={3}>
            <Box p={3}>
              <h3>LTI v1.1</h3>
              <Divider />
              <Box p={1} />
              <Alert severity="warning">
                <AlertTitle>Important notice on LTI v1.1</AlertTitle>
                  As of June 2021, IMS LTI v1.1 is deprecated.  All new configurations should use LTI 1.3.
                  
              </Alert>
              <Box p={3} />
              {!loading && !entities.length && (
                <Grid
                    container
                    direction="row"
                    spacing={3}
                    alignItems="center"
                    justifyContent="center"
                    className={classes.root} 
                  >              
                    <Grid item>
                      <Button color="primary" variant="contained" onClick={handleCreate}>Generate Credentials</Button>               
                    </Grid>                  
                </Grid>
              )}

              {Array.isArray(entities) && entities.length > 0 && (
              <TableContainer component={Paper}>
                <Table aria-label="LTI Entities">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      
                      <TableCell>Credentials</TableCell>
                                   
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entities.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Button 
                            size="small"
                            variant="outlined" 
                            color="secondary" 
                            className={classes.margin}
                            onClick={() => handleDelete(row.consumerKey)}
                            >
                            delete
                          </Button>
                        </TableCell>

                        <TableCell>

                          <Typography
                          color="textSecondary"
                          variant="caption"  
                          >
                          Consumer Key
                          </Typography>
                          <Typography
                          
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                          >
                          {row.consumerKey}
                          </Typography>
                    

                          <Typography
                          color="textSecondary"
                          variant="caption"  
                          >
                          Consumer Secret
                          </Typography>
                          <Typography
                          
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                          >
                          {row.consumerSecret}
                          </Typography>
                        
                       </TableCell>
                       
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              )}
              {/* <Box p={3} /> */}
              {/* <Box display="flex" justifyContent="center">
                  <Button color="primary" variant="contained">Generate</Button>
              </Box> */}
              
                {/* What is it?
                <br />
                Provides SSO integration of CPM's online curriculum with all major LMS providers.  Depending on your LMS and configuration, CPM curriculum is displayed directly in the LMS, or opens in a new window.
                <br />
                <br /> */}

                </Box>
              
          </Paper>    
          </div>
    )
}

export {
    LTI1
}