import React from 'react';
import {
    Snackbar,
    Slide
  } from '@material-ui/core';
  import {
    Alert
  } from '@material-ui/lab';  

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
}
  
const CustomSnackbar = ( { handleClose, snackbarState: { open, message, severity }}) => {
    return (
        <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        open={open}   
        message={message}
        key={SlideTransition.name}
        >
            <Alert 
                onClose={handleClose}
                severity={severity}

                >
            {message}
            </Alert>
        </Snackbar>           
    )
}

export { CustomSnackbar };