import {
    AUTHENTICATE_USER_REQUEST,
    AUTHENTICATE_USER_SUCCESS,
    AUTHENTICATE_USER_FAILURE,
    AUTHENTICATE_NAV_ADMIN,
    UNSET_ADMIN_MODE,
    INIT_ADMIN,
    SET_ADMIN_IMPERSONATE,
    LOGOUT_USER
} from '../actions/actionTypes';
import { makeStateFromToken } from '../actions';


let initialState = {
  isLoading: false,
  isAdmin: false,
  isDistrictAdmin: false,
  isAuthenticated: false,
  unauthorizedUser: true,
  adminMode: false,  // needed?
  email: null,
  family_name: null,
  given_name: null,
  preferred_username: null,
  triggerNavToAdminDashboard: false,
  realm_access: {
    districts: [],
    roles: []
  },
  sub: null  
}

const token = document.cookie.replace(/(?:(?:^|.*;\s*)cpm-sso-token\s*=\s*([^;]*).*$)|^.*$/,'$1');

if (token) {
  initialState = {...initialState, ...makeStateFromToken(token)};
}


const authReducer = ((state = initialState, action) => {
  switch (action.type) {   
    case INIT_ADMIN:
     if (!action.payload.isAdmin) {
        return state;
      } else if (action.payload.impersonateId) {
        state = { ...state, adminMode: false, impersonateId: action.payload.impersonateId };
      } else {
        state = {...state, adminMode: true };
      }
      return {...state,  isAdmin: true};
    case AUTHENTICATE_USER_REQUEST:
      return {...initialState, isLoading: true };
    case UNSET_ADMIN_MODE:      
    return { ...state, impersonateId: null, adminMode: true };
    case SET_ADMIN_IMPERSONATE:
      return {...state, impersonateId: action.payload, adminMode: false };
    case AUTHENTICATE_USER_SUCCESS:
      try {
        return {...state, ...action.payload, isLoading: false, isAuthenticated: true };
      } catch (err) {        
        console.log('bad login payload');
        // return { ...state, isAuthenticated: false };
      }
      break;
    case AUTHENTICATE_USER_FAILURE:
      console.log('login failure');
      return state;   
    case AUTHENTICATE_NAV_ADMIN:
      return { ...state, triggerNavToAdminDashboard: action.payload };
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
});

export default authReducer;
