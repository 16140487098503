import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosWithAuth } from 'utils/axiosWithAuth';
import { LTI_API_URL } from 'env';

// const samplePlatform = {
//   "url":"https://moodle-test.cpm.org",
//   "clientId":"testme",
//   "name":"Test Config",
//   "authenticationEndpoint":"https://moodle-test.cpm.org/mod/lti/auth.php",
//   "accesstokenEndpoint":"https://moodle-test.cpm.org/mod/lti/token.php",
//   "authConfig": {
//       "method":"JWK_SET",
//       "key":"https://moodle-test.cpm.org/mod/lti/certs.php"
//     },
//     "publicKey":"-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAy01XWnd3ZNEQiV/y6U/k\nK2EfMHyBEWcDWYh3kPv3wxhd5cH8rBaAINxNHj3r9Ut32ZWjv9v65xA2Teve1Kkz\nixY/3LcejK+GtxuUsmqlQ6CQ9N/3USeiW0qv0vgnZjvknwsI/CHiUWXqzFmkmJo3\nYxRKx/IuXnT168TZiUJD/7LngTba6FtsnYlLN0T9TJWcvToMi8EC8JFkx83B69SL\nisc0NKkq1DYsrKP8kFqe3vhl6FXvIjI41ysjb9RCkMML8jEBlTysSzoq7mdG83VU\n/4GxsXYyKqDnqwrpNd2NGMX8VNA8RVtPQU7nWSWuMC/ruU8mGVR0IaXGTuyA80Hm\nQZW7VgLoq1iJqfPn1D7Ze5uBZC28sXQfiKC4ddC4HM8NYQH8mvhE/LIIF92EAKJm\npWsl4DV4SBo0q6kalvxUEzN6b7cM0AAvD8JVi+/YgtC7YhORZ93lzo2+v1dwoKQi\ncHHdwRXskQ16pUGwskCNh+Y4+c9hZZkPTHGIrvBkrGIJPjMjSoWZ776BRAIBvlg5\n169vkPZg+lvElS1RFE9bwmG9FATn7dGDhDMe6uaKo+UttFSrk5oS7AUMW0K0UMD3\n39KeZzp2EhgtekEDQqr1/Ag20RcUCzFNmC/7+zQ0z16CkCyB8Z87WiTGe0QwXvdl\nFwqr7k2KAdflsJ49q1IwrfECAwEAAQ==\n-----END PUBLIC KEY-----\n"
//   }
const samplePlatform = {
  "clientId":"",
  "name":"",  
  }


const initialState = {   
  platforms: [],
  isLoading: false,
  error: '',
  linkRequestId: null
};

export const fetchLtiPlatformList = createAsyncThunk(
  'ltiAdvantage/fetchLtiPlatformList',
  async () => {    
        const url = `${LTI_API_URL}/api/integrations/platforms`
        const response = await axiosWithAuth().get(url);        
        return response.data;
});

export const updateLTIPlatform = createAsyncThunk(
  'ltiAdvantage/updateLTIPlatform',
  async ({platformId, platform }) => {
    try {
      const url = `${LTI_API_URL}/api/integrations/platform/${platformId}`
      const response = await axiosWithAuth().patch(url, platform);
      return response.data;
    } catch (e) {
      throw new Error(`${e.response.status}: ${e.response.data || e.response.statusText}`);
    }
    
});

export const saveAddLTIPlatform = createAsyncThunk(
  'ltiAdvantage/saveAddLTIPlatform',
  async ({platform }) => {
    try {   
        const url = `${LTI_API_URL}/api/integrations/platform`
        const response = await axiosWithAuth().post(url, platform);
        return response.data;
      } catch (e) {
        throw new Error(`${e.response.status}: ${e.response.data || e.response.statusText}`);
      }        
});


export const linkLTIPlatform = createAsyncThunk(
  'ltiAdvantage/linkLTIPlatform',
  async ( clientId ) => {
    try {   
        const url = `${LTI_API_URL}/api/integrations/platform/link/${clientId}`
        const response = await axiosWithAuth().post(url);
        return response.data;
      } catch (e) {
        throw new Error(`${e.response.status}: ${e.response.data || e.response.statusText}`);
      }        
});

export const deleteLTIPlatform = createAsyncThunk(
  'ltiAdvantage/deleteLTIPlatform',
  async ( platformId ) => {
    try {
        const url = `${LTI_API_URL}/api/integrations/platform/${platformId}`
        const response = await axiosWithAuth().delete(url);
        return response.data;
    } catch (e) {
      throw new Error(`${e.response.status}: ${e.response.data || e.response.statusText}`);
    }
});


export const ltiAdvantageDistrictSlice = createSlice({
  name: 'ltiAdvantageDistrict',
  initialState,
  reducers: {
    seedLtiPlatform(state) {
      if(!state.platforms.find(platform => !platform.id)) {
        state.platforms.unshift(samplePlatform);
      }
    },
    removeSeedLtiPlatform(state) {
      state.platforms = state.platforms.filter(platform => platform.id);
    }    
  },
  extraReducers: {
    // GET LIST
    [fetchLtiPlatformList.pending]: (state) => {
      state.loading = true;
      state.platforms = [];      
    },
    [fetchLtiPlatformList.fulfilled]: (state, { payload }) => {
      state.loading = false; 
      state.platforms = payload;
    },
    [fetchLtiPlatformList.rejected]: (state, { payload }) => {    
      state.loading = false;
      state.error = payload;
    }, 
    // UPDATE
    [updateLTIPlatform.pending]: (state, action) => {
      const { meta: { arg: { platformId }}} = action;
      state.platforms = state.platforms.reduce((platforms, platform) => {
        return platform.id === platformId ? [...platforms, platform] : [...platforms, platform];
      }, []);
     
      state.loading = true;
    },
    [updateLTIPlatform.fulfilled]: (state, { meta: { arg: { platformId }}, payload }) => {
      state.loading = false;
      state.platforms = state.platforms.reduce((platforms, platform) => {
        return platform.id === platformId ? [...platforms, payload] : [...platforms, platform];
      }, []);
    },
    [updateLTIPlatform.rejected]: (state, action) => {
      const { error : { message }, meta: { arg: { platformId }}} = action;
      state.loading = false;
      state.platforms = state.platforms.reduce((platforms, platform) => {
        return platform.id === platformId ? [...platforms, {...platform, error: message }] : [...platforms, platform];
      }, []);
    }, 


    // ADD
    [saveAddLTIPlatform.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [saveAddLTIPlatform.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.platforms = state.platforms.reduce((platforms, platform) => {
        return !platform.id ? [...platforms, payload] : [...platforms, platform];
      }, []);
    },
    [saveAddLTIPlatform.rejected]: (state, action) => {    
      state.loading = false;
      const { error : { message } } = action;
      state.platforms = state.platforms.reduce((platforms, platform) => {
        return !platform.id ? [...platforms, {...platform, error: message }] : [...platforms, platform];
      }, []);      
    }, 

    // LINK
    [linkLTIPlatform.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [linkLTIPlatform.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.platforms = state.platforms.reduce((platforms, platform) => {
        return !platform.id ? [...platforms, payload] : [...platforms, platform];
      }, []);
    },
    [linkLTIPlatform.rejected]: (state, action) => {
      state.loading = false;
      const { error : { message } } = action;
      state.platforms = state.platforms.reduce((platforms, platform) => {
        return !platform.id ? [...platforms, {...platform, error: message }] : [...platforms, platform];
      }, []);
    },


    // DELETE
    [deleteLTIPlatform.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteLTIPlatform.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      const { meta: { arg: platformId }} = action;
      state.platforms = state.platforms.filter(platform => platform.id !== platformId);
    },
    [deleteLTIPlatform.rejected]: (state, action) => {
      const { error : { message }, meta: { arg: platformId }} = action;
      state.loading = false;
      state.platforms = state.platforms.reduce((platforms, platform) => {
        return platform.id === platformId ? [...platforms, {...platform, error: message }] : [...platforms, platform];
      }, []);  
    }
  },
})


export const { seedLtiPlatform, removeSeedLtiPlatform } =  ltiAdvantageDistrictSlice.actions;
export const ltiAdvantageDistrictReducer = ltiAdvantageDistrictSlice.reducer;


