
import {
  FETCH_SCHOOLS_FAILURE,
  FETCH_SCHOOLS_REQUEST,
  FETCH_SCHOOLS_SUCCESS,
  REMOVE_INVALID_SCHOOL,
  RESET_SCHOOLS_FILTER,
  SET_SCHOOLS_FILTER,
  TOGGLE_ROSTER_SCHOOL
} from '../actions/actionTypes';

const initialState = { schools: [], invalid: [], filter: '', isLoading: false, error: '' };

const schoolsReducer = ((state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHOOLS_FAILURE:
      return {...state, isLoading: false, error: action.payload };
    case FETCH_SCHOOLS_REQUEST:
      return {...state, isLoading: true, error: '' };
    case FETCH_SCHOOLS_SUCCESS:
      return {...state, schools: action.payload.schools, invalid: action.payload.invalid, isLoading: false, error: '' };
    case RESET_SCHOOLS_FILTER:
      return {...state, filter: '' };
    case SET_SCHOOLS_FILTER:
      return {...state, filter: action.payload };      
    case TOGGLE_ROSTER_SCHOOL: {
      const rostered = action.method === 'post';
      const updatedState =  state.schools.map( item => {        
        return (item.schoolId === action.payload.schoolId ) ? {...item, rostered } : item;
      })
      return {...state, schools: updatedState };
    }
    case REMOVE_INVALID_SCHOOL:
      return {...state, invalid: state.invalid.filter(x => x.schoolId !== action.payload.schoolId)};
    default:
      return state;
  }
});

export default schoolsReducer;
