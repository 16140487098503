import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress
  } from '@material-ui/core';
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    table: {
      minWidth: '650',
    },
    paper: {
      backgroundColor: theme.palette.info.light,
      paddingTop: '1em',
      paddingBottom: '1em',
      paddingLeft: '1em'
    },
    errorRow: {
        backgroundColor: theme.palette.warning.main        
    },
    spinner: {
      minHeight: '50vh',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(6)
    },

  }));
  
  
const SyncStats = () => {
    const classes = useStyles(); 
    const {  data, isLoading } = useSelector((state) => state.pushReport, shallowEqual);
    return (
      <>
        {isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress color="secondary" size={100} />
          </div>
        ) : (      

        <Grid item lg={12} md={12}>
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="staged licenses table">
              <TableHead>
                <TableRow>
                  <TableCell>Event</TableCell>
                  <TableCell>Succeeded</TableCell>
                  <TableCell>Failed</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='fadeIn'>
                {data && data.statistics && Object.keys(data.statistics).map(index => (
                  <TableRow key={index} className={data.statistics[index].failed ? classes.errorRow : ''}>
                    <TableCell scope="row">
                      {index}
                    </TableCell>
                    <TableCell>{data.statistics[index].succeeded}</TableCell>
                    <TableCell>{data.statistics[index].failed}</TableCell>
                    <TableCell>{data.statistics[index].total}</TableCell>
  
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        )}
        </>
    )
}

export { SyncStats };