const API_URL = process.env.REACT_APP_API_URL || window.REACT_APP_API_URL;
const LTI_API_URL = process.env.REACT_APP_LTI_API_URL || window.REACT_APP_LTI_API_URL;
const LTIAAS_API_URL = process.env.REACT_APP_LTIAAS_API_URL || window.REACT_APP_LTIAAS_API_URL;


const SSO_REDIRECT = process.env.REACT_APP_SSO_REDIRECT || window.REACT_APP_SSO_REDIRECT;

// const REACT_APP_BASE = process.env.REACT_APP_URL || window.REACT_APP_URL;
// const PORT = process.env.PORT || 3000;
// const REACT_APP_URL = `${REACT_APP_BASE}:${PORT}`
const REACT_APP_URL = process.env.REACT_APP_URL || window.REACT_APP_URL;

export {
    API_URL,
    LTI_API_URL,
    SSO_REDIRECT,
    REACT_APP_URL,
    LTIAAS_API_URL
}