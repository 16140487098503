import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { 
    Grid,
    makeStyles,
    Avatar,
    Typography,
    InputAdornment,
    TextField,
    MenuItem,
    FormControl
 } from '@material-ui/core';

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  IconButton
} from '@material-ui/core';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import RecordVoiceOverSharpIcon from '@material-ui/icons/RecordVoiceOverSharp';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';

import {
  fetchEworkspaceTeachers,
  enableEworkspace,
  disableEworkspace
} from 'actions';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      width: '100%',
      flexDirection: 'column',
      height: '100%',
      paddingLeft: '1em',
      paddingRight: '1em'
    },
    active: {
      backgroundColor: theme.palette.success.main
    },
    paperInputField: {
      marginBottom: theme.spacing(3),
      width: '100%'
    },    
    formControl: {
      maxWidth: 300,
      minWidth: 200,
      width: '100%',
      marginBottom: theme.spacing(3)
    },    
  }));


  const GetTeacherAvatar = ({ teacher }) => {
    const classes = useStyles();
    return (
      <>
      {teacher && teacher.eWorkspace ? (
        <Avatar className={classes.active}>
          <RecordVoiceOverSharpIcon/>
        </Avatar>
      ) : (
        <Avatar >
        <PersonSharpIcon />
        </Avatar>
      )}
    </>
    )
  }



const EWorkspaceTeachers = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    //const work = useSelector((state) => state.EWorkspace, shallowEqual);
    const { teachers, schools } = useSelector((state) => state.eWorkspace, shallowEqual);
    const [searchFilter, setSearchFilter] = useState('');
    const [schoolId, setSchoolId] = useState();
    
    const filteredTeachers =  teachers.filter(x => {
      return (
            (x.firstName.indexOf(searchFilter) >= 0)
        ||  (x.lastName.indexOf(searchFilter) >= 0)
        ||  (x.email.indexOf(searchFilter) >= 0)
      );
      }
    );


    useEffect(() => {
      dispatch(fetchEworkspaceTeachers(schoolId));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolId]);

    const handleEnableWorkspace = (teacher ) => {
      dispatch(enableEworkspace(teacher));
    }

    const handleDisableWorkspace = (teacher ) => {
      dispatch(disableEworkspace(teacher));
      
    }    

    return (
      <>
    <div className={classes.root}>      
      <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          >
          <Grid item>
            <TextField
              className={classes.paperInputField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchSharpIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchFilter(e.target.value)}
              placeholder="Search teachers"
              value={searchFilter}
            />
          </Grid>
          <Grid item>

              <FormControl className={classes.formControl} >
                    <TextField
                        label="Filter School"
                        name="schoolId"
                        onChange={(e) => setSchoolId(e.target.value)}
                        select
                        value={schoolId || ''}
                        variant="outlined"
                        required
                    >              
                        <MenuItem value=''>
                            All
                        </MenuItem>                    
                        {schools && schools.map((school) => (
                        <MenuItem key={school.schoolId} value={school.schoolId}>
                            {school.schoolName}
                        </MenuItem>
                        ))}
                    
                    </TextField>
              </FormControl>
          </Grid>
    
    </Grid>
    </div>
      <List dense={true} className={classes.root}>
        {filteredTeachers && filteredTeachers.map((teacher) => (

        
      <ListItem divider key={teacher.rosterSourceId || teacher.email}>
      <ListItemAvatar>
        
          <GetTeacherAvatar teacher={teacher} />
        
      </ListItemAvatar>
      <ListItemText
        id={teacher.rosterSourceId}
        primary={
          <Typography variant="body2">
            {teacher.lastName}, {teacher.firstName}            
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        {teacher && teacher.eWorkspace ? (
          <IconButton
          aria-label="Remove Teacher"
          edge="end"
          onClick={() => handleDisableWorkspace(teacher)}
        >
          <RemoveSharpIcon />
        </IconButton>
        ) : (
        <IconButton
          aria-label="Add Teacher"
          edge="end"
          onClick={() => handleEnableWorkspace(teacher)}
        >
          <AddSharpIcon />
        </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
        ))}
    </List>   
    </>
    )
    
}

export {
  EWorkspaceTeachers
};