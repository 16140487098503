import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,  
  Divider,
  FormControl,
  TextField,
  MenuItem,  
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';



import { updateLicensePool, fetchLicensePools, activateLicensePool } from 'actions';
import { InfoBox } from 'components/Common/InfoBox';
const useStyles = makeStyles((theme) => ({
  alertButton: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    }
  },  
}));

const LicensePool = () => {
  const dispatch = useDispatch();
  const licensePools = useSelector((state) => state.licensePools, shallowEqual);
  const district = useSelector((state) => state.district, shallowEqual);
  const [myAlert, setMyAlert] = useState();

  const classes = useStyles();

  const activatePool = (status) => {
    dispatch(activateLicensePool(status));
  }

  const handleChange = (e) => {
    const { id, groupName } = licensePools.find(x => x.id === e.target.value);
    dispatch(updateLicensePool({ licensePoolId: id, licensePoolName: groupName }));
  };


  useEffect(() => {
    dispatch(fetchLicensePools());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      if(typeof district.licensePoolId === 'number') {
        const isPoolRostered = licensePools.find(x => x.id === district.licensePoolId)?.isRostered || undefined;
      
      if (isPoolRostered) {
         setMyAlert(
          <>
            <Alert severity="success">
              <AlertTitle>Valid</AlertTitle>
              License pool is set for automated rostering administration only
            </Alert>
            <Button
                aria-label="Set License Pool to manual"
                className={classes.button}
                color="primary"
                variant="contained"            
                fullWidth={true}
                onClick={() => {activatePool(false)}}
            >Set Manual
            </Button>
          </>
          );
      } else {
         setMyAlert(
            <>                 
              <Alert severity="error">
                <AlertTitle>Attention Required</AlertTitle>
                License pool is set for manual administration only.
              </Alert>
              <Button
                aria-label="Set License Pool to rostered"
                className={classes.alertButton}
                color="primary"
                variant="contained"              
                fullWidth={true}
                onClick={() => {activatePool(true)}}
              >Set Rostered
              </Button>
          </>
          );
      }
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensePools, district.licensePoolId]);

  const MyTooltip = () => {
    return (
      <>
      <Typography variant="subtitle2">
          CPM eBook licenses are grouped togther in &quot;Pools&quot;.  Most School Districts will only have 1 license pool.
        </Typography>
        <Divider />
        <Typography variant="subtitle2">
          License pools must be in &quot;Rostered&quot; mode before the automated rostering process can assign licenses to the incoming roster users.
        </Typography>

      </>
    )

  }
  return (
    <InfoBox title='License Pool' tooltip={MyTooltip()} >
        <FormControl  component="fieldset">
        <TextField
          label="License Pool"
          name="rosterPool"
          onChange={handleChange}
          select
          value={district.licensePoolId || ''}
          variant="outlined"
          required
        >              
          {licensePools.map((pool, index) => (
            <MenuItem key={index} value={pool.id}>
              {pool.groupName}
            </MenuItem>
          ))}
        
        </TextField>


      </FormControl>

      {myAlert}

  </InfoBox>      
    
  );
};

export {
  LicensePool
};