import { axiosWithAuth, axiosFile } from 'utils/axiosWithAuth';
import { API_URL } from 'env';
import { 
    FETCH_SSO_REQUEST,
    FETCH_SSO_SUCCESS,
    FETCH_SSO_FAILURE,
    SSO_UPDATE_REQUEST,
    SSO_UPDATE_SUCCESS,
    SSO_UPDATE_FAILURE,
} from './actionTypes';

export const getSSOProfiles = () => async (dispatch) => {
    try {
      dispatch({ type: FETCH_SSO_REQUEST });
      const url = `${API_URL}/api/v1/sso`
      const { data } = await axiosWithAuth().get(url);
      dispatch({
        type: FETCH_SSO_SUCCESS,
        payload: data
      });
    } catch (e) {
      dispatch({ 
        type: FETCH_SSO_FAILURE,
        payload: e.response.statusText
      });
    }
  };

  export const updateTenantSSO = (value) => async (dispatch) => {
    try {
      dispatch({ type: SSO_UPDATE_REQUEST });
      const url = `${API_URL}/api/v1/sso/tenant`
      const { data } = await axiosWithAuth().put(url, value);
      dispatch({
        type: SSO_UPDATE_SUCCESS,
        payload: data
      });
    } catch (e) {
      dispatch({ 
        type: SSO_UPDATE_FAILURE,
        payload: e.response.statusText
      });
    }
  };
  

  export const addSocialSSO = (socialProvider) => async (dispatch) => {
    try {
      dispatch({ type: SSO_UPDATE_REQUEST });
      const url = `${API_URL}/api/v1/sso/social`
      const { data } = await axiosWithAuth().post(url, socialProvider);
      dispatch({
        type: SSO_UPDATE_SUCCESS,
        payload: data
      });
    } catch (e) {
      dispatch({ type: SSO_UPDATE_FAILURE, payload: e.response.statusText });
    }    
  }

  export const updateSocialSSO = (socialProvider) => async (dispatch) => {
    try {
      // dispatch({ type: SSO_UPDATE_REQUEST });
      const url = `${API_URL}/api/v1/sso/social`
      const { data } = await axiosWithAuth().put(url, socialProvider);
      dispatch({
        type: SSO_UPDATE_SUCCESS,
        payload: data
      });
      return true;
    } catch (e) {
      dispatch({ type: SSO_UPDATE_FAILURE, payload: e.response.data || e.response.statusText });
      throw e;
    }    
  }  

  export const deleteSocialSSO = (id) => async (dispatch) => {
    try {
      dispatch({ type: SSO_UPDATE_REQUEST });
      const url = `${API_URL}/api/v1/sso/social/${id}`
      const { data } = await axiosWithAuth().delete(url);
      dispatch({
        type: SSO_UPDATE_SUCCESS,
        payload: data
      });
    } catch (e) {
      dispatch({ 
        type: SSO_UPDATE_FAILURE,
        payload: e.response.statusText
      });
    }    
  }
  

  export const uploadSAML = (file) => async (dispatch) => {
    try {
      const url = `${API_URL}/api/v1/sso/saml/xml`
      const { data } = await axiosFile().post(url, file);
      dispatch({
        type: SSO_UPDATE_SUCCESS,
        payload: data
      });
      return true;
    } catch (e) {
      dispatch({ type: SSO_UPDATE_FAILURE, payload: e.response.statusText  });
      throw e;
    }    
  }  

  export const importSAML = (formData) => async (dispatch) => {
    // expects formData to be { url: string }
    try {
      const uri = `${API_URL}/api/v1/sso/saml/url`
      const { data } = await axiosFile().post(uri, formData);
      dispatch({
        type: SSO_UPDATE_SUCCESS,
        payload: data
      });
      return true;
    } catch (e) {
      dispatch({ type: SSO_UPDATE_FAILURE, payload: e.response.statusText });
      throw e;
    }    
  }

  export const getSAML = async (configId) =>  {
    try {
      const url = `${API_URL}/api/v1/sso/saml/${configId}`

      const { data } = await axiosWithAuth().get(url);      
      return data;
    } catch (e) {
      
      throw e;
    }    
  }  

  export const addSAML = (samlConfig) => async (dispatch) => {
    try {
      
      const url = `${API_URL}/api/v1/sso/saml`
      const { data } = await axiosWithAuth().post(url, { samlConfig });
      dispatch({
        type: SSO_UPDATE_SUCCESS,
        payload: data
      });
      return true;
    } catch (e) {
      dispatch({ type: SSO_UPDATE_FAILURE, payload: e.response.message });
    }    
  }  
  export const updateSAML = (configId, samlConfig) => async (dispatch) => {
    try {
      const url = `${API_URL}/api/v1/sso/saml/${configId}`

      const { data } = await axiosWithAuth().put(url, { samlConfig });
      dispatch({
        type: SSO_UPDATE_SUCCESS,
        payload: data
      });
      return true;
    } catch (e) {
      dispatch({ type: SSO_UPDATE_FAILURE, payload: e.response.message });
      throw e;
    }    
  }  
  export const deleteSAML = (configId) => async (dispatch) => {
    dispatch({type: SSO_UPDATE_REQUEST});    
    try {
      const url = `${API_URL}/api/v1/sso/saml/${configId}`
      const { data } = await axiosWithAuth().delete(url);
      dispatch({
        type: SSO_UPDATE_SUCCESS,
        payload: data
      });
      return true;
    } catch (e) {
      dispatch({ type: SSO_UPDATE_FAILURE, payload: e.response.message });
      throw e;
    }    
  }    