import React from 'react';
import NumberFormat from 'react-number-format'; 
import moment from 'moment';

const DynamicFormat = (props) => {
    const {  fieldType='text', fieldValue } = props;    
    return (
        <>
            {(() => {            
                switch (fieldType) {
                    case 'time':
                        return (        
                            moment(fieldValue).local().format('h:mm:ss a')
                        )
                    case 'date':
                        return (        
                            moment(fieldValue).local().format('MMM DD, YYYY')
                        )
                    case 'utc-date':                        
                        return (
                            moment(fieldValue).utcOffset(0, false).format('MMM DD, YYYY')
                        )
                    case 'utc-time':                        
                        return (
                            moment(fieldValue).utcOffset(0, false).format('h:mm:ss a')
                        )
                    case 'currency':
                        return (        
                            <NumberFormat 
                                value={fieldValue}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                />
                        )
                    case 'number':                        
                        return (        
                            <NumberFormat 
                            value={fieldValue || '0'}
                            displayType={'text'}
                            thousandSeparator={true}                            
                            />
                        )
                   
                    default:
                        return (        
                            fieldValue
                        )
                }
            
            })()}

        </>
    )
}

export { DynamicFormat };