import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { debounce } from 'lodash';

import rootReducer from 'reducers';
// import * as actionCreators from '../actions';

const debounceNotify = debounce((notify) => notify());


// const composeEnhancers = composeWithDevTools({
//   actionCreators, 
//   trace: true, 
//   traceLimit: 25 
// }); 

// export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, debounceNotify)))

export default createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk),
    batchedSubscribe(debounceNotify),
//    applyMiddleware(composeEnhancers)
  )
);
