import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SyncEbookReport, SyncNumbers, JobLogPaged, SyncStats, SyncWarnings } from 'components/Jobs';
import { launchPush } from '../../utils/statelessCalls';
import { DialogConfirm } from "../../components/Common/DialogConfirm";
import {
    Box,
    Grid,
    Button,
    FormControl,
    Paper,
    TextField,
    MenuItem,
    Typography,
    AppBar,
    Tabs,
    Tab
} from '@material-ui/core';


import Alert from '@material-ui/lab/Alert';
import SwapVertOutlinedIcon from '@material-ui/icons/SwapVertOutlined';
import { fetchJobList, fetchPushReport } from 'actions';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`job-tabpanel-${index}`}
        aria-labelledby={`job-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `import-tab-${index}`,
      'aria-controls': `import-tabpanel-${index}`,
    };
  }

const useStyles = makeStyles((theme) => ({
    formControl: {
        maxWidth: 300,
        minWidth: 200,
        width: '100%',
        marginBottom: theme.spacing(3)
      },
      navigationButton: {
        marginBottom: theme.spacing(3)
      },
      root: {
        width: '100%'
      },
      myButton: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.accent.main
    },   
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      }
}));

const LicenseSyncList = ( ) => {
    const { jobId } = useParams();
    
    const classes = useStyles();
    const history = useHistory();        
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = useState(0);
    
    const [open, setOpen] = useState(false);
    const [manualPushSubmitted, setManualPushSubmitted] = useState({submitted: false, error: false, message: ''});
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };

    const jobList = useSelector((state) => state.jobList.data, shallowEqual);
    
    const refreshList = () => {
        dispatch(fetchJobList('push'));
        // setManualImportSubmitted({...manualImportSubmitted, message: `List Refreshed. ${manualImportSubmitted.counter || 0}`, counter: +manualImportSubmitted.counter + 1 });
    }

    useEffect(() => {
        if(jobId) {
            refreshList();
            dispatch(fetchPushReport(jobId));            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);

    

    const handleGoToDashboard = () => {
        history.push('/dashboard');
      };
    const handleOnChange = (e) => {
        const { value } = e.target;
        setTimeout(() => {
          history.push(value);
      }, 200);
    };

    const openPushDialog = async() => {
        setOpen(true);
    } 
    const handleCancelPush = async() => {
      setOpen(false);
  }           
    const handleConfirmPush = async() => {
      setOpen(false);
      try {
          await launchPush();
          setManualPushSubmitted({submitted: true, message: 'License Sync has been launched.  Syncs can take between 1 to 10 minutes to complete.'});   
      } catch(err) {
          setManualPushSubmitted({submitted: true, error: true, message: 'Unable to launch license sync. Please contact support.'});
      }
    }


    return (         
      <>
            <DialogConfirm 
              open={open}
              handleCancel={handleCancelPush}
              handleConfirm={handleConfirmPush}
              title='Sync Licenses'
              description='CPM licenses will be replaced with the latest staged roster data.  This cannot be undone.'
            />

        <Grid
          container
          direction="column"
          justifyContent="space-between"
          > 
            <Grid item>
                <Button
                    aria-label="Back"
                    className={classes.navigationButton}
                    color="default"
                    onClick={handleGoToDashboard}
                    startIcon={<ArrowBackIosIcon />}
                    variant="contained"
                >
                    Back to Dashboard
                </Button>              
            </Grid>

            
        <Grid
        container
        direction="row"
        justifyContent="space-between"
        >
            <Grid item>
                <FormControl className={classes.formControl} >
                    <TextField
                        label="License Sync Reports"
                        name="jobId"
                        onChange={handleOnChange}
                        select
                        value={jobId || ''}
                        variant="outlined"
                        required
                    >              
                        {jobList.map((job, index) => (
                        <MenuItem key={index} value={job._id}>
                            {job.date} - {job.success ? 'success' : 'failed' }
                        </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item>
              <SyncNumbers />
            </Grid>
            <Grid item>
              <Button
                  style={{ marginLeft: '1em' }}
                  variant='contained'
                  className={classes.myButton}
                  startIcon={<SwapVertOutlinedIcon />}       
                  onClick={openPushDialog}
                  >Resync
              </Button>
            </Grid>
            <Grid item md={12}>
                {manualPushSubmitted && manualPushSubmitted.submitted && (
                    <Alert severity={manualPushSubmitted.error ? 'error' :  'success'}>{manualPushSubmitted.message}</Alert>        
                )}
            </Grid>         
            <Grid item lg={12} md={12}>
                <Paper elevation={2} square>     
                <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor='secondary' aria-label="staged results tabbed navigation">
                    <Tab label="License Report" {...a11yProps(0)} />
                    <Tab label="Logs" {...a11yProps(1)} />
                    <Tab label="Stats" {...a11yProps(2)} />
                    <Tab label="Warnings" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>                  
                    <SyncEbookReport />   
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <JobLogPaged jobId={jobId} />   
                </TabPanel>   
                <TabPanel value={tabValue} index={2}>
                    <SyncStats />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <SyncWarnings />
                </TabPanel>
            </Paper>

            </Grid>
          </Grid>

         </Grid>
         </>
        );

};
export {
    LicenseSyncList 
};