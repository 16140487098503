import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Button
} from '@material-ui/core';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SyncIcon from '@material-ui/icons/Sync';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { InfoBox } from 'components/Common/InfoBox';
import { resetSchedule } from 'actions';

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: theme.palette.info.main,
    fontSize: '56px',
    '& small': {
      fontSize: '24px'
    }    
  },    

}));

const JobStatus = () => {
  const classes = useStyles();
  const JobStatusOptions = 
  {
    unknown: {      
      display: 'Unknown Run Status',
      icon: <HelpOutlineIcon className={classes.infoIcon} />
    },
    running: {
      display: 'Job Running',
      icon: <SyncIcon className={`${classes.infoIcon} icon-spinner`} />
    },
    scheduled: {
      display: 'Queued',
      icon: <AccessTimeIcon className={classes.infoIcon} />
    },
    failed: {
      display: 'Last Job Failed',
      icon: <SyncProblemIcon className={classes.infoIcon} />
    }
  }
  

  const [jobStatus, updateJobStatus] = useState(JobStatusOptions.unknown);
  const { scheduleStatus } = useSelector((state) => state.district, shallowEqual);
  const { isAdmin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    updateJobStatus(getJobStatus(scheduleStatus))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleStatus]);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(resetSchedule());
  };
  
  const getJobStatus = (myStatus) => {
    if (typeof myStatus !== 'string') {
      return JobStatusOptions['unknown']
    }
    if (myStatus.includes("RUNNING")) {
      return JobStatusOptions['running']
    }
    if (myStatus.includes("INACTIVE") || myStatus.includes("COMPLETED")) {
      return JobStatusOptions['scheduled']
    }    
    if (myStatus.includes("FAILED")) {
      return JobStatusOptions['failed']
    } 
    if (myStatus.includes("SCHEDULED")) {
      return JobStatusOptions['scheduled']
    }        
  }

  return (
    <InfoBox title='Job Status' tooltip='Indicates whether a job is currently running' >
    <Box grow={1} />
    {jobStatus && jobStatus.icon}
        <Typography variant="h6">
        {jobStatus && jobStatus.display}
        </Typography>
        <Box grow={1} />
        {isAdmin && (


        <Button
              aria-label="Reset Job Schedulers"
              className={classes.button}
              color="primary"
              fullWidth={true}
              onClick={handleClick}
              variant="contained"
            >
              Reset Schedule State
            </Button>      
        )}
    </InfoBox>
  );
};

export {
  JobStatus
};
