import React, { createRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTenantSSO } from "../../actions";
// import { Alert, AlertTitle } from '@material-ui/lab';
import { 
  Box,
  Button,
  Grid,
  Divider,
} from '@material-ui/core';
import { DynamicField } from '../Common/DynamicField';




const TenantSSO = ( { ssoProvider }) => {
    const dispatch = useDispatch();
    const form = createRef();
    const [formValues, updateFormValues] = useState(ssoProvider.settings);
    const [editMode, updateEditMode] = useState(false);

    useEffect(() => { 
      if(ssoProvider && ssoProvider['settings'] && !ssoProvider['settings']['tenantId']) {
        updateEditMode(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {       
      updateFormValues(ssoProvider.settings)
    }, [ssoProvider.settings]);

    const handleChange = (value) => {
      updateFormValues({...formValues, ...value});
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(form.current.reportValidity()) {
        const fullFormValues = {...formValues, type: ssoProvider.type};
        dispatch(updateTenantSSO(fullFormValues));
      }
    }

    // const handleDelete = async (e) => {
    //   e.preventDefault();      
    //     const fullFormValues = {tenantId: '', type: ssoProvider.type};
    //     dispatch(updateTenantSSO(fullFormValues));
    // }

    return (
        <form ref={form} onSubmit={e => e.preventDefault()} noValidate>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <h3>{ssoProvider.displayName} Settings</h3>
                {!editMode && (
                <Button
                  aria-label="Save"
                  color="secondary"
                  size="small"                            
                  variant="outlined"
                  onClick={() => updateEditMode(true)}
                >
                Edit
              </Button>                              
                )}
            </Box>
            <Divider />
            <Box m={3}/>
              {Object.keys(formValues).map(key => (    
                <DynamicField
                  key={key}
                  editMode={editMode}
                  variant="outlined"
                  handleChange={handleChange}
                  fieldName={key}
                  fieldValue={formValues[key]}                  
                  fieldDisplay={`${ssoProvider.displayName} ID`}
                  fieldType='text'
                />
              ))}
              <Box m={6}/>
            {editMode && (
            <Grid
                container
                direction="row-reverse"
                justifyContent="space-between"
                alignItems="baseline"    
              >
                <Grid item>
                  <Button
                      aria-label="Save"
                      color="primary"
                      type="submit"
                      variant="outlined"
                      onClick={handleSubmit}
                  >Save</Button>
                  {ssoProvider.isActive && (   
                  <Button
                      aria-label="Cancel"
                      color="secondary"
                      className='button-list'
                      type="button"
                      variant="outlined"
                      onClick={() => updateEditMode(false)}
                  >Cancel</Button>
                   )}
                </Grid>
                {/* {ssoProvider.isActive && editMode && (   
                <Button
                      aria-label="Delete"
                      color="secondary"
                      type="button"
                      variant="contained"
                      onClick={handleDelete}
                  >Delete</Button>
                )} */}
                </Grid>
                )}
            </form>
    )
}

export {
  TenantSSO
};