import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Grid,
    Paper,
    Tooltip,
    Typography,
  } from '@material-ui/core';

  import HelpIconSharp from '@material-ui/icons/HelpSharp';
const useStyles = makeStyles((theme) => ({
    paper: {    
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    paperContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(3)
    },
    paperHeading: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      display: 'flex',
      margin: 0,
      padding: theme.spacing(2)
    },
    paperHeadingTitle: {
      marginRight: theme.spacing(0.5)
    },
    tooltip: {
      backgroundColor: theme.palette.success.main,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1)
    }           
  }));
  
/**
 * 
 * @param {alignContent} 'center' | 'start' | 'end'
 * @returns 
 */
const InfoBox = ({title, tooltip='', children, alignContent='center'}) => {

  const classes = useStyles();

  return (
        <Paper className={classes.paper} elevation={2} square>
          <div className={classes.paperHeading}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >   
              <Typography
                className={classes.paperHeadingTitle}
                component="h1"
                variant="body1"
              >
                {title}
              </Typography>
              {tooltip && (
              <Tooltip
                arrow
                classes={{
                  arrow: classes.arrow,
                  tooltip: classes.tooltip
                }}
                placement="right"
                title={
                  <div className={classes.tooltip}>
                    <Typography variant="subtitle2">
                      {tooltip}
                    </Typography>
                  </div>
                }
              >
                <HelpIconSharp aria-label="Help Text" fontSize="small" />
              </Tooltip>
              )}
              </Grid>
            </div>
            <div className={classes.paperContent} style={{"alignItems": alignContent}}>
                {children}
          </div>
        </Paper>
      );
};

export {
    InfoBox
};
