import { combineReducers } from 'redux';
import { setAutoFreeze } from 'immer';

import districtReducer from './districtReducer';
import schoolsReducer from './schoolsReducer';
import coursesReducer from './coursesReducer';
import licensesReducer from './licensesReducer';
import licensePools from './licensePoolReducer';
import { stickyLicensesReducer } from './stickyLicensesReducer';
import teachersReducer from './teachersReducer';
import pushReportReducer from './pushReportReducer';
import importReportReducer from './importReportReducer';

import jobList from './jobListReducer';
import { adminDistrictsReducer } from './adminDistrictsReducer';
import auth from './authReducer';
import eWorkspaceReducer from './eWorkspaceReducer';
import districtElsReducer from './districtElsReducer';
import excludedReducer from './excludedReducer';
import ssoReducer from './ssoReducer';
import { ltiLegacyReducer } from './ltiReducer';
import { ltiAdvantageDistrictReducer } from './ltiAdvantageDistrictReducer';
import { ltiAdvantageAdminReducer } from './ltiAdvantageAdminReducer';
setAutoFreeze(false);

const rootReducer = combineReducers({
  district: districtReducer,
  schools: schoolsReducer,
  courses: coursesReducer,
  licenses: licensesReducer,
  licensePools,
  stickyLicenses: stickyLicensesReducer,
  teachers: teachersReducer,
  pushReport: pushReportReducer,
  importReport: importReportReducer,
  jobList,
  districts: adminDistrictsReducer,
  auth,
  eWorkspace: eWorkspaceReducer,
  districtEls: districtElsReducer,
  excludedRecords: excludedReducer,
  sso: ssoReducer,
  ltiLegacy: ltiLegacyReducer,
  ltiAdvantage: ltiAdvantageDistrictReducer,
  ltiAdvantageAdmin: ltiAdvantageAdminReducer
});

export default rootReducer;
