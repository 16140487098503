import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosWithAuth } from 'utils/axiosWithAuth';
import { API_URL } from 'env';
const thunkName = 'stickylicenses';

export const fetchStickyLicenses = createAsyncThunk(
  `${thunkName}/fetchStickyLicenses`,
  async () => {
      const response = await axiosWithAuth().get(
          `${API_URL}/api/v1/district/sticky`
          );
          return response.data;
});

export const fetchStickyLicenseCounts = createAsyncThunk(
  `${thunkName}/fetchStickyLicensesCounts`,
  async () => {
      const response = await axiosWithAuth().get(
          `${API_URL}/api/v1/district/sticky/counts`
          );
          return response.data;
});

export const addStickyLicenseProfile = createAsyncThunk(
  `${thunkName}/addStickyLicenseProfile`,
  async (profile, thunkApi) => {
      const response = await axiosWithAuth().post(
          `${API_URL}/api/v1/district/sticky`,
          profile
        );
        return response.data;
});
export const editStickyLicenseProfile = createAsyncThunk(
  `${thunkName}/editStickyLicenseProfile`,
  async (profile, thunkApi) => {
    const { _id: stickyLicenseId } = profile;
      const response = await axiosWithAuth().patch(
          `${API_URL}/api/v1/district/sticky/${stickyLicenseId}`,
          profile
        );
        return response.data;
});
export const deleteStickyLicenseProfile = createAsyncThunk(
  `${thunkName}/deleteStickyLicenseProfile`,
  async (profile, thunkApi) => {
    const { _id: stickyLicenseId } = profile;
      await axiosWithAuth().delete(
          `${API_URL}/api/v1/district/sticky/${stickyLicenseId}`,
          profile
        );
        return stickyLicenseId;
});

export const appendStickyLicense = createAsyncThunk(
  `${thunkName}/appendStickyLicense`,
  async (license, thunkApi) => {
      const { stickyLicenseId } = license;
      const response = await axiosWithAuth().post(
          `${API_URL}/api/v1/district/sticky/${stickyLicenseId}`,
          license
        );
        return response.data;
});


export const deleteStickyLicense = createAsyncThunk(
  `${thunkName}/deleteStickyLicense`,
  async ( { stickyLicenseId, eBookId }, thunkApi) => {
      const response = await axiosWithAuth().delete(`${API_URL}/api/v1/district/sticky/${stickyLicenseId}/license/${eBookId}`);      
      return response.data;
});