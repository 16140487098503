import { useEffect, useRef  } from "react";
/**
 * 
 * @param {*} callback 
 * @param {*} delay 
 * @example 
 * const [pollingInterval, updatePollingInterval] = useState(3000);
 * UseIntervalHook(async () => {
        if(jobId) {
           dispatch(fetchImportReport(jobId));
        } else {
            updatePollingInterval(5000000);
        }
   }, pollingInterval)
 * 
 * 
 * 
 * 
 * 
 */
const UseIntervalHook = (callback, delay) => {
    const savedCallback = useRef();
    // Remember the latest callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            }
        }
    }, [callback, delay]);
}

export {
    UseIntervalHook
};