import React  from 'react';
import {     
    Grid,
    Box,
    Divider,    
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import { LTI1, LTIAvantage } from "./components";
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    }));
const LTI = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} fadeIn`}>
        <Grid container spacing={3}>
              <Grid item sm={12} xs={12}>
                  <Divider />
                  
                  <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="baseline">
                      <h2>LTI Integrations</h2>
                    
                      <Box flex={1} />
                    
                  </Grid>
                  <Divider />
              </Grid>
          
        </Grid>        
        <Box mt={6} />
        <LTIAvantage />
        <Box mt={6} />
        <LTI1 />
        
    </div>
    )
}

export {
    LTI
};