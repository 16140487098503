import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { 
  CircularProgress,
  Grid,
  Divider,
  colors,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ClassSharpIcon from '@material-ui/icons/ClassSharp';

import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import DashboardBox from 'components/DashboardBox';
import { testRosterCredentials, fetchStickyLicenseCounts, fetchLatestImportReport, fetchLatestPushReport } from 'actions';
import { IntegrationStatus } from 'components/IntegrationStatus';
import { DistrictOverview } from 'components/DistrictOverview';
import {  ImportDashboard, SyncDashboard } from 'components/Jobs';
import {  JobStatus } from 'components/JobStatus';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
  errorMessage: {
    alignItems: 'center',
    borderRadius: 0,
    display: 'flex',
    minHeight: '64px'
  },
  gridContainer: {
    // maxWidth: '1040px',
    minHeight: '64px'
  },
  link: {
    textDecoration: 'none'
  },
  spinner: {
    minHeight: '50vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    width: '100%'
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  },
  successIcon: {
    color: theme.palette.secondary.light,
    fontSize: '56px',
  },
  successIconSmall: {
      color: theme.palette.success.main,
      fontSize: '24px'    
  },  
  infoIcon: {
    color: theme.palette.info.main,
    fontSize: '56px',
    '& small': {
      fontSize: '24px'
    }    
  },  
  warningIcon: {
    color: colors.orange,
    fontSize: '56px',    
  },
  warningIconSmall: {
    color: colors.orange[600],
    fontSize: '24px'    
  },
  uncheckedIconSmall: {
    color: colors.blueGrey[600],
    fontSize: '24px'    
  },  
  errorIconSmall: {
    color: colors.red[600],
    fontSize: '24px'    
  },
}));

const Dashboard = () => {
  const { districtId } = useSelector((state) => state.district);
  const exclusionTerms = useSelector((state) => state.district.exclusions.length);

  const stickyLicenses = useSelector((state) => state.stickyLicenses.counts);

  
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (districtId) {
      dispatch(fetchStickyLicenseCounts());    
      dispatch(fetchLatestImportReport());
      dispatch(fetchLatestPushReport());
      dispatch(testRosterCredentials());  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtId]);
  

  return (
    <>
      {!districtId ? (
        <div className={`${classes.spinner} fadeIn`}>
          <CircularProgress color="secondary" size={100} />
        </div>
      ) : (
        <div className={`${classes.root} fadeIn`}>


        <Grid className={classes.gridContainer} container spacing={3}>

        
          <Grid item sm={12} xs={12}>        
              <Divider />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                >              
                  <h2>Automated Rostering Settings</h2>
                  {/* <div>
                  <Link className={classes.link} to="/checklist">
                    <Button
                              variant='contained'                      
                              endIcon={<DoneAllIcon />}
                          >Checklist
                    </Button>

                  </Link>
                  <Link className={classes.link} to="/setup">
                    <Button
                      className={classes.marginLeft}
                              variant='contained'                      
                              endIcon={<TuneIcon />}
                          >Setup Wizard
                    </Button>
                  </Link>                  
                  </div> */}
                </Grid>
              <Divider />
          </Grid>




            <Grid item lg={6} md={12} sm={12} xs={12}>


            <DistrictOverview />


              
            </Grid>


            <Grid item lg={6} md={12} sm={12} xs={12}>
              
                <IntegrationStatus />
              
            </Grid>

          <Grid item sm={12} xs={12}>        
              <Divider />
                <h2>Job Status</h2>
              <Divider />          
            </Grid>
            
            <Grid item lg={4} md={6} sm={6} xs={12}>              
              <JobStatus />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>              
              <ImportDashboard />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>              
              <SyncDashboard />
            </Grid>

            
            <Grid item sm={12} xs={12}>        
              <Divider />
                <h2>Data Explorer</h2>
              <Divider />          
            </Grid>

            <Grid item md={4} sm={4} xs={12}>
              <Link className={classes.link} to="/explorer">
                <DashboardBox
                  content={{
                    numOfResources: <SearchIcon  style={{fontSize:'1.25em'}} />,
                    resourceText: 'Explore current course enrollments'
                  }}
                  headingIcon={<ClassSharpIcon />}
                  headingTitle="Course Enrollments"
                  helpText="View course enrollment data (oneRoster only)"
                  buttonText="View"
                />
              </Link>
            </Grid>

            



            <Grid item sm={12} xs={12}>        
              <Divider />
                <h2>Overrides</h2>
              <Divider />          
            </Grid>

            <Grid item md={4} sm={4} xs={12}>
              <Link className={classes.link} to="/sticky/teacher">
                <DashboardBox
                  content={{
                    numOfResources: stickyLicenses.total,
                    resourceText:
                    stickyLicenses.total === 1
                        ? 'Sticky License'
                        : 'Sticky Licenses'
                  }}
                  headingIcon={<PersonPinCircleIcon />}
                  headingTitle="Sticky Licenses"
                  helpText="Assign sticky licenses to teachers"
                />
              </Link>
            </Grid>

            <Grid item md={4} sm={4} xs={12}>
              <Link className={classes.link} to="/exclusions">
                <DashboardBox
                  content={{
                    numOfResources: exclusionTerms,
                    resourceText: 'Exclusion Criteria'                
                  }}
                  headingIcon={<PersonPinCircleIcon />}
                  headingTitle="Exclusions"
                  helpText="Assign sticky licenses to teachers"
                />
              </Link>
            </Grid>

          </Grid>
        </div>
      )}
    </>
  );
};

export default Dashboard;
