import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText, Box, Icon } from '@material-ui/core';
import { unsetAdminMode } from "actions";

const SideNav = ({ handleDrawerToggle, mobileOpen }) => {
  const { districtName, districtId } = useSelector((state) => state.district);
  
  const { 
    // adminMode, 
    isAdmin, 
    // unauthorizedUser 
  } = useSelector((state) => state.auth);  
  // const onboardingComplete = useSelector((state) => state.district.onboardingComplete);
  

  const dispatch = useDispatch();
  
  const adminLinks = [{
    icon: <Icon>settings</Icon>,
    name: 'Admin',
    pathname: '/admin',
    onClick: () => dispatch(unsetAdminMode())
  }];
  
  // const setupLinks = [
  //   {
  //     icon: <CastForEducationIcon />,
  //     name: 'Roster Setup',
  //     pathname: '/setup'
  //   },    
  // ];
  
  const maintenanceLinks = [
    {
      icon: <Icon>sync_alt</Icon>,
      name: 'Rostering',
      pathname: '/dashboard'
    },
    {
      icon: <Icon>vpn_key</Icon>,
      name: 'Single Sign-On',
      pathname: '/sso'
    }, 
    {
      icon: <Icon>cast_for_icon</Icon>,
      name: 'LMS (LTI)',
      pathname: '/lti'
    },
    {
      icon: <Icon>school</Icon>,
      name: 'eWorkspace',
      pathname: '/eworkspace'
    },     
  ];

  let links = [];

  if (isAdmin) {
    links = [...adminLinks];
  }

  if(districtId) {
    const defaultLinks = maintenanceLinks;
    // const defaultLinks = onboardingComplete ? maintenanceLinks : setupLinks;
    links = [...links, ...defaultLinks];
  }

  // const links = isAdmin ? [...adminLinks, ...maintenanceLinks] : maintenanceLinks;
  const toggleDrawer = () => {
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  

  return (
    <>
      <div style={{ position: 'relative' }}>
        <List component="nav"> 
            {links.map((link) => (
              <NavLink
                activeStyle={{
                  color: 'rgb(14, 108, 67)',
                  textDecoration: 'underline'
                }}

                key={link.name}
                onClick={toggleDrawer}
                style={{
                  color: 'inherit',
                  textDecoration: 'none'
                }}
                to={link.pathname}
              >
                <ListItem  onClick={link.onClick}>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.name} />
                </ListItem>
              </NavLink>
            ))}

        </List>
      </div>
      <div style={{ position: 'absolute', bottom: '24px', left: '24px', textAlign: 'center' }}>
        {districtName} <br /> 
        <Box color="accent.main" >
        {districtId}
        </Box>
      </div>
    </>
  );
};

export { SideNav };
