import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import MomentUtils from '@date-io/moment';
import axios from 'axios';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';  
import * as serviceWorker from 'serviceWorker';

import store from 'store';

import App from 'containers/App';

// import { REACT_APP_URL } from "./env/index"

// Add a request interceptor
// axios.defaults.origin = REACT_APP_URL;

axios.defaults.origin =
  process.env.REACT_APP_URL ||
  window.REACT_APP_URL ||
  'http://localsso.cpm.org:3000';


ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}> 
    <Router>
      <App />
    </Router>
    </ MuiPickersUtilsProvider> 
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
