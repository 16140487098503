import produce from 'immer';

import {
    FETCH_EWORKSPACE_REQUEST,
    FETCH_EWORKSPACE_SUCCESS,
    FETCH_EWORKSPACE_FAILURE,
    ENABLE_EWORKSPACETEACHER_REQUEST,
    ENABLE_EWORKSPACETEACHER_SUCCESS,
    DISABLE_EWORKSPACETEACHER_REQUEST,
    DISABLE_EWORKSPACETEACHER_SUCCESS,
} from '../actions/actionTypes';

const initialState = { error: null, schools: [], teachers: [], isLoading: false, isTeacherUpdating: false};

const eWorkspaceReducer = produce((draft, action) => {
  switch (action.type) {   
    case FETCH_EWORKSPACE_REQUEST:
      return { ...initialState, isLoading: true };
    case FETCH_EWORKSPACE_FAILURE:
      return { ...initialState, isLoading: false, error: action.payload };
    case FETCH_EWORKSPACE_SUCCESS:
      return { ...initialState, ...action.payload };
    case ENABLE_EWORKSPACETEACHER_REQUEST:
      return { ...draft, isTeacherUpdating: true };
    case ENABLE_EWORKSPACETEACHER_SUCCESS:
      return { ...draft, isTeacherUpdating: false, teachers: draft.teachers.map(x => {
          if (x.email === action.payload.email) {
            return action.payload;
          } else {
            return x
          }
      }) };
    case DISABLE_EWORKSPACETEACHER_REQUEST:
      return { ...draft, isTeacherUpdating: true };      
    case DISABLE_EWORKSPACETEACHER_SUCCESS:
      return { ...draft, isTeacherUpdating: false, teachers: draft.teachers.map(x => {
        if (x.email === action.payload.email) {
          return {...x, eWorkspace: false};
        } else {
          return x
        }
    }) };      
      
    default:          
      return draft;
  }
}, initialState);

export default eWorkspaceReducer;
