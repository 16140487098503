import React, { useEffect, useState, useReducer, createRef }  from 'react';
import { shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { ExclusionResults2 } from './ExclusionResults2'
import {
    FormControl,
    MenuItem,
    Grid,
    IconButton,
    Button,
    Box,
    TextField,    
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import { getFilterableFields } from '../../utils/statelessCalls';
import { addExclusionCriteria } from '../../actions';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
      },  
    addButton: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },    
    navigationButton: {
      marginBottom: theme.spacing(3)
    },
    paper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3)
    },
    selectStyle: {
    //   marginBottom: theme.spacing(1),
    //    marginTop: theme.spacing(1),
      width: '100%'
    },
    submit: {
      marginTop: theme.spacing(2)
    },
    successIcon: {
      color: theme.palette.success.dark,
      fontSize: '56px'
    },
    warningIcon: {
      color: '#ff9800',
      fontSize: '56px'
    },
    deleteItem: {
        color: 'red',        
      },
    link: {
        color: theme.palette.secondary.main,
    }      
  }));

  const initValues = 
    {
         exclusionField: "",
         exclusionValue: "",
         exclusionOperator: "equals"
    }

  const exclusionCriteriaReducer = (state, action) => {
    switch (action.type) {
    case 'INIT':          
        return action.payload;        
      case 'UPDATE':
  
            return state.map( (x, index) => {
                if (index === action.payload.itemIndex) {
                    return {...x, ...action.payload.newCriteria };
                } else {
                    return x;
                }
            });

        case 'REMOVE':
            return state.filter((item, itemIndex) => itemIndex !== action.payload.itemIndex);            
        case 'ADD':
        return [...state, initValues];        
      default:
        throw new Error('Unsupported reducer action')
    }
  }

const AddExclusion = ({ handleDoneAdd }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const form = createRef();
    const [exclusionName, updateExclusionName] = useState("");
    const [filterable, updateFilterable] = useState([]);
    const [addError, updateAddError] = useState();
    const [exclusionCriteria, updateExclusionCriteria] = useReducer(exclusionCriteriaReducer, [initValues]);
    const [testCriteria, updateTestCriteria] = useState({});
    
    const exclusionOperators = ['equals', 'does not equal'];

    const formatCriteria = () => {
        return exclusionCriteria.reduce((memo, item) => {
            const newItem = (item.exclusionField && item.exclusionValue) ? {[`${item.exclusionField}`]: item.exclusionValue} : {};
            return {...memo, ...newItem};
        }, {});
    }

    const handleTest = () => {
        const newCriteria = formatCriteria();
        if (Object.keys(newCriteria).length) {            
            updateTestCriteria(newCriteria);
        } else {
            updateAddError("Test requires valid Exclusion Critera");
        }
    }
    useEffect(() => {
        if (filterable.length) {
            return false;
        }     
        getFilterableFields()
        .then(results => {            
            updateFilterable(results);
        }).catch((err) => {
          console.log(err.message);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [], shallowEqual);

    const handleChange = ({newCriteria, itemIndex}) => {
        const payload = {
            itemIndex,
            newCriteria
        }
        updateExclusionCriteria({ type: "UPDATE", payload });
    }
    const handleRemove = (payload) => {
        updateExclusionCriteria({ type: "REMOVE", payload });
    }    

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!form.current.reportValidity()) {
            return false;
        }
        
        dispatch(addExclusionCriteria({ name: exclusionName, criteria: exclusionCriteria }));
        handleDoneAdd();
        return false;
        
    }
    const handleCancel = () => {
        handleDoneAdd();
    }    

    const addCriteriaItem = () => {
        updateExclusionCriteria({ type: "ADD" });
    }
      return (
          <>
          <h4>Add New Exclusion Criteria</h4>
        <form ref={form} onSubmit={e => e.preventDefault()}>
          <Grid 
                container                
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={4}>
                    <TextField  
                        name='exclusionName'
                        className={classes.selectStyle}
                        value={exclusionName}                                                        
                        onChange={(e) => updateExclusionName(e.target.value)}
                        label="Friendly Name"
                        required
                        variant="outlined" />                            
                </Grid>
        </Grid>          
          {exclusionCriteria && exclusionCriteria.map((criteria, itemIndex) => (             
            <Grid 
                container
                key={itemIndex}                
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >

                <Grid item xs={4}>
                    <FormControl className={classes.selectStyle} m={1} >
                        <TextField
                            label="Field"
                            name="exclusionField"
                            onChange={(e) => handleChange({ newCriteria: { [e.target.name]: e.target.value}, itemIndex })}
                            select
                            value={criteria.exclusionField || ''}
                            variant="outlined"
                            required
                            >              
                            {filterable.map((filterableField, index) => (
                                <MenuItem key={index} value={filterableField}>
                                {filterableField}
                                </MenuItem>
                            ))}        
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4} >
                    <Box m={3}>
                    <FormControl className={classes.selectStyle} >
                        <TextField
                            label="Criteria"
                            name="exclusionOperator"
                            onChange={(e) => handleChange({ newCriteria: { [e.target.name]: e.target.value}, itemIndex })}
                            select
                            value={criteria.exclusionOperator || ''}
                            variant="outlined"
                            required
                            >              
                            {exclusionOperators.map((exclusionOperator, index) => (
                                <MenuItem key={`exclusion-${index}-operator`} value={exclusionOperator}>
                                {exclusionOperator}
                                </MenuItem>
                            ))}        
                        </TextField>
                    </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" justifyContent="space-between" m={1} >
                        <TextField  
                            name='exclusionValue'
                            value={criteria.exclusionValue}
                            onChange={(e) => handleChange({ newCriteria: { [e.target.name]: e.target.value}, itemIndex })}
                            className={classes.selectStyle}
                            label="Value to exclude"
                            required
                            variant="outlined" />
                            {exclusionCriteria && exclusionCriteria.length > 1 && (
                                <Box alignSelf="center">
                                    <IconButton 
                                        className={classes.link}
                                        onClick={(e) => handleRemove({ itemIndex })}
                                        aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                            </Box>
                            )}
                    </Box>
                </Grid>
            </Grid>
          ))}
          {addError && (          
            <Box  spacing={1}  pt={2}>
                <Alert severity="error">{addError}</Alert>          
            </Box>
          )}
        <Box display="flex" spacing={1} justifyContent="flex-end" pr={2}>
            <div className={classes.addButton} onClick={addCriteriaItem}>Add criteria +</div>
        </Box>          
    
        <Box display="flex" justifyContent="space-between">
            <Box display="flex" spacing={1} justifyContent="flex-start"  pt={2}>
                <Button
                    aria-label="Test"
                    color="default"
                    className={classes.button}
                    onClick={() => {handleTest()}}
                    variant="contained"
                    >
                    Test
                </Button>
            </Box>
            <Box display="flex" spacing={1} justifyContent="flex-end"  pt={2}>
                <Button
                    aria-label="Save"
                    color="primary"
                    type="submit"
                    className={classes.button}
                    onClick={handleSubmit}
                    variant="contained"
                    >
                    Save
                </Button>       
                <Button
                    aria-label="Cancel"
                    color="secondary"
                    className={classes.button}   
                    onClick={() => {handleCancel()}}
                    variant="contained"
                >
                    Cancel
                </Button>        
            </Box>
        </Box>
        </form>
        <ExclusionResults2  criteria={testCriteria}/>     
        </>
      )

}


export { AddExclusion };