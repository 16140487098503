import React, { useEffect, useState } from 'react';
import { 
    Paper,
    makeStyles
 } from '@material-ui/core';
 
import { EWorkspaceIntro } from './EWorkspaceIntro';
import { EWorkspaceRequest } from './EWorkspaceRequest';
import { EWorkspaceTeachers } from './EWorkspaceTeachers';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchElsProfile,
} from 'actions';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      width: '100%',
      flexDirection: 'column',
    },
    paperHeading: {
      alignItems: 'center',
      backgroundColor: theme.palette.info.light,
      display: 'flex',
      margin: 0,
      padding: theme.spacing(2)
    },   
  }));

const EWorkspace = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.districtEls);
    const [eBooksEnabled, setEbooksEnabled] = useState(false);
    useEffect(() => {
      dispatch(fetchElsProfile());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    useEffect(() => {
      if (profile) {
        setEbooksEnabled(profile.isEWorkspaceEnabled);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);     
    
    return (
      <div className={classes.root}>

        <Paper elevation={5}>
          
            <EWorkspaceIntro />
    
          <div>
            {eBooksEnabled ? (
              <EWorkspaceTeachers />
            ) : (
              <EWorkspaceRequest />
            )}
            </div>
        </Paper>

      </div>
    )
}

export {
    EWorkspace
};