import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from 'env';
import { makeStyles } from '@material-ui/core/styles';
import { UploadFile } from '../Common/UploadFile';
import {
  Button,
  Box,
  colors,
  Container,  
  Paper,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { saveRosterCredentials, testRosterCredentials } from 'actions';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import HttpsIcon from '@material-ui/icons/Https';
import GetAppIcon from '@material-ui/icons/GetApp';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import { DynamicField } from '../Common/DynamicField';

import { convertCourseMappings } from "../../utils/statelessCalls";
import { rosterPlatforms, rosterPlatformProfiles } from "./districtData";


const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: 'white',
    margin: theme.spacing(1)
  },
  district: {
    width: '100%'
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%' // Fix IE 11 issue.
  },
  navigationButton: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  selectStyle: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: '100%'
  },
  testButton: {
    backgroundColor: theme.palette.success.main,
    // color: theme.palette.success.light,
  },
  successIcon: {
    color: theme.palette.success.dark,
    fontSize: '56px'
  },
  warningIcon: {
    color: '#ff9800',
    fontSize: '56px'
  },
  errorIcon: {
    color: colors.red[600],
    fontSize: '56px'
  }  
}));


const District = ({ disableNext}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const form = createRef();
  const currentRoster = useSelector((state) => state.district);
  
  const [slurpMessage, updateSlurpMessage] = useState({ show: false, severity: 'info', message: ''});
  
  const [formValues, updateFormValues] = useState({rosterPlatform: ''});
  const [dynamicRosterFields, updateDynamicRosterFields] = useState([rosterPlatforms]);

  const validProfileCredentials = useSelector((state) => state.district.validProfileCredentials);
  const [dirtyBit, updateDirtyBit] = useState(false); // used to track unsaved changes to form

  const resetFormData = () => {    
    updateDirtyBit(false);
    if (currentRoster.hasOwnProperty('rosterPlatform')) {
      
      const rosterConfig = rosterPlatformProfiles[currentRoster['rosterPlatform']];
      const platformFormFields = rosterConfig.getFormFields(currentRoster['authProtocol']);
 
      let sourceData = {};
      // what is the simplest, predictable way to derive the base URL?
      if (currentRoster.hasOwnProperty('rosterServer') && rosterConfig.hasOwnProperty('getBaseUrl')) {
        const baseUrl =   rosterConfig.getBaseUrl(currentRoster.rosterServer);
        sourceData = {...currentRoster, baseUrl};
      } else {
        sourceData = currentRoster;
      }

      const seededFormValues = platformFormFields.reduce((memo, item) => {
        return {...memo, [item.fieldName]: sourceData.hasOwnProperty(item.fieldName) ? sourceData[item.fieldName] : item.fieldValue};
      }, {});

      
      updateFormValues({...seededFormValues, rosterPlatform: currentRoster.rosterPlatform})
      updateDynamicRosterFields(platformFormFields);
    } else {
      updateFormValues({});
    }
  }

  const handleChange = (newValue) => {
    updateDirtyBit(true);
    updateFormValues({...formValues, ...newValue});
    let rosterConfig;

    if(newValue.hasOwnProperty('rosterPlatform')) { 
      rosterConfig = rosterPlatformProfiles[newValue['rosterPlatform']];
       updateFormValues({...newValue, ...rosterConfig.defaults})
      updateDynamicRosterFields(rosterConfig.getFormFields())
      
    } else {
      rosterConfig = rosterPlatformProfiles[formValues['rosterPlatform']];
    }

    if(newValue.hasOwnProperty('baseUrl') && rosterConfig.hasOwnProperty('urlWizards')) {      
      const dynamicUrls = rosterConfig.urlWizards(newValue['baseUrl'])
      updateFormValues({...formValues, ...newValue, ...dynamicUrls})            
    }

    if(newValue.hasOwnProperty('authProtocol')) {      

      const newDynamicFields = rosterConfig.getFormFields(newValue['authProtocol']);
      const newFormValues = newDynamicFields.reduce((memo, item) => {
        return formValues.hasOwnProperty(item.fieldName) ? {...memo, [item.fieldName]: formValues[item.fieldName]} : memo;
      }, {});

      updateFormValues({...newFormValues, ...newValue});
      updateDynamicRosterFields(newDynamicFields)          
    }
    if(newValue.hasOwnProperty('slurpData') && currentRoster.hasOwnProperty('_id')) {      
      updateSlurpMessage({show: true, severity: 'warning', message: `Changing this setting will invalidates previously configured course settings.`});      
      updateFormValues({...formValues, ...newValue})      
    }

    if(newValue.hasOwnProperty('rosterServer')) {
      //updateFormValues({...formValues, rosterServer: cleanUrlSlashes(newValue['rosterServer']) });
      updateFormValues({...formValues, ...newValue });
    }    
  }

  useEffect(() => {
    if (currentRoster.hasOwnProperty('rosterPlatform')) {
      resetFormData();
    }
    updateDirtyBit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoster]);
 
  useEffect(() => {      
       dispatch(testRosterCredentials());        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    
  const handleSubmit = (e) => {
    e.preventDefault();
    if(form.current.reportValidity()) {
      
       dispatch(saveRosterCredentials(formValues));
       if (formValues.hasOwnProperty('slurpData') && currentRoster.slurpData !== formValues.slurpData) {
         const slurpType = formValues.slurpData ? 'preloaded data' : 'live queries';
        updateSlurpMessage({show: true, severity: 'info', message: `Previously configured courses and license mappings must be converted to use the required format for ${slurpType}.  This may take a few minutes for large districts.`});
         convertCourseMappings(formValues.slurpData).then(result => {
          updateSlurpMessage({show: true, severity: 'success', message: `Course and license settings have successfully been converted to use ${slurpType}. ${formValues.slurpData ? 'Important!!!  You must preload data before your course and license settings display correctly' : ''}`});
         }).catch(err => {
          updateSlurpMessage({show: true, severity: 'error', message: 'Course and license settings failed.  Please review the settings.'});
         })
       }
    }
  };

  const downloadPlugin = () => {
    window.open(`${API_URL}/plugin/CPM_Powerschool_plugin.zip`)
  }

  const handleTest = (e) => {
    e.preventDefault();
    if(form.current.reportValidity()) {
      dispatch(testRosterCredentials(formValues));
    }        
  }

  useEffect(() => {
    if (validProfileCredentials && disableNext) {
      disableNext(false);
    }

    return () => {
      if (disableNext) {
        disableNext(true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validProfileCredentials]);

  
  return (
    <div className={classes.district}>
      <Container component="section" maxWidth='sm'>
        <Paper className={classes.paper} elevation={2} square>
          <Typography component="h1" variant="h5">
            Roster Provider
          </Typography>
          {currentRoster && currentRoster.checkingCredentials && (
              <LockOpenIcon className={`${classes.warningIcon} icon-pulse`} />
          )}
          {currentRoster && (!currentRoster.checkingCredentials && currentRoster.validRosterCredentials) && (
              <HttpsIcon className={`${classes.successIcon}`} />
          )}
          {currentRoster && (!currentRoster.checkingCredentials && !currentRoster.validRosterCredentials) && (
              <>
              <NoEncryptionIcon className={`${classes.errorIcon}`} />
              <Alert severity='error'>{currentRoster.error}</Alert>
              </>
            )}           

          <form className={classes.form} ref={form} onSubmit={e => e.preventDefault()} noValidate>

            {Array.isArray(dynamicRosterFields) && dynamicRosterFields.filter(x => !x.hidden).map((myField, myIndex) => (
              <DynamicField
                key={myIndex}
                variant="outlined"
                handleChange={handleChange}
                {...myField}                
                editMode={!myField.disabled}
                disabled={currentRoster.checkingCredentials}
                fieldValue = {formValues[myField.fieldName] || myField.fieldValue}
              />
            ))}

          {formValues.hasOwnProperty('rosterPlatform') && formValues['rosterPlatform'] === 'powerschool' && (
                        <Alert severity='info' onClick={downloadPlugin}
                        action={
                          <Button color="inherit" size="small">
                            <GetAppIcon />                  
                          </Button>
                        }>Install and enable the CPM_PowerSchool Plugin to generate client credentials
                        
                        </Alert> 
          )}
          {slurpMessage.show && (
            <Alert onClose={() => {updateSlurpMessage(false)}} severity={slurpMessage.severity}>
              {slurpMessage.message}
            </Alert>
          )}
          

            <Box display="flex" justifyContent="space-between"mt={2}>
                
            <Box flexGrow={1}>
                <Button                
                color="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={!dirtyBit || currentRoster.isLoading}
                type="submit"
                variant="contained"
                className="button-list"
              >
                Save
              </Button>
              </Box>
            
            <Box ml={3}>
                       
                <Button                
                      color="secondary"
                      disabled={!dirtyBit}
                      onClick={resetFormData}
                      className="button-list"      
                      variant="outlined"
                      
                    >
                      Reset
                    </Button>
              
              <Button                                    
                    onClick={handleTest}                    
                    variant="outlined"
                    className="button-list"
                  >
                    Test
                  </Button>
              </Box>  
              </Box>               
          </form>
          

          {currentRoster && currentRoster.rosterPlatform === 'oneroster-csv' && formValues.rosterPlatform === 'oneroster-csv' && (                      
            <UploadFile  />
          )}
      </Paper>
      </Container>
    </div>
  );
};

export default District;
