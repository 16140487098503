import React from 'react';
import { 
    Typography,
    Grid,
    Avatar,
    makeStyles    
} from '@material-ui/core';
import CastForEducationOutlinedIcon from '@material-ui/icons/CastForEducationOutlined';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
          margin: theme.spacing(1),
        },
        width: '100%',
        flexDirection: 'column',
      },    
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      // backgroundColor: theme.palette.info.light,
      backgroundColor: 'inherit',
      // color: theme.palette.success.main,
      color: 'white'
    },
    paperHeading: {
        alignItems: 'center',
        backgroundColor: theme.palette.info.main,
        display: 'flex',
        color: 'white',
        margin: 0,
        paddingTop: theme.spacing(2)
      },
  }));

const EWorkspaceIntro = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.paperHeading}
            >    
                <Grid item>
                    <Typography variant="h4" component="h1">
                           CPM eWorkspace     
                    </Typography>
                </Grid>    
                <Grid item>                    
                    <Avatar alt="Remy Sharp"  className={classes.large} >
                        <CastForEducationOutlinedIcon style={{ fontSize: 80 }}/>
                    </Avatar>
                </Grid> 
            </Grid>
      </div>            
    )
}

export {
    EWorkspaceIntro
};