import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { differenceBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Grid
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';

import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { addStickyLicenseProfile, fetchTeachers, resetTeachers } from 'actions';
import { ToggleText, StickyBooksList } from './components';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px'
  },
  arrow: {
    color: theme.palette.secondary.main
  },
  button: {
    width: '50%'
  },
  close: {
    cursor: 'pointer'
  },
  columnHeader: {
    fontWeight: 600
  },
  cta: {
    display: 'flex',
    width: '100%'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  eBooks: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px'
  },
  eBooksHeader: {
    margin: '8px 0',
    fontWeight: 600
  },
  eBookInput: {
    // marginTop: '16px',
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  halfWidth: {
    width: '49%'
  },
  list: {
    backgroundColor: 'white',
    flex: '1',
    height: '100vh'
  },
  listsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  manual: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    paddingLeft: '12px'
  },
  modalContainer: {
    position: 'fixed',
    zIndex: 9999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,.33)'
  },
  modalContent: {
    margin: '0 auto',
    padding: theme.spacing(3),
    maxWidth: '800px'
  },
  paper: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingLeft: {
    display: 'flex'
  },
  paperHeadingTitle: {
    marginRight: theme.spacing(0.5)
  },
  paperInput: {
    padding: theme.spacing(3)
  },
  paperInputField: {
    // marginBottom: theme.spacing(3),
    width: '100%'
  },
  paperList: {
    flex: '1',
    height: '100vh',
    overflow: 'hidden',
    '&:first-child': {
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
        marginRight: 0
      }
    }
  },
  paperOtherContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  row: {
    padding: theme.spacing(0, 3)
  },
  selectContainer: {
    width: '50%',
    paddingRight: '12px'
  },
  selectStyle: {
    width: '100%'
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },
  teacherProfile: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%'
  },
  tooltip: {
    backgroundColor: theme.palette.success.main,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    justifyContent: 'center'
  },
  warningIcon: {
    color: '#f1c40f',
    fontSize: '80px'
  }
}));

const AddStickyLicenseModal = ({ toggleModal }) => {
  const [isTeacher, updateIsTeacher] = useState(true);
  const licenses = useSelector((state) => state.licenses);
  const [license, setLicense] = useState({
    rosterSourceId: '',
    lastName: '',
    firstName: '',
    email: '',
    login: '',
    schoolId: '',
    schoolName: '',
    classes: []
  });
  const teachers = useSelector((state) => state.teachers.data, shallowEqual);
  const [availableTeachers, updateAvailableTeachers] = useState([]);
  const [availableLicenses, updateAvailableLicenses] = useState([]);
  const stickyLicenses = useSelector((state) => state.stickyLicenses.data, shallowEqual);
  const isLoading = useSelector((state) => state.teachers.isLoading);
  const { schools } = useSelector((state) => state.schools, shallowEqual);
  const [teacher, setTeacher] = useState(null);
  const [manualAdd, setManualAdd] = useState(false);
  
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSubmit = () => {
    dispatch(addStickyLicenseProfile(license));
    toggleModal();
  };

  const handleSelectTeacher = (teacher) => {
    if (teacher === null) {
      setTeacher(null);
      setManualAdd(false);
      setLicense({
        rosterSourceId: '',
        firstName: '',
        lastName: '',
        email: '',
        login: '',        
        schoolId: '',
        schoolName: '',
        classes: []
      });
    } else {
      const { rosterSourceId, userId, lastName, firstName, email, login } = teacher;
      const { schoolId, schoolName } = license;
      
      setTeacher(teacher);
      setLicense({
        rosterSourceId,
        lastName,
        firstName,
        email,
        login,
        userId,
        schoolId,
        schoolName,
        classes: []
      });
    }
  };

  useEffect(() => {
    if(licenses && Array.isArray(licenses.licenses)) {
      const myAvailableLicenses = differenceBy( licenses.licenses, license.classes, 'eBookId')
        .filter(x => x.isTeacherEdition === isTeacher && x.licenses > 0);
      updateAvailableLicenses(myAvailableLicenses);
    }

    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license, isTeacher]);



  const handleAddEbook = (e) => {
    const eBook = e.target.value;
    const { eBookId, eBookName } = eBook;
    const baseNewBook = { eBookId, eBookName };
    const eBookIndex = license.classes.findIndex(
      (eBook) => eBook.eBookId === eBookId
    );
    if (eBookIndex === -1) {
      setLicense((prevState) => ({
        ...license,
        classes: [...prevState.classes, baseNewBook]
      }));
    }
  };

  const handleRemoveEbook = (eBookToRemove) => {
    const eBookIndex = license.classes.findIndex(
      (eBook) => eBook.eBookId === eBookToRemove.eBookId
    );
    if (eBookIndex !== -1) {
      setLicense((prevState) => ({
        ...license,
        classes: prevState.classes.filter((eBook) => eBook.eBookId !== eBookToRemove.eBookId)
      }));
    }
  };

  const handleChange = (e) => {
    setLicense({ ...license, [e.target.name]: e.target.value });    
  };

  const handleSchoolChange = (school) => {
    setLicense({ ...license, ...school });
  };

  const handleManualAdd = () => {
    setManualAdd(true);
  };

  const filter = createFilterOptions();

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        toggleModal(null);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
      setManualAdd(false);
      dispatch(resetTeachers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
  
    const myAvailableTeachers = differenceBy( teachers, stickyLicenses, 'email');
    updateAvailableTeachers(myAvailableTeachers)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teachers]);  

  return ReactDOM.createPortal(
    <div className={classes.modalContainer}>
      <div className={classes.modalContent}>
        <Paper className={classes.paper} elevation={2} square>
          <div className={classes.paperHeading}>
            <Typography
              className={classes.paperHeadingTitle}
              component="h1"
              variant="body1"
            >
              Add Sticky License
            </Typography>
            <CloseSharpIcon
              className={classes.close}
              onClick={() => toggleModal()}
            />
          </div>
          {license && !license.schoolId && (
              <div className={classes.paperContent}>
                <Autocomplete
                  fullWidth
                  value={license && license.schoolId}
                  onChange={(event, newValue) => {
                    console.log(newValue)
                    if (newValue) {
                      const { schoolId, schoolName } = newValue;
                      handleSchoolChange({ schoolId, schoolName });
                      dispatch(fetchTeachers(schoolId));
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  selectOnFocus
                  handleHomeEndKeys
                  id="schools-autocomplete"
                  options={schools}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }

                    return (option && option.schoolName) || '';
                  }}
                  renderOption={(option) => option.schoolName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      label="Primary School"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            )}
          {license &&
            license.hasOwnProperty('schoolId') &&
            license.schoolId && (
              <div className={classes.paperContent}>
                <div className={classes.fullWidth}>
                  <Typography
                    style={{ fontWeight: '600', marginTop: '12px' }}
                    variant="body1"
                  >
                    Primary School
                  </Typography>
                  {license.schoolName}
                </div>

                {manualAdd === false && (
                  <>
                    <Divider style={{ margin: '16px 0' }} />
                    <div className={classes.cta}>
                      <div className={classes.selectContainer}>
                        {isLoading && <div>Loading...</div>}
                        {!isLoading && (
                          <>
                            {license && !license.lastName && (
                              <Typography
                                style={{ fontWeight: '600', marginTop: '12px' }}
                                variant="body1"
                              >
                                Auto Populate
                              </Typography>
                            )}
                            <Autocomplete
                              fullWidth
                              value={teacher || ''}
                              disabled={
                                license &&
                                license.hasOwnProperty('schoolId') &&
                                !license.schoolId
                              }
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  handleSelectTeacher(newValue);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                return filtered;
                              }}
                              selectOnFocus
                              handleHomeEndKeys
                              id="schools-autocomplete"
                              options={availableTeachers}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  return option;
                                }

                                return (
                                  (option &&
                                    `${option.lastName}, ${option.firstName}`) ||
                                  ''
                                );
                              }}
                              renderOption={(option) =>
                                `${option.lastName}, ${option.firstName}`
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  label="Teacher"
                                  variant="outlined"
                                />
                              )}
                            />
                          </>
                        )}
                      </div>

                      {!isLoading && teacher === null && manualAdd === false && (
                        <>
                          <div className={classes.manual}>
                            <div
                              style={{
                                alignSelf: 'flex-end',
                                marginBottom: '24px'
                              }}
                            >
                              OR
                            </div>
                            <div
                              style={{
                                alignItems: 'flex-end',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Typography
                                style={{ fontWeight: '600', marginTop: '12px' }}
                                variant="body1"
                              >
                                Manually Enter
                              </Typography>

                              <Button
                                aria-label="Manually Add Teacher"
                                style={{ marginBottom: '16px', width: '50%' }}
                                color="primary"
                                onClick={handleManualAdd}
                                variant="contained"
                              >
                                Go
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                <div
                  style={{
                    display: teacher || manualAdd ? 'flex' : 'none',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <div className={classes.teacherProfile}>
                    <div className={classes.fullWidth}>
                      <Typography
                        style={{ fontWeight: '600', marginTop: '12px' }}
                        variant="body1"
                      >
                        Teacher Profile
                      </Typography>
                    </div>
                    <div className={classes.halfWidth}>
                      <TextField
                        autoComplete="text"
                        autoFocus
                        disabled={!manualAdd}
                        fullWidth
                        id="firstName"
                        InputLabelProps={{ shrink: true }}
                        label="First Name"
                        margin="normal"
                        name="firstName"
                        onChange={handleChange}
                        required
                        value={(license && license.firstName) || ''}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.halfWidth}>
                      <TextField
                        autoComplete="text"
                        disabled={!manualAdd}
                        fullWidth
                        id="lastName"
                        InputLabelProps={{ shrink: true }}
                        label="Last Name"
                        margin="normal"
                        name="lastName"
                        onChange={handleChange}
                        required
                        value={(license && license.lastName) || ''}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.halfWidth}>
                      <TextField
                        autoComplete="text"
                        disabled={!manualAdd}
                        fullWidth
                        id="email"
                        InputLabelProps={{ shrink: true }}
                        label="Email"
                        margin="normal"
                        name="email"
                        onChange={handleChange}
                        required
                        value={(license && license.email) || ''}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.halfWidth}>
                      <TextField
                        autoComplete="text"
                        disabled={!manualAdd}
                        fullWidth
                        id="login"
                        InputLabelProps={{ shrink: true }}
                        label="Login"
                        margin="normal"
                        name="login"
                        onChange={handleChange}
                        required
                        value={(license && license.login) || ''}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <Divider className={classes.divider} />



            <div className={classes.fullWidth}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: '600' }} variant="body1">
                      Licenses
                    </Typography>
                  </Grid>
           
              </div>




                  <div className={classes.fullWidth}>
                      <ToggleText items={["Teacher", "Student"]} activeIndex={isTeacher ? 0 : 1} handleClick={(myIndex) => updateIsTeacher(myIndex === 0 ? true : false)}/>
                  </div>
                  <div className={classes.eBookInput}>
                    <FormControl className={classes.selectStyle}>
                      <TextField
                        label="Add License"
                        name="classes"
                        onChange={handleAddEbook}
                        select
                        variant="outlined"
                        value=""
                      >
                        {availableLicenses.map((l) => (
                          <MenuItem key={`new-${l.eBookId}-id`} value={l}>
                            {l.eBookName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                    <div>
                      <div className={classes.eBooksHeader}>
                        Assigned Licenses:
                      </div>
                      <StickyBooksList licenses={license.classes} handleClick={handleRemoveEbook} />
                      
                    </div>
                  </div>
                </div>
                {(teacher || manualAdd) && (
                  <div className={classes.actions}>
                    <Button
                      aria-label="Save Sticky License"
                      className={classes.button}
                      color="primary"
                      onClick={handleSubmit}
                      variant="contained"
                    >
                      SAVE
                    </Button>
                  </div>
                )}
              </div>
            )}
        </Paper>
      </div>
    </div>,
    document.querySelector('#modal')
  );
};

export { AddStickyLicenseModal };
