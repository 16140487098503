import {
  FETCH_COURSES_FAILURE,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
  REMOVE_INVALID_COURSE,
  RESET_COURSES_FILTER,
  SET_COURSES_FILTER,
  TOGGLE_ROSTER_COURSE
} from '../actions/actionTypes';

const initialState = { courses: [], invalid: [], filter: '', isLoading: false };

const coursesReducer = ((state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSES_FAILURE:
      return { ...state, isLoading: false };      
    case FETCH_COURSES_REQUEST:
      return { ...state, isLoading: true };      
    case FETCH_COURSES_SUCCESS:
      const { courses, invalid } = action.payload;      
      return { ...state, isLoading: false, courses, invalid };            
    case RESET_COURSES_FILTER:
      return { ...state, filter: ''};      
    case SET_COURSES_FILTER:
      return { ...state, filter: action.payload};
    case TOGGLE_ROSTER_COURSE: {
      const rostered = action.method === 'post';
      const updatedCoursesState =  state.courses.map( c => {        
        return (c.courseId === action.payload.courseId ) ? {...c, rostered } : c;
      })
      return {...state, courses: updatedCoursesState };
    }
    case REMOVE_INVALID_COURSE:
      return {...state, invalid: state.invalid.filter(x => x.courseId !== action.payload.courseId)};
    default:
      return state;
  }
});

export default coursesReducer;
