import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
  } from '@material-ui/core';
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const DialogConfirm = ( {open, title, description, handleCancel, handleConfirm}) => {
    return (
        <div>
            <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCancel}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} >
                Cancel
                </Button>
                <Button onClick={handleConfirm} variant="outlined" color="primary">
                Confirm
                </Button>
            </DialogActions>
            </Dialog>
        </div>
    )
}

export {
    DialogConfirm
};